import { ctpTokens, HHBox, HHIconButton, HHSkeleton, HHStack, HHTypography } from '@hinge-health/react-component-library';
import { KeyboardArrowLeft, KeyboardArrowRight, Videocam } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { WorkflowPayload } from '../../../types';
import { ONE_MINUTE_IN_MS } from '../constants';
import { useAppointments } from '../hooks/use-appointments';
import { AppointmentWorkflowCard } from './appointment-workflow-card';
import { MemberProfileCard } from './member-profile-card';
const styles = {
  container: {
    padding: 6
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
    justifyContent: 'space-between'
  },
  headerInfo: {
    gap: 4,
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerButtons: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  button: (disabled: boolean): React.CSSProperties => ({
    padding: 0,
    color: disabled ? ctpTokens.palette.neutral[70] : ctpTokens.palette.neutral[20]
  }),
  appointments: {
    gap: 2,
    flexDirection: 'row',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    marginBottom: 2.5
  }
};
export const Appointments: React.FC = () => {
  const {
    loading,
    error,
    completedAppointmentsCount,
    incompleteAppointmentsCount,
    appointments
  } = useAppointments();
  const updateActiveIndex = useCallback(() => {
    const activeIndex = appointments.findIndex(appointment => {
      const callDate = dayjs(appointment.customFields?.callDate);
      return callDate.isBetween(dayjs(), dayjs().add(10, 'minutes'));
    });
    if (activeIndex >= 0) setActiveIndex(activeIndex);
  }, [appointments]);
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]);
  const [activeIndex_0, setActiveIndex] = useState(0);
  useEffect(() => {
    updateActiveIndex();
    const id = setInterval(updateActiveIndex, ONE_MINUTE_IN_MS);
    return () => clearInterval(id);
  }, [updateActiveIndex]);
  const onAppointmentCardClick = (appointment_0: WorkflowPayload): void => {
    const index = appointments.findIndex(a => a.id === appointment_0.id);
    setActiveIndex(index);
    scrollAppointmentCardIntoView(index);
  };
  const onNextClick = (): void => {
    setActiveIndex(activeIndex_0 + 1);
    scrollAppointmentCardIntoView(activeIndex_0 + 1);
  };
  const onPreviousClick = (): void => {
    setActiveIndex(activeIndex_0 - 1);
    scrollAppointmentCardIntoView(activeIndex_0 - 1);
  };
  const scrollAppointmentCardIntoView = (index_0: number): void => {
    cardsRef.current[index_0]?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
  };
  const selectedAppointment = appointments?.[activeIndex_0];
  const totalAppointments = completedAppointmentsCount + incompleteAppointmentsCount;
  if (loading) return <HHSkeleton hhVariant="rectangular" />;
  if (error) return <div>error</div>;
  if (totalAppointments === 0) return <div>No appointments</div>;
  return <HHBox sx={styles.container}>
      <HHStack sx={styles.header}>
        <HHStack sx={styles.headerInfo}>
          <Videocam />
          <HHTypography hhVariant="variant-bypass">
            <HHTypography component="span" hhVariant="variant-bypass" variant="body1" fontWeight={700} fontSize={16} paddingRight={1}>
              {incompleteAppointmentsCount} / {totalAppointments} appointments
            </HHTypography>
            <HHTypography component="span" hhVariant="variant-bypass" variant="body1" fontSize="inherit">
              remaining
            </HHTypography>
          </HHTypography>
        </HHStack>
        <HHStack sx={styles.headerButtons}>
          <HHIconButton hhVariant="variant-bypass" disabled={activeIndex_0 === 0} sx={styles.button(activeIndex_0 === 0)} onClick={onPreviousClick} aria-label="previous">
            <KeyboardArrowLeft />
          </HHIconButton>
          <HHIconButton hhVariant="variant-bypass" disabled={activeIndex_0 === appointments.length - 1} sx={styles.button(activeIndex_0 === appointments.length - 1)} onClick={onNextClick} aria-label="next">
            <KeyboardArrowRight />
          </HHIconButton>
        </HHStack>
      </HHStack>
      <HHStack sx={styles.appointments}>
        {appointments?.map((appointment_1, index_1) => <div ref={(el: HTMLDivElement | null): void => {
        cardsRef.current[index_1] = el;
      }} key={appointment_1?.id}>
            <AppointmentWorkflowCard workflow={appointment_1} isActive={selectedAppointment?.id === appointment_1?.id} onClick={onAppointmentCardClick} />
          </div>)}
      </HHStack>
      {selectedAppointment && <MemberProfileCard key={selectedAppointment.id} workflow={selectedAppointment} />}
    </HHBox>;
};