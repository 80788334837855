import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { CoachWorkflowTypes, PtWorkflowTypes } from '../../custom-types';
import { WorkflowPayload } from '../../types';
import { VIDEO_VISIT_TYPES } from './constants';
dayjs.extend(isBetween);
export const getWorkflowCallDate = (workflow: WorkflowPayload): Date => {
  if (!workflow?.customFields?.callDate) {
    throw new Error('Workflow does not have a call date');
  }
  return dayjs(workflow.customFields.callDate).toDate();
};
export const isWithinNext10Minutes = (givenDate: string): boolean => {
  const now = dayjs();
  const nowPlus10Minutes = now.add(10, 'minute');
  return dayjs(givenDate).isBetween(now, nowPlus10Minutes);
};
export const getVideoVisitType = (workflow: WorkflowPayload): string => {
  const {
    customFields,
    type
  } = workflow;
  const {
    followups = 0
  } = customFields ?? {};
  switch (type) {
    case CoachWorkflowTypes.CoachVideoVisit:
      return 'Video visit';
    case CoachWorkflowTypes.CoachCall:
      return 'Coach call';
    case PtWorkflowTypes.FifteenMinute:
      return '15 min video visit';
    default:
      return VIDEO_VISIT_TYPES[followups];
  }
};