/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/naming-convention */
import { HHSelectOption } from '@hinge-health/react-component-library';
import { OktaRole } from '../../../../types/okta-roles';
export const MEMBER_UUID_STORAGE_KEY = 'hh-member-uuid';
export const ERROR_STRINGS = {
  GENERIC: 'Sorry, we are having trouble loading data. Please try reloading the page',
  MEMBER_DATA_ERROR: 'Unable to load member data'
};
export const N_A = 'N/A';
export const TABS = {
  PROFILE: 'Profile',
  ACTIVITY: 'Activity',
  CARE_PLAN: 'Care plan',
  INTERACTION: 'Interactions',
  NOTES: 'Notes',
  WORKFLOW: 'Workflows'
};
export const MESSAGING_TABS = {
  CONVERSATION: 'Conversation'
};
export const CARE_INTERACTIONS_TABS = {
  VIDEO_VISIT: 'Video Visit'
};
export const MESSAGING_PANEL = 'messaging-panel';
export const RECORDS_PANEL = 'records-panel';
export const WORKFLOWS_PANEL = 'workflows-panel';
export const HEALTH_LOG_PANEL_ID = 'health-log-workflow-panel';
export const MINIMIZE_ICON = 'MinimizeIcon';
export const NO_ACTIVE_PATHWAY = 'This member does not have an active program';
export const CONFIGURATOR_WRITE_ACCESS_ROLES = [OktaRole.PhysicalTherapist.toString()];
export const MEMBER_SNAPSHOT = 'Member snapshot';
export const ACCOUNT_DETAILS = 'Account details';
export const HIDE_EMPTY_FIELDS = 'Hide Empty Fields';
export const SHOW_EMPTY_FIELDS = 'Show Empty Fields';
export const VIEW_SCREENER = 'View Screener';
export const MEMBER_BACKGROUND_ERROR_STRING = 'Unable to load to display background data';
export const INFORMATION_CURRENTLY_UNAVAILABLE = 'Information Currently Unavailable';
export const FDC_REGULATION_DATA_IS_MASKED = 'FDC regulation data is masked';
export const FDC_REGULATION_ERROR_STRING = 'Unable to display FDC regulation data';
export const FDC_PT_VV_DISABLED_INFO = 'PTVV Disabled: Video Visits are NOT available for this member';
export const FDC_SENSITIVE_REGULATION_INFO = 'FDC Sensitive: Limit total Video Visits to 6 per year';
export const FDC_REGULAR_REGULATION_INFO = 'Regular FDC clients: Limit total Video Visits to 12 per year';
export const FDC_REGULAR_REGULATION_INFO_WITH_HCP_REQUIRED = 'Regular FDC clients: Member Requires proof of HCP visit to move on to the 7th visit';
export const DIRECT_ACCESS_REGULATIONS_DATA_IS_MASKED = 'Direct access regulations data is masked';
export const DIRECT_ACCESS_REGULATIONS_ERROR_STRING = 'Unable to load to display direct access regulations data';
export const MEMBER_INFO_ERROR_STRING = 'Unable to load to display member data';
export const JUST_NOW = 'just now';
export const FIELD_ERROR = 'Unable to load to display';
export const CREATE_CARE_COORDINATION_WORKFLOW_ERROR = 'There was an error. Please try to create workflow again.';
export const CLINICAL_TEMPLATE_SNACKBAR_DURATION = 7000;
export const CHART_SELECT_ID = 'chart-select';
export const CHART_DATA_TEST_IDS = {
  CHART: 'chart-test-id',
  CHART_DATE_RANGE_PICKER: 'chart-date-range-picker-test-id',
  CHART_SELECT: 'chart-select-test-id'
};
export const PREFERRED_NAME_EDIT_ICON_TEST_ID = 'preferred-name-edit-icon';
export const PREFERRED_NAME_SNACKBAR_SUCCESS_MESSAGE = "The member's preferred name successfully updated";
export const PREFERRED_NAME_SNACKBAR_ERROR_MESSAGE = "Error updating member's preferred name";
export const PREFERRED_NAME_CANNOT_BE_EMPTY = 'Preferred name cannot be empty';
export const PREFERRED_NAME_CHARACTER_LIMIT_MESSAGE = 'Character limit reached (max 30)';
export const PREFERRED_NAME_CHARACTER_LIMIT = 30;
export const PREFERRED_NAME_TEST_ID = 'preferred-name';
export const PREFERRED_NAME_DIALOG_TEST_ID = 'preferred-name-dialog';
export const PREFERRED_NAME_NOT_GIVEN = 'Not given';
export const EDIT_PREFERRED_NAME = 'Edit preferred name';
export const PREFERRED_NAME_PLACEHOLDER = 'Type a name';
export const PREFERRED_NAME_EDIT_CANCEL = 'Cancel';
export const PREFERRED_NAME_EDIT_CONFIRM = 'Confirm';
export const MAX_TAGS_VISIBLE = 7;
export const COMMUNICATION_PREFERENCES_EDIT_DIALOG_TITLE = 'Edit communication preferences';
export const NO_PATHWAY_DIALOG_TITLE = 'Member profile unavailable';
export const NO_PATHWAY_DIALOG_CONTENT =
// eslint-disable-next-line quotes
"This member's program is pending acceptance. The profile will be available once accepted";
export const NO_PATHWAY_DIALOG_BUTTON = 'Go Back';
export const NO_PATHWAY_DIALOG_TEST_ID = 'no-pathway-dialog-test-id';
export enum ContactMethod {
  Sms = 'sms',
  Call = 'call',
  Email = 'email',
}
export const COMMUNICATION_PREFERRED_STRINGS = new Map([[ContactMethod.Sms, 'SMS'], [ContactMethod.Call, 'Call'], [ContactMethod.Email, 'Email']]);
export const COMMUNICATION_NOT_PREFERRED_STRINGS = new Map([[ContactMethod.Sms, 'No SMS'], [ContactMethod.Call, 'No Call'], [ContactMethod.Email, 'No Email']]);
export const COMMUNICATION_PREFERENCES = {
  SMS: 'SMS',
  CALL: 'Call',
  EMAIL: 'Email',
  NO_SMS: 'No SMS',
  NO_CALL: 'No Call',
  NO_EMAIL: 'No Email'
};
export const OPTED_OUT_STRINGS = {
  HH_PROGRAM: 'Mark opted out of the program',
  CTM_COMMUNICATION: 'Mark opted out of care communications'
};
export const OPTED_IN_STRINGS = {
  HH_PROGRAM: 'Mark opted in to the HH Program',
  CTM_COMMUNICATION: 'Mark opted in to CTM Communications'
};
export enum OptingOptions {
  HhProgramOptedIn = 'HH_PROGRAM_OPTED_IN',
  CtmCommunicationOptedIn = 'CTM_COMMUNICATION_OPTED_IN',
  HhProgramOptedOut = 'HH_PROGRAM_OPTED_OUT',
  CtmCommunicationOptedOut = 'CTM_COMMUNICATION_OPTED_OUT',
}
export const HH_PROGRAM_OPT_OUT_CONFIRMATION_MESSAGE = 'By taking this action, the member will not receive any communication from Hinge Health. Please provide a reason for this action.';
export const HH_PROGRAM_OPT_IN_CONFIRMATION_MESSAGE = 'Please confirm that this member has expressed a desire to resume with Hinge Health Program';
export const OPTING_OUT_DROPDOWN_PLACEHOLDER = 'Select a reason';
export const OPTING_OUT_REASONS_OPTIONS = {
  HH_PROGRAM: [{
    displayName: 'Member requests to opt out of program'
  }, {
    displayName: 'Member inappropriate for program due to medical concerns'
  }, {
    displayName: 'Client discontinued program'
  }, {
    displayName: 'Member’s insurance has lapsed'
  }, {
    displayName: 'Other'
  }],
  CTM_COMMUNICATION: [{
    displayName: 'Member requests to opt out of care team communication'
  }, {
    displayName: 'Member unresponsive + inactive'
  }, {
    displayName: 'Member attending in person care & requests to pause outreach'
  }, {
    displayName: 'Other'
  }]
};
const hhProgramOptedOutReasonSelectOptions: HHSelectOption[] = OPTING_OUT_REASONS_OPTIONS.HH_PROGRAM.map(reason => ({
  displayName: reason.displayName,
  value: reason.displayName
}));
const ctmCommunicationOptedOutReasonSelectOptions: HHSelectOption[] = OPTING_OUT_REASONS_OPTIONS.CTM_COMMUNICATION.map(reason => ({
  displayName: reason.displayName,
  value: reason.displayName
}));
export const REASON_EMPTY_ERROR_TEXT = 'Please select a reason';
export enum EnrollmentStatus {
  OPT_IN = 'opt_in',
  OPT_OUT = 'opt_out',
}
export const COMMUNICATION_PREFERENCES_CANCEL = 'Cancel';
export const COMMUNICATION_PREFERENCES_CONFIRM = 'Confirm';
export const COMMUNICATION_PREFERENCE_OPT_OUT_WARNING = 'Unchecking all options will opt out the member from CTM communications. Please confirm.';
export const COMMUNICATION_PREFERENCE_OPT_IN_WARNING = 'Checking any option will opt in the member to CTM communications.';
export const COMMUNICATION_PREFERENCES_UPDATED_SNACKBAR_MESSAGE = "Member's communication preferences updated";
export const COMMUNICATION_PREFERENCES_OPTED_OUT_SNACKBAR_MESSAGE = 'The member has been marked as out of the Care Team communications.';
export const COMMUNICATION_PREFERENCES_OPTED_IN_SNACKBAR_MESSAGE = 'The member has been marked for inclusion in Care Team communications.';
export const COMMUNICATION_PREFERENCE_OPTED_OUT_UNDO = 'Undo';
export const COMMUNICATION_PREFERENCES_UPDATED_SNACKBAR_FAILURE_MESSAGE = "Failed to update Member's communication preferences";
export const MEMBER_NAME_TEST_ID = 'member-name';
export const COMMUNICATION_PREFERENCES_FIELD_TEST_ID = 'communication-preferences';
export const COMMUNICATION_PREFERENCES_EDIT_DIALOG_TEST_ID = 'communication-preferences-edit-dialog';
export const HH_PROGRAM_OPT_OUT_DIALOG_CONTENT = {
  title: OPTED_OUT_STRINGS.HH_PROGRAM,
  selectOptions: hhProgramOptedOutReasonSelectOptions,
  confirmationMessage: HH_PROGRAM_OPT_OUT_CONFIRMATION_MESSAGE,
  successMessage: 'Member has been successfully opted out of the Hinge Health Program',
  dialogContent: undefined
};
export const HH_PROGRAM_OPT_IN_DIALOG_CONTENT = {
  title: OPTED_IN_STRINGS.HH_PROGRAM,
  confirmationMessage: HH_PROGRAM_OPT_IN_CONFIRMATION_MESSAGE,
  selectOptions: undefined,
  successMessage: 'Member has been successfully opted in to the Hinge Health Program',
  dialogContent: undefined
};
export const CTM_COMMUNICATION_OPT_OUT_DIALOG_CONTENT = {
  title: OPTED_OUT_STRINGS.CTM_COMMUNICATION,
  selectOptions: ctmCommunicationOptedOutReasonSelectOptions,
  confirmationMessage: 'By taking this action, the member will not receive any future proactive communications (SMS, call, email or IAM) from Care Team members. Please provide a reason for this action.',
  successMessage: 'Member has been successfully opted out of CTM Communications',
  dialogContent: undefined
};
export const CTM_COMMUNICATION_OPT_IN_CONFIRMATION_MESSAGE = 'Please confirm that this member has expressed a desire to resume receiving CTM Communications by selecting communications preferences below.';
export const MEMBER_STRIKTHROUGH_ERROR_MESSAGE = 'Unable to perform this action. Please try again later.';
export const CTM_EDITING_UNAVAILABLE = 'Editing is unavailable as this member has opted out of the program';
export const EDIT_ICON = 'edit-icon';
export const KEY_METRICS_TEST_ID = 'key-metrics-test-id';
export const KEY_METRICS_COL_BREAKPOINT_WIDTH = 860;
export const VIDEO_VISIT_ELIGIBLE = 'Video Visit Eligible';
export const CLOSE_BUTTON = 'Close';
export const FDC_REGULATION_GUIDELINES_URL = 'https://app.getguru.com/card/T9AKjd8c/PT-Org-FDC-Guidelines';

// Video Visit Messages
export const VV_MESSAGES = {
  MEMBER_IS_NOT_ELIGIBLE_FOR_VV: 'The member is not eligible for Video Visits. Please refer to SOP for further details.',
  SENSITIVE_4_VISITS: 'The member has <b>completed 4 out of the 6 permitted</b> Video Visits.',
  SENSITIVE_5_VISITS: 'The member has completed <b>5 out of the 6</b> permitted Video Visits.',
  SENSITIVE_6_VISITS: 'The member has exhausted <b>ALL 6 available Video Visits</b>. Refer to SOP for next steps',
  REGULAR_4_5_VISITS: (visits: number): string => `The member has <b>completed ${visits} out of the 12 permitted</b> Video Visits.`,
  REGULAR_6_VISITS: 'Member has finished their <b>6th Video Visit call</b>. To schedule a 7th Video Visit Member needs to present proof of HCP visit.',
  REGULAR_7_VISITS: 'The member has completed <b>7 out of the 12</b> permitted Video Visits.',
  REGULAR_11_VISITS: 'The member has completed <b>11 out of the 12</b> permitted Video Visits.',
  REGULAR_12_VISITS: 'The member has exhausted <b>all 12 permissible Video Visits</b>. Refer to SOP for next steps'
};