import { CustomUserClaim } from '@okta/okta-auth-js';
import { acuteLayoutUrl, memberLayoutUrl, portalSidebarUrl } from '../../constants/strings/workflow-urls';
import { acuteSidebarWorkflows, CoachWorkflowTypes, PtWorkflowTypes, threePanelLayoutWorkflows } from '../../custom-types';
import { AllAcuteGroupTypes, VideoVisitsWithoutMedicalHistory, VideoVisitsWorkflows, VIDEO_VISIT_WORKFLOWS } from '../../modules/acute-physical-therapy/custom-types';
import { QueueWorkflowIdentifiers } from '../../state/queue-atoms';
import { OktaRole } from '../../types/okta-roles';
export const buildWorkflowUrl = (workflow: QueueWorkflowIdentifiers | undefined, isSimplifiedWorkflowsSplitOn: boolean, groupType?: AllAcuteGroupTypes, panelLayoutWorkflows: string[] = threePanelLayoutWorkflows, acuteSidebarWorkflowList: string[] = acuteSidebarWorkflows): string | null => {
  if (!workflow) {
    return null;
  }
  if (isSimplifiedWorkflowsSplitOn) {
    return memberLayoutUrl(workflow.id, workflow.type, workflow.patientId, {
      isSimplifiedWorkflowsSplitOn
    });
  }
  if (acuteSidebarWorkflowList.includes((workflow?.type as PtWorkflowTypes)) && !VIDEO_VISIT_WORKFLOWS.includes((workflow?.type as VideoVisitsWorkflows | VideoVisitsWithoutMedicalHistory))) {
    return acuteLayoutUrl(workflow.id, workflow.type);
  }
  return panelLayoutWorkflows.includes((workflow?.type as CoachWorkflowTypes)) ? memberLayoutUrl(workflow.id, workflow.type, workflow.patientId, {
    isSimplifiedWorkflowsSplitOn
  }) : portalSidebarUrl(workflow.id, workflow.type, groupType);
};
export const getPHIMaskingTooltip = (isPHIFieldsMasked: boolean): string => `PHI mask ${isPHIFieldsMasked ? 'enabled' : 'disabled'}`;
export const coachAndOsRoles: string[] = [OktaRole.Coach, OktaRole.OnboardingSpecialist];
export const isAdminCoachOrOs = (roles?: string[]): boolean | undefined => roles?.some(role => coachAndOsRoles.includes(role));

// NOTE: `acceptedRoles` is an array of keys used in the `roleMapping` object.
// If you modify this array, make sure to update `roleMapping` to match.
export const acceptedRoles: CustomUserClaim[] = [OktaRole.Coach, OktaRole.OnboardingSpecialist, OktaRole.PhysicalTherapist];
export const getByOktaRole = <CoachValue, OsValue, PtValue>(coachValue: CoachValue, osValue: OsValue, ptValue: PtValue, roles?: CustomUserClaim[]): CoachValue | OsValue | PtValue => {
  const matchedRole = (roles?.find(role => acceptedRoles.includes(role)) as string);
  const roleMapping: Record<string, CoachValue | OsValue | PtValue> = {
    [OktaRole.Coach]: coachValue,
    [OktaRole.OnboardingSpecialist]: osValue,
    [OktaRole.PhysicalTherapist]: ptValue
  };
  return matchedRole ? roleMapping[matchedRole] : ptValue;
};