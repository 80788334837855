import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHStack, HHTypography } from '@hinge-health/react-component-library';
import { Done, DoneAll, Error } from '@mui/icons-material';
import dayjs from 'dayjs';
import { forwardRef } from 'react';
import { WorkflowPayload } from '../../../types';
export interface AppointmentWorkflowCardProps {
  workflow: WorkflowPayload;
  onClick: (workflow: WorkflowPayload) => void;
  isActive?: boolean;
}
enum AppointmentCardVariant {
  'Default' = 'Default',
  'VisitComplete' = 'VisitComplete',
  'WorkflowComplete' = 'WorkflowComplete',
  'Cancelled' = 'Cancelled',
}
const styles = {
  container: {
    gap: '2px',
    justifyContent: 'center',
    alignItems: 'center',
    paddingX: 1,
    paddingY: 0.5,
    height: 64,
    minWidth: 84,
    boxSizing: 'border-box',
    backgroundColor: ctpTokens.palette.sand.shades['12p'],
    borderRadius: 2,
    position: 'relative',
    marginBottom: '0.5rem',
    border: '1px solid transparent',
    cursor: 'pointer',
    '&.active': {
      border: '1px solid #E8E6E4',
      '&::after': {
        content: '""',
        borderLeft: '0.5rem solid transparent',
        borderTop: '0.5rem solid #E8E6E4',
        borderRight: '0.5rem solid transparent',
        position: 'absolute',
        left: '50%',
        bottom: '-0.5rem',
        transform: 'translateX(-50%)'
      }
    },
    '&.WorkflowComplete': {
      backgroundColor: ctpTokens.palette.green[80]
    },
    '&.Cancelled': {
      backgroundColor: ctpTokens.palette.red.shades['12p']
    }
  }
};
const IconVariantMap = {
  [AppointmentCardVariant.Default]: <></>,
  [AppointmentCardVariant.VisitComplete]: <Done sx={{
    fontSize: 16,
    color: ctpTokens.palette.neutral[10]
  }} />,
  [AppointmentCardVariant.WorkflowComplete]: <DoneAll sx={{
    fontSize: 16,
    color: ctpTokens.palette.green[40]
  }} />,
  [AppointmentCardVariant.Cancelled]: <Error sx={{
    fontSize: 16,
    color: ctpTokens.palette.red[20]
  }} />
};
export const AppointmentWorkflowCard: React.FC<AppointmentWorkflowCardProps> = forwardRef((t0, ref) => {
  const $ = _c(20);
  const {
    workflow,
    isActive,
    onClick
  } = t0;
  let t1;
  if ($[0] !== workflow) {
    t1 = workflow ?? {};
    $[0] = workflow;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const {
    customFields,
    state
  } = t1;
  let t2;
  if ($[2] !== customFields) {
    t2 = customFields ?? {};
    $[2] = customFields;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const {
    callDate,
    durationInMinutes
  } = t2;
  let variant;
  bb0: switch (state) {
    case "perform-video-visit-followup":
      {}
    case "perform-followup-video-visit-followup":
      {
        variant = AppointmentCardVariant.VisitComplete;
        break bb0;
      }
    case "cancel-video-visit":
      {
        variant = AppointmentCardVariant.Cancelled;
        break bb0;
      }
    case "acute-complete":
      {}
    case "complete":
      {
        variant = AppointmentCardVariant.WorkflowComplete;
        break bb0;
      }
    default:
      {
        variant = AppointmentCardVariant.Default;
      }
  }
  const t3 = isActive ? `active ${variant}` : variant;
  let t4;
  if ($[4] !== onClick || $[5] !== workflow) {
    t4 = () => onClick(workflow);
    $[4] = onClick;
    $[5] = workflow;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  const t5 = IconVariantMap[variant];
  let t6;
  if ($[7] !== callDate) {
    t6 = dayjs(callDate).format("hh:mm a");
    $[7] = callDate;
    $[8] = t6;
  } else {
    t6 = $[8];
  }
  let t7;
  if ($[9] !== t6) {
    t7 = <HHTypography hhVariant="h4">{t6}</HHTypography>;
    $[9] = t6;
    $[10] = t7;
  } else {
    t7 = $[10];
  }
  const t8 = durationInMinutes ? `${durationInMinutes}min` : "";
  let t9;
  if ($[11] !== t8) {
    t9 = <HHTypography hhVariant="variant-bypass" variant="body2" color={ctpTokens.palette.neutral[40]}>{t8}</HHTypography>;
    $[11] = t8;
    $[12] = t9;
  } else {
    t9 = $[12];
  }
  let t10;
  if ($[13] !== ref || $[14] !== t3 || $[15] !== t4 || $[16] !== t5 || $[17] !== t7 || $[18] !== t9) {
    t10 = <HHStack ref={ref} sx={styles.container} className={t3} onClick={t4}>{t5}{t7}{t9}</HHStack>;
    $[13] = ref;
    $[14] = t3;
    $[15] = t4;
    $[16] = t5;
    $[17] = t7;
    $[18] = t9;
    $[19] = t10;
  } else {
    t10 = $[19];
  }
  return t10;
});