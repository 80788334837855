import { c as _c } from "react-compiler-runtime";
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ON } from '../constants/strings/split';
import { baseCoachQueueWorkflowTypes, CoachWorkflowTypes, OsWorkflowTypes, PtWorkflowTypes, WorkflowTypes } from '../custom-types';
import { SPLIT_TREATMENTS } from '../modules/dashboard/constants/splits';
import { defaultPtWorkflows } from '../modules/dashboard/types/pt';
import { OktaRole } from '../types/okta-roles';
import { useCurrentStoredAdminHook } from './use-current-stored-admin';
const getSplitTreatmentClient = (splitClient: SplitIO.IBrowserClient | null, adminUuid: string | undefined) => (treatment: string): boolean => {
  const splitTreatmentOn = splitClient?.getTreatment(treatment, {
    adminUuid: adminUuid || '*'
  }) === ON;
  return splitTreatmentOn;
};
export const useGetQueueWorkflowTypes = () => {
  const $ = _c(18);
  const splitClient = useClient();
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = [];
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const [workflowTypesToCount, setWorkflowTypesToCount] = useState(t0);
  const getSplitTreatmentOn = getSplitTreatmentClient(splitClient, currentStoredAdmin?.uuid);
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const ptActionPlanReviewWorkflowSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.PT_ACTION_PLAN_REVIEW);
  const setupActionPlanTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.SETUP_ACTION_PLAN_WORKFLOW);
  const coachWeeklyGoalExpiryTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.COACH_WEEKLY_GOAL_EXPIRY);
  const coachVideoVisitTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.COACH_VIDEO_VISIT);
  let t1;
  if ($[1] !== coachWeeklyGoalExpiryTreatment || $[2] !== coachVideoVisitTreatment) {
    t1 = () => {
      let coachWorkflowTypesList = [...baseCoachQueueWorkflowTypes];
      if (coachWeeklyGoalExpiryTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachWeeklyGoalExpiry];
      }
      coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal];
      if (coachVideoVisitTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachVideoVisit];
      }
      setWorkflowTypesToCount((coachWorkflowTypesList as T[]));
    };
    $[1] = coachWeeklyGoalExpiryTreatment;
    $[2] = coachVideoVisitTreatment;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  const updateCoachWorkflowTypes = t1;
  let t2;
  if ($[4] !== ptActionPlanReviewWorkflowSplitOn || $[5] !== setupActionPlanTreatment) {
    t2 = () => {
      let ptWorkflowTypesList = [...defaultPtWorkflows];
      if (ptActionPlanReviewWorkflowSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.ActionPlanReview];
      }
      if (setupActionPlanTreatment) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.SetupActionPlan];
      }
      setWorkflowTypesToCount((ptWorkflowTypesList as T[]));
    };
    $[4] = ptActionPlanReviewWorkflowSplitOn;
    $[5] = setupActionPlanTreatment;
    $[6] = t2;
  } else {
    t2 = $[6];
  }
  const updatePtWorkflowTypes = t2;
  let t3;
  if ($[7] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = () => {
      const osWorkflowTypesList = [OsWorkflowTypes.UnreadMessages, OsWorkflowTypes.MemberAlertReminder, OsWorkflowTypes.ReEngager, OsWorkflowTypes.OutreachNeeded, OsWorkflowTypes.Nuto, OsWorkflowTypes.Ruto];
      setWorkflowTypesToCount(((osWorkflowTypesList as unknown) as T[]));
    };
    $[7] = t3;
  } else {
    t3 = $[7];
  }
  const updateOsWorkflowTypes = t3;
  const roles = hingeHealthAuthState?.accessToken?.claims.roles;
  let t4;
  let t5;
  if ($[8] !== roles) {
    t5 = roles?.includes(OktaRole.PhysicalTherapist);
    $[8] = roles;
    $[9] = t5;
  } else {
    t5 = $[9];
  }
  t4 = t5;
  const isPhysicalTherapist = t4;
  let t6;
  let t7;
  if ($[10] !== roles) {
    t7 = roles?.includes(OktaRole.OnboardingSpecialist);
    $[10] = roles;
    $[11] = t7;
  } else {
    t7 = $[11];
  }
  t6 = t7;
  const isOnboardingSpecialist = t6;
  let t8;
  let t9;
  if ($[12] !== isPhysicalTherapist || $[13] !== updatePtWorkflowTypes || $[14] !== isOnboardingSpecialist || $[15] !== updateCoachWorkflowTypes) {
    t8 = () => {
      if (isPhysicalTherapist) {
        updatePtWorkflowTypes();
      } else {
        if (isOnboardingSpecialist) {
          updateOsWorkflowTypes();
        } else {
          updateCoachWorkflowTypes();
        }
      }
    };
    t9 = [updateCoachWorkflowTypes, updatePtWorkflowTypes, isPhysicalTherapist, isOnboardingSpecialist, updateOsWorkflowTypes];
    $[12] = isPhysicalTherapist;
    $[13] = updatePtWorkflowTypes;
    $[14] = isOnboardingSpecialist;
    $[15] = updateCoachWorkflowTypes;
    $[16] = t8;
    $[17] = t9;
  } else {
    t8 = $[16];
    t9 = $[17];
  }
  useEffect(t8, t9);
  return workflowTypesToCount;
};