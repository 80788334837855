import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox, HHDate, HHGrid, HHTypography, PriorityStates } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { get, lowerCase } from 'lodash';
import { useNavigate } from 'react-router';
import { ON } from '../../constants/strings/split';
import { useCurrentStoredAdminHook } from '../../hooks/use-current-stored-admin';
import { PT_MEMBER_VIEW_HEADER_TITLE } from '../../modules/acute-physical-therapy/constants/strings/acute';
import { getVideoVisitsTypeByFollowups } from '../../modules/acute-physical-therapy/utils/video-visits';
import { COACH_WORKFLOW_TYPES } from '../../modules/dashboard/constants/strings/coach-workflow-types';
import { SIMPLIFIED_WORKFLOWS } from '../../modules/member-360/constants/splits';
import { MEMBER_ALERT_THEME } from '../../modules/member-360/modules/member-alerts/constants/theme';
import { buildWorkflowUrl } from '../utils';
import { useUpcomingEncounter } from './hooks/use-upcoming-encounter';
const styles = {
  upcomingEncounterBox: {
    backgroundColor: ctpTokens.palette.neutral['99'],
    mb: 6,
    mx: 8,
    py: 2,
    px: 4,
    cursor: 'pointer',
    borderRadius: 1
  },
  title: {
    mb: 2
  },
  subtitle: {
    mt: 1,
    color: MEMBER_ALERT_THEME.COLOR.TEXT.SECONDARY
  },
  upcomingEncounterDateGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
};
export const UpcomingEncounterBox = () => {
  const $ = _c(29);
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const upcomingEncounter = useUpcomingEncounter();
  const splitClient = useClient();
  const navigate = useNavigate();
  if (!upcomingEncounter) {
    let t0;
    if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <></>;
      $[0] = t0;
    } else {
      t0 = $[0];
    }
    return t0;
  }
  const {
    callDate,
    patientDetails,
    followups,
    applicationPathway,
    type,
    workflowId,
    patientId
  } = upcomingEncounter;
  const isSimplifiedWorkflowsSplitOn = splitClient?.getTreatment(SIMPLIFIED_WORKFLOWS, {
    adminUuid: currentStoredAdmin?.uuid || "*"
  }) === ON;
  let t0;
  if ($[1] !== workflowId || $[2] !== patientId || $[3] !== type || $[4] !== isSimplifiedWorkflowsSplitOn || $[5] !== navigate) {
    t0 = () => {
      const encounterWorkflowUrl = buildWorkflowUrl({
        id: workflowId,
        patientId: patientId || "",
        type,
        nextId: 0
      }, isSimplifiedWorkflowsSplitOn);
      if (encounterWorkflowUrl) {
        navigate(encounterWorkflowUrl);
      }
    };
    $[1] = workflowId;
    $[2] = patientId;
    $[3] = type;
    $[4] = isSimplifiedWorkflowsSplitOn;
    $[5] = navigate;
    $[6] = t0;
  } else {
    t0 = $[6];
  }
  const navigateToUpcomingEncounter = t0;
  const title = `Upcoming ${lowerCase(get(PT_MEMBER_VIEW_HEADER_TITLE, type, get(COACH_WORKFLOW_TYPES, type, "")))}`;
  let t1;
  if ($[7] !== followups || $[8] !== applicationPathway || $[9] !== type) {
    t1 = getVideoVisitsTypeByFollowups(followups, applicationPathway, type);
    $[7] = followups;
    $[8] = applicationPathway;
    $[9] = type;
    $[10] = t1;
  } else {
    t1 = $[10];
  }
  const subtitle = t1;
  let t2;
  if ($[11] !== title) {
    t2 = <HHBox sx={styles.title}><HHTypography hhVariant="h3">{title}</HHTypography></HHBox>;
    $[11] = title;
    $[12] = t2;
  } else {
    t2 = $[12];
  }
  let t3;
  if ($[13] !== patientDetails) {
    t3 = <HHBox><HHTypography hhVariant="body1">{patientDetails}</HHTypography></HHBox>;
    $[13] = patientDetails;
    $[14] = t3;
  } else {
    t3 = $[14];
  }
  let t4;
  if ($[15] !== subtitle) {
    t4 = <HHBox sx={styles.subtitle}><HHTypography hhVariant="body1">{subtitle}</HHTypography></HHBox>;
    $[15] = subtitle;
    $[16] = t4;
  } else {
    t4 = $[16];
  }
  let t5;
  if ($[17] !== t2 || $[18] !== t3 || $[19] !== t4) {
    t5 = <HHGrid item={true} xs={9}>{t2}{t3}{t4}</HHGrid>;
    $[17] = t2;
    $[18] = t3;
    $[19] = t4;
    $[20] = t5;
  } else {
    t5 = $[20];
  }
  let t6;
  if ($[21] !== callDate) {
    t6 = <HHGrid item={true} xs={3} sx={styles.upcomingEncounterDateGrid}><HHDate dueDate={callDate} iconPosition="left" dueState={PriorityStates.Default} iconColor={ctpTokens.palette.navy["40"]} showScheduledTime={true} /></HHGrid>;
    $[21] = callDate;
    $[22] = t6;
  } else {
    t6 = $[22];
  }
  let t7;
  if ($[23] !== t5 || $[24] !== t6) {
    t7 = <HHGrid container={true}>{t5}{t6}</HHGrid>;
    $[23] = t5;
    $[24] = t6;
    $[25] = t7;
  } else {
    t7 = $[25];
  }
  let t8;
  if ($[26] !== navigateToUpcomingEncounter || $[27] !== t7) {
    t8 = <HHBox sx={styles.upcomingEncounterBox} onClick={navigateToUpcomingEncounter}>{t7}</HHBox>;
    $[26] = navigateToUpcomingEncounter;
    $[27] = t7;
    $[28] = t8;
  } else {
    t8 = $[28];
  }
  return t8;
};