import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox, HHButton, HHIconButton, HHStack, HHTypography } from '@hinge-health/react-component-library';
import { DoneAll, Error, KeyboardArrowRightRounded, Timer } from '@mui/icons-material';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../constants';
import { WorkflowPayload } from '../../../types';
import { THIRTY_SECONDS_IN_MS } from '../constants';
import { getVideoVisitType, isWithinNext10Minutes } from '../utils';
dayjs.extend(isBetween);
interface MemberProfileCardProps {
  workflow: WorkflowPayload;
}
const styles = {
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1,
    flexDirection: 'row',
    borderRadius: 2,
    padding: 4,
    backgroundColor: ctpTokens.palette.sand.shades['8p']
  },
  button: {
    border: `1px solid ${ctpTokens.palette.neutral.shades.secondary['30p']}`,
    color: ctpTokens.palette.neutral[10],
    paddingX: 4,
    paddingY: 1,
    '&:hover': {
      backgroundColor: ctpTokens.palette.neutral.shades.secondary['8p']
    }
  },
  within10Minutes: {
    backgroundColor: ctpTokens.palette.green[30],
    cursor: 'pointer'
  },
  timerSection: {
    borderRadius: 2,
    textAlign: 'center',
    backgroundColor: ctpTokens.palette.green[90]
  },
  timer: {
    display: 'flex',
    padding: 0.5,
    gap: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconButton: {
    backgroundColor: ctpTokens.palette.neutral[100],
    padding: 1,
    '&:hover': {
      backgroundColor: ctpTokens.palette.neutral[90]
    }
  }
};
export const MemberProfileCard: React.FC<MemberProfileCardProps> = t0 => {
  const $ = _c(58);
  const {
    workflow
  } = t0;
  let t1;
  if ($[0] !== workflow) {
    t1 = workflow ?? {};
    $[0] = workflow;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const {
    state,
    patient,
    customFields
  } = t1;
  let t2;
  if ($[2] !== customFields) {
    t2 = customFields ?? {};
    $[2] = customFields;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const {
    callDate
  } = t2;
  let t3;
  if ($[4] !== patient) {
    t3 = patient ?? {};
    $[4] = patient;
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  const {
    firstName: t4,
    lastName: t5,
    id
  } = t3;
  const firstName = t4 === undefined ? "" : t4;
  const lastName = t5 === undefined ? "" : t5;
  const [minutesToStart, setMinutesToStart] = useState(0);
  const navigate = useNavigate();
  let t6;
  let t7;
  if ($[6] !== callDate) {
    t6 = () => {
      setMinutesToStart(dayjs(callDate).diff(dayjs(), "minutes"));
      const id_0 = setInterval(() => setMinutesToStart(dayjs(callDate).diff(dayjs(), "minutes")), THIRTY_SECONDS_IN_MS);
      return () => clearInterval(id_0);
    };
    t7 = [callDate];
    $[6] = callDate;
    $[7] = t6;
    $[8] = t7;
  } else {
    t6 = $[7];
    t7 = $[8];
  }
  useEffect(t6, t7);
  let t8;
  if ($[9] !== workflow) {
    t8 = getVideoVisitType(workflow);
    $[9] = workflow;
    $[10] = t8;
  } else {
    t8 = $[10];
  }
  const videoVisitType = t8;
  let t9;
  if ($[11] !== navigate || $[12] !== id) {
    t9 = () => {
      navigate(`${ROUTES.MEMBER}/${id}/profile`);
    };
    $[11] = navigate;
    $[12] = id;
    $[13] = t9;
  } else {
    t9 = $[13];
  }
  const navigateToProfile = t9;
  if (isWithinNext10Minutes(callDate)) {
    let t10;
    if ($[14] === Symbol.for("react.memo_cache_sentinel")) {
      t10 = <Timer sx={{
        fontSize: "16px"
      }} />;
      $[14] = t10;
    } else {
      t10 = $[14];
    }
    let t11;
    if ($[15] !== minutesToStart) {
      t11 = <HHBox sx={styles.timer}>{t10}<HHTypography hhVariant="body2">Starts in {minutesToStart} minutes</HHTypography></HHBox>;
      $[15] = minutesToStart;
      $[16] = t11;
    } else {
      t11 = $[16];
    }
    let t12;
    if ($[17] === Symbol.for("react.memo_cache_sentinel")) {
      t12 = {
        ...styles.container,
        ...styles.within10Minutes
      };
      $[17] = t12;
    } else {
      t12 = $[17];
    }
    const t13 = `${firstName} ${lastName}`;
    let t14;
    if ($[18] !== t13) {
      t14 = <HHTypography hhVariant="variant-bypass" variant="body1" fontWeight={500} fontSize="1rem" color={ctpTokens.palette.neutral[100]}>{t13}</HHTypography>;
      $[18] = t13;
      $[19] = t14;
    } else {
      t14 = $[19];
    }
    let t15;
    if ($[20] !== videoVisitType) {
      t15 = <HHTypography hhVariant="variant-bypass" variant="body1" color={ctpTokens.palette.neutral[80]}>{videoVisitType}</HHTypography>;
      $[20] = videoVisitType;
      $[21] = t15;
    } else {
      t15 = $[21];
    }
    let t16;
    if ($[22] !== t14 || $[23] !== t15) {
      t16 = <HHStack gap={1}>{t14}{t15}</HHStack>;
      $[22] = t14;
      $[23] = t15;
      $[24] = t16;
    } else {
      t16 = $[24];
    }
    let t17;
    if ($[25] === Symbol.for("react.memo_cache_sentinel")) {
      t17 = <KeyboardArrowRightRounded sx={{
        color: ctpTokens.palette.green[30]
      }} />;
      $[25] = t17;
    } else {
      t17 = $[25];
    }
    let t18;
    if ($[26] !== navigateToProfile) {
      t18 = <HHIconButton hhVariant="variant-bypass" sx={styles.iconButton} onClick={navigateToProfile}>{t17}</HHIconButton>;
      $[26] = navigateToProfile;
      $[27] = t18;
    } else {
      t18 = $[27];
    }
    let t19;
    if ($[28] !== navigateToProfile || $[29] !== t16 || $[30] !== t18) {
      t19 = <HHStack sx={t12} onClick={navigateToProfile}>{t16}{t18}</HHStack>;
      $[28] = navigateToProfile;
      $[29] = t16;
      $[30] = t18;
      $[31] = t19;
    } else {
      t19 = $[31];
    }
    let t20;
    if ($[32] !== t11 || $[33] !== t19) {
      t20 = <HHBox sx={styles.timerSection}>{t11}{t19}</HHBox>;
      $[32] = t11;
      $[33] = t19;
      $[34] = t20;
    } else {
      t20 = $[34];
    }
    return t20;
  }
  const t10 = `${firstName} ${lastName}`;
  let t11;
  if ($[35] !== t10) {
    t11 = <HHTypography hhVariant="variant-bypass" variant="body1" fontWeight={500} fontSize="1rem" color={ctpTokens.palette.neutral[10]}>{t10}</HHTypography>;
    $[35] = t10;
    $[36] = t11;
  } else {
    t11 = $[36];
  }
  let t12;
  if ($[37] !== videoVisitType) {
    t12 = <HHTypography hhVariant="variant-bypass" variant="body1" color={ctpTokens.palette.neutral[40]}>{videoVisitType}</HHTypography>;
    $[37] = videoVisitType;
    $[38] = t12;
  } else {
    t12 = $[38];
  }
  let t13;
  if ($[39] !== t11 || $[40] !== t12) {
    t13 = <HHStack gap={1}>{t11}{t12}</HHStack>;
    $[39] = t11;
    $[40] = t12;
    $[41] = t13;
  } else {
    t13 = $[41];
  }
  let t14;
  if ($[42] !== state || $[43] !== navigateToProfile) {
    t14 = state === "complete-video-visit" && <HHButton hhVariant="variant-bypass" variant="outlined" sx={styles.button} onClick={navigateToProfile}>View Profile</HHButton>;
    $[42] = state;
    $[43] = navigateToProfile;
    $[44] = t14;
  } else {
    t14 = $[44];
  }
  let t15;
  if ($[45] !== state) {
    t15 = state === "cancel-video-visit" && <HHBox sx={{
      display: "flex",
      gap: 1
    }}><Error sx={{
        fontSize: 16,
        color: ctpTokens.palette.red[40]
      }} /><HHTypography hhVariant="variant-bypass" variant="body2" sx={{
        color: ctpTokens.palette.red[40]
      }}>Cancelled</HHTypography></HHBox>;
    $[45] = state;
    $[46] = t15;
  } else {
    t15 = $[46];
  }
  let t16;
  if ($[47] !== state || $[48] !== navigateToProfile) {
    t16 = ["perform-video-visit-followup", "perform-followup-video-visit-followup"].includes(state) && <HHButton hhVariant="variant-bypass" variant="outlined" sx={styles.button} onClick={navigateToProfile}>Post Visit Tasks</HHButton>;
    $[47] = state;
    $[48] = navigateToProfile;
    $[49] = t16;
  } else {
    t16 = $[49];
  }
  let t17;
  if ($[50] !== state) {
    t17 = ["acute-complete", "complete"].includes(state) && <HHBox sx={{
      display: "flex",
      gap: 1
    }}><DoneAll sx={{
        fontSize: 16
      }} /><HHTypography hhVariant="body2">Completed</HHTypography></HHBox>;
    $[50] = state;
    $[51] = t17;
  } else {
    t17 = $[51];
  }
  let t18;
  if ($[52] !== t13 || $[53] !== t14 || $[54] !== t15 || $[55] !== t16 || $[56] !== t17) {
    t18 = <HHStack sx={styles.container}>{t13}{t14}{t15}{t16}{t17}</HHStack>;
    $[52] = t13;
    $[53] = t14;
    $[54] = t15;
    $[55] = t16;
    $[56] = t17;
    $[57] = t18;
  } else {
    t18 = $[57];
  }
  return t18;
};