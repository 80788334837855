import React, { useEffect } from 'react';
export const useBulkProgressTimeout = (progressFlag: boolean, setShouldRefetchWorkflows: React.Dispatch<React.SetStateAction<boolean>>, setProgress: React.Dispatch<React.SetStateAction<boolean>>, timeout = 30000): void => {
  useEffect(() => {
    if (progressFlag) {
      const timeoutId = setTimeout(() => {
        setProgress(false);
        setShouldRefetchWorkflows(true);
      }, timeout);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressFlag]);
};