import { c as _c } from "react-compiler-runtime";
import { HHBox, HHCircularProgress } from '@hinge-health/react-component-library';
import * as Sentry from '@sentry/react';
import { Provider } from 'jotai';
import { useNavigateToDashboard } from '../hooks/use-navigate-to-dashboard';
import { MpeDashboard } from '../modules/mpe-dashboard';
const ErrorPage = () => {
  const $ = _c(1);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = <HHBox>Unable to load Dashboard</HHBox>;
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  return t0;
};
export const MpeDashboardPage = () => {
  const $ = _c(4);
  const {
    loading,
    error
  } = useNavigateToDashboard();
  if (loading) {
    let t0;
    if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <HHCircularProgress />;
      $[0] = t0;
    } else {
      t0 = $[0];
    }
    return t0;
  }
  if (error) {
    let t0;
    if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
      t0 = <ErrorPage />;
      $[1] = t0;
    } else {
      t0 = $[1];
    }
    return t0;
  }
  let t0;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = <ErrorPage />;
    $[2] = t0;
  } else {
    t0 = $[2];
  }
  let t1;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = <Provider><Sentry.ErrorBoundary fallback={t0}><MpeDashboard /></Sentry.ErrorBoundary></Provider>;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  return t1;
};