import { HHBox, HHCircularProgress, useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { SignInOptions } from '@hinge-health/react-component-library/dist/components/auth/hinge-health-security-context';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OktaRole } from '../types/okta-roles';
import { getLocalStorageItem } from '../utils/local-storage-utils';
import RootScreen from './root-screen';
interface AuthenticatedPageProps {
  children: React.ReactNode;
  hideSideNavigation?: boolean;
}
const styles = {
  containerStyle: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  spinnerStyle: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
};
const AuthenticatedPage = ({
  hideSideNavigation,
  children
}: AuthenticatedPageProps): JSX.Element => {
  const {
    hingeHealthAuthState,
    hingeHealthAuthClient
  } = useHingeHealthSecurityContext();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!hingeHealthAuthState) {
      return;
    }
    if (!hingeHealthAuthState?.isAuthenticated) {
      const signInOptions = ({
        originalUri: `${location.pathname}?redirect=${process.env.REACT_APP_BASE_NAME}`
      } as SignInOptions);
      hingeHealthAuthClient.signInWithRedirect(signInOptions);
    } else {
      const userRoles = hingeHealthAuthState?.accessToken?.claims?.roles;
      const isPT = userRoles?.includes(OktaRole.PhysicalTherapist);
      const isCoach = userRoles?.includes(OktaRole.Coach);
      const isOS = userRoles?.includes(OktaRole.OnboardingSpecialist);
      const hasRedirectedToPT = getLocalStorageItem('hasRedirectedToPT') === 'true';
      const hasRedirectedToCoach = getLocalStorageItem('hasRedirectedToCoach') === 'true';
      const hasRedirectedToOS = getLocalStorageItem('hasRedirectedToOS') === 'true';
      if (isPT && !hasRedirectedToPT) {
        // Set the flag in local storage
        localStorage.setItem('hasRedirectedToPT', 'true');
        navigate('/pt', {
          replace: true
        });
      }
      if (isCoach && !hasRedirectedToCoach) {
        // Set the flag in local storage
        localStorage.setItem('hasRedirectedToCoach', 'true');
        navigate('/coach', {
          replace: true
        });
      }
      if (isOS && !hasRedirectedToOS) {
        // Set the flag in local storage
        localStorage.setItem('hasRedirectedToOS', 'true');
        navigate('/os', {
          replace: true
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hingeHealthAuthState, hingeHealthAuthClient, navigate]);
  return <HHBox sx={styles.containerStyle}>
      {!hingeHealthAuthState?.isAuthenticated ? <HHCircularProgress size={24} sx={styles.spinnerStyle} /> : <RootScreen hideSideNavigation={hideSideNavigation}>
          {children}
        </RootScreen>}
    </HHBox>;
};
export default AuthenticatedPage;