import { c as _c } from "react-compiler-runtime";
import { HHBox, HHCircularProgress } from '@hinge-health/react-component-library';
import * as Sentry from '@sentry/react';
import { Provider } from 'jotai';
import { COACH } from '../constants/strings/coach';
import { useCurrentStoredAdminHook } from '../hooks/use-current-stored-admin';
import { useNavigateToDashboard } from '../hooks/use-navigate-to-dashboard';
import { useSse } from '../hooks/use-sse';
import { DashboardPage } from '../modules/dashboard';
import { ShellProvider } from '../modules/dashboard/contexts/shell.context';
import { DashboardType } from '../modules/dashboard/custom-types';
import { Admin } from '../types';
import { genericMixpanelTrack, mixpanelTrack } from '../utils/mixpanel';
export const LOADER_ID = 'coach-page-loader';
const Error = () => {
  const $ = _c(1);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = <HHBox>{COACH.ERROR}</HHBox>;
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  return t0;
};
const Coach = (): JSX.Element => {
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const {
    loading,
    error
  } = useNavigateToDashboard();
  const shellProps = {
    loading,
    adminProfile: (currentStoredAdmin as Admin),
    mixpanelTrack,
    genericMixpanelTrack,
    useSse
  };
  if (loading) return <HHCircularProgress data-testid={LOADER_ID} />;
  return <Provider>
      <Sentry.ErrorBoundary fallback={<Error />}>
        {error && <Error />}
        {currentStoredAdmin && <ShellProvider {...shellProps}>
            <DashboardPage loading={loading} dashboardType={DashboardType.Coach} />
          </ShellProvider>}
      </Sentry.ErrorBoundary>
    </Provider>;
};
export default Coach;