import React, { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import { SseEvents as CommonSseEvents } from '../constants/strings/events';
import { CommonCoachState } from '../custom-types';
import { useGenericMixpanelTrack } from '../hooks/use-generic-mixpanel';
import { useSse } from '../hooks/use-sse';
import { MIXPANEL_EVENTS } from '../lib/mixpanel/mixpanel-event-names';
import { AutoWorkflowTransitionMixpanelProperties, MixpanelPropertiesContext } from '../lib/mixpanel/types/generic-mixpanel-event-types';
import { CommonOsState } from '../modules/dashboard/types/os';
import { CommonPtState } from '../modules/dashboard/types/pt';
import { SseEvents } from '../modules/member-360/constants/events';
import { allCompletedStates } from '../modules/member-360/modules/workflows/constants';
import { WorkflowPayload } from '../types';
enum TransitionSource {
  InApp = 'inapp',
  Sms = 'sms',
}
export interface WorkflowCreatedSseData {
  type: SseEvents.WorkflowCreated;
  serviceName: string;
  stateName: string;
  workflowId: number;
  adminUuids: string[];
  customFields?: Record<string, unknown>;
  referenceEventId?: string;
  userUuid?: string;
  patientUuid?: string;
  correlationId?: string;
}
export interface WorkflowTransitionedSseData {
  type: SseEvents.WorkflowTransitioned;
  serviceName: string;
  transitionName: string;
  workflowId: number;
  stateName: string;
  adminUuids: string[];
  unpauseToState?: string;
  taskAdminUuid?: string;
  isBulkTransition: boolean;
  patientUuid?: string;
  correlationId?: string;
  transitionSource?: TransitionSource;
  careTeamUuids?: string[];
}
export interface TaskUpdatedSseData {
  type: SseEvents.TaskUpdated;
  workflow: WorkflowPayload;
  id: number;
  adminUuid: string;
  name: string;
  dueDate: string;
  selection: string;
  archived: boolean;
  state: string;
  completedAt: string;
  createdAt: string;
  updatedAt: string;
  correlationId?: string;
}
export interface BulkWorkflowTransitionSseData {
  type: SseEvents.BulkWorkflowTransitionCompleted | SseEvents.BulkWorkflowTransitionFailed;
  submissionId: string;
  workflowIdsSuccessfullyTransitioned: number[];
  workflowIdsFailedTransition: number[];
  adminUUIDs: string[];
  workflowType: string;
  correlationId?: string;
}
export interface CareCoordinationSseData {
  workflowId?: number;
  workflowType: string;
  patientUuid: string;
  adminUuids?: string[];
  type: SseEvents.CareCoordinationWorkflowCreated | SseEvents.CareCoordinationWorkflowFailed;
  correlationId?: string;
}
export interface VideoVisitRecordingSseData {
  adminUuid: string;
  userUuid: string;
  userName: string;
  type: CommonSseEvents.VideoVisitRecordingReady | CommonSseEvents.VideoVisitRecordingFailed;
  correlationId?: string;
}
export type SseData = undefined | WorkflowCreatedSseData | WorkflowTransitionedSseData | TaskUpdatedSseData | BulkWorkflowTransitionSseData | CareCoordinationSseData | VideoVisitRecordingSseData;
export interface SseContextType {
  sseData?: SseData;
}
const SseContext = createContext<SseContextType>({});
export const SseProvider: React.FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const sseData = (useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, [SseEvents.WorkflowCreated, SseEvents.WorkflowTransitioned, SseEvents.TaskUpdated, SseEvents.CareCoordinationWorkflowCreated]) as SseContextType['sseData']);
  const trackEvent = useGenericMixpanelTrack([MixpanelPropertiesContext.MemberContext, MixpanelPropertiesContext.WorkflowContext]);
  useEffect(() => {
    if (sseData?.type === SseEvents.WorkflowTransitioned && allCompletedStates.includes((sseData.stateName as CommonCoachState | CommonPtState | CommonOsState))) {
      const transitionSource = sseData?.transitionSource;
      if (transitionSource === TransitionSource.Sms || transitionSource === TransitionSource.InApp) {
        trackEvent<AutoWorkflowTransitionMixpanelProperties>(MIXPANEL_EVENTS.WORKFLOW_AUTOMATICALLY_RESOLVED, {
          currentWorkflowId: sseData?.workflowId,
          currentWorkflowName: sseData?.serviceName,
          currentWorkflowMemberUuid: sseData?.patientUuid ?? '',
          currentWorkflowState: sseData?.stateName,
          adminUuid: sseData?.adminUuids.toString()
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseData]);
  const memoizedSseData = useMemo(() => ({
    sseData
  }), [sseData]);
  return <SseContext.Provider value={memoizedSseData}>
      {children}
    </SseContext.Provider>;
};
export const useSseContext = (): SseContextType => useContext(SseContext);