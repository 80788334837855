import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox } from '@hinge-health/react-component-library';
export interface ProgressIndicatorProps {
  current: number;
  max: number;
}
export const ProgressIndicator = t0 => {
  const $ = _c(4);
  const {
    current,
    max
  } = t0;
  const validCurrent = Math.max(0, current);
  const validMax = Math.max(1, max);
  const progress = Math.min(100, validCurrent / validMax * 100);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      width: "100%",
      height: "4px",
      backgroundColor: ctpTokens.palette.sand.shades["30p"],
      borderRadius: "4px"
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const t2 = `${progress}%`;
  let t3;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      height: "4px",
      backgroundColor: ctpTokens.palette.green["40"]
    };
    $[1] = t3;
  } else {
    t3 = $[1];
  }
  let t4;
  if ($[2] !== t2) {
    t4 = <HHBox role="progressbar" sx={t1}><HHBox width={t2} sx={t3} /></HHBox>;
    $[2] = t2;
    $[3] = t4;
  } else {
    t4 = $[3];
  }
  return t4;
};