import { ctpTokens, HHBox, HHButton, HHChip, HHDatePicker, HHDialogActions, HHDialogContent, HHDivider, HHIconButton, HHSelect, HHSelectChangeEvent, HHSelectOption, HHStack, HHTextField, HHTextFieldProps, HHTypography, LayoutStyles, Typography } from '@hinge-health/react-component-library';
import { DeleteOutlineRounded, DoneRounded, InfoOutlined, UploadFileSharp } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { CfInternalRequesterType, CF_SUB_REQUEST_TYPE_MAP, CombinedRequestSubTypeUnion, FRESHDESK_FIELDS, MANAGER_ROLES, OS_ROLES, RequestTypeMapValue, RequestTypeNew, REQUEST_TYPE_MAP, USER_FEEDBACK, VIP_PROGRAM_ROLES } from '../../../constants/strings/user-feedback';
import { useGenericMixpanelTrack } from '../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../lib/mixpanel/mixpanel-event-names';
import { currentUserAtom } from '../../../modules/member-360/state/atoms';
import { FeedbackMessage, FeedbackType } from '../../../utils/freshdesk-dialog-utils';
import { FreshdeskTicketCustomFields, FreshdeskTicketDetails, TicketPriority } from './freshdesk-dialog';
import { layoutStyles } from './freshdesk-dialog-styles';
export const programSelectInputId = 'program-select';
export const indicationSelectInputId = 'indication-select';
export const formatTicketDate = (date: Date | null): string => date && dayjs(date).isValid() ? dayjs(date).startOf('day').format('YYYY-MM-DD') : '';
export const handleDateChange = (date: Date | null, setDateValue: (value: string) => void, setDateError: (value: boolean) => void, updateCustomFields: (callback: (prevData: FreshdeskTicketCustomFields) => FreshdeskTicketCustomFields) => void): void => {
  if (!date) {
    setDateValue('');
    setDateError(false);
    return;
  }
  const validDate = formatTicketDate(date);
  setDateValue(validDate);
  setDateError(!validDate);
  updateCustomFields((prevData: FreshdeskTicketCustomFields) => ({
    ...prevData,
    cf_date_of_issue: validDate
  }));
};
const createUserIdsArrayFromCustomFields = (cfUserIds?: string[] | string): string[] => {
  if (cfUserIds) {
    if (Array.isArray(cfUserIds)) {
      return cfUserIds;
    } else {
      return [cfUserIds];
    }
  }
  return [];
};
const createSelectOptions = (options: readonly string[]): HHSelectOption[] => options.map(option => ({
  value: option,
  label: option,
  displayName: option
}));
const deleteCustomFieldKeys = (customFields: FreshdeskTicketCustomFields, keysToDelete: string[]): FreshdeskTicketCustomFields => {
  const customFieldsKeys = Object.keys(customFields);
  const updatedKeys = customFieldsKeys.reduce((acc, key) => {
    const fieldKey = (key as keyof FreshdeskTicketCustomFields);
    const fieldValue = customFields[fieldKey];
    if (!keysToDelete.includes(key) && fieldValue) {
      acc[fieldKey] = (fieldValue as string);
    }
    return acc;
  }, ({} as FreshdeskTicketCustomFields));
  return updatedKeys;
};
const getCustomFieldValueFromFirstAvailableKey = <T,>(customFields: FreshdeskTicketCustomFields, keys: string[]): T => {
  for (const key of keys) {
    const fieldKey = (key as keyof FreshdeskTicketCustomFields);
    if (fieldKey in customFields && customFields[fieldKey]) {
      return (customFields[fieldKey] as T);
    }
  }
  return (('' as unknown) as T);
};
export const FreshdeskSubmissionForm = ({
  ...props
}): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [typeChip, setTypeChip] = useState<string>(USER_FEEDBACK.BUG_STRING);
  const [role, setRole] = useState<string>(props.customFields.cf_internal_requester_type);
  const [request, setRequest] = useState<RequestTypeNew>(props.customFields.cf_request_type_new);
  const requestSubTypeAvailableKeys = [...new Set(Array.from(REQUEST_TYPE_MAP).map(([_, value]) => value.fieldKey))];
  const requestDetailsAvailableKeys = [...new Set(Array.from(CF_SUB_REQUEST_TYPE_MAP).map(([__0, value_0]) => value_0.fieldKey))];
  const [requestSubType, setRequestSubType] = useState<CombinedRequestSubTypeUnion | undefined>(getCustomFieldValueFromFirstAvailableKey<CombinedRequestSubTypeUnion>(props.customFields, requestSubTypeAvailableKeys));
  const [requestDetails, setRequestDetails] = useState<string | undefined>(getCustomFieldValueFromFirstAvailableKey<string>(props.customFields, requestDetailsAvailableKeys));
  const [roleError, setRoleError] = useState<boolean>(false);
  const [requestError, setRequestError] = useState<boolean>(false);
  const [requestDetailsError, setRequestDetailsError] = useState<boolean>(false);
  const [requestSubTypeError, setRequestSubTypeError] = useState<boolean>(false);
  const [titleHelperText, setTitleHelperText] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [multipleUserIdsError, setMultipleUserIdsError] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<string>(props.customFields.cf_date_of_issue);
  const [dateError, setDateError] = useState<boolean>(false);
  const [priorityValue, setPriorityValue] = useState<string>('');
  const [memberSelectionOption, setMemberSelectionOption] = useState<string>(USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED.MULTIPLE.toUpperCase());
  const [selectedProgram, setSelectedProgram] = useState<string>(props?.customFields?.cf_program || '');
  const [selectedIndication, setSelectedIndication] = useState<string>(props?.customFields?.cf_pathway || '');
  const currentUser = useAtomValue(currentUserAtom);
  const userIdsFromCustomFields = createUserIdsArrayFromCustomFields(props?.customFields?.cf_user_id);
  const [userIds, setUserIds] = useState(userIdsFromCustomFields ? userIdsFromCustomFields.join(', ') : '');
  const [userId, setUserId] = useState(currentUser?.id || (userIdsFromCustomFields?.length === 1 ? userIdsFromCustomFields[0] : ''));
  const genericMixpanelEvents = useGenericMixpanelTrack();
  const validateForm = (): boolean => {
    const subjectLength = props.freshdeskTicketDetails.subject.trim().length;
    const isTitleValid = subjectLength > 0 && subjectLength < USER_FEEDBACK.MAX_CHARACTER_LIMIT;
    const isDescriptionValid = !!props.freshdeskTicketDetails.description;
    const isRoleValid = !!role;
    const isRequestValid = !!request;
    const isMultipleUserIdsValid = userIds.trim().length > 0;
    let isRequestSubTypeValid = true;
    let isRequestDetailsValid = true;
    if (isRequestValid) {
      const isRequestSubTypeVisible = Boolean(REQUEST_TYPE_MAP.get(request));
      if (isRequestSubTypeVisible) {
        isRequestSubTypeValid = Boolean(requestSubType);
        const isRequestDetailsVisible = Boolean(CF_SUB_REQUEST_TYPE_MAP.get((requestSubType as CombinedRequestSubTypeUnion)));
        if (isRequestDetailsVisible) {
          isRequestDetailsValid = Boolean(requestDetails);
        }
      }
    }
    setRoleError(!isRoleValid);
    setRequestError(!isRequestValid);
    setRequestSubTypeError(!isRequestSubTypeValid);
    setRequestDetailsError(!isRequestDetailsValid);
    setDescriptionError(!isDescriptionValid);
    setMultipleUserIdsError(!isMultipleUserIdsValid);
    if (!isTitleValid) {
      if (props.freshdeskTicketDetails.subject.trim().length === 0) {
        setTitleHelperText(USER_FEEDBACK.ERROR_TEXT_VALUE);
      } else if (props.freshdeskTicketDetails.subject.trim().length > 250) {
        setTitleHelperText(USER_FEEDBACK.CHARACTER_LIMIT_ERROR);
      }
    }
    return isTitleValid && isDescriptionValid && isRoleValid && isRequestValid && isRequestSubTypeValid && isRequestDetailsValid && !dateError && !multipleUserIdsError;
  };
  const handleSubmit = (): void => {
    genericMixpanelEvents(MIXPANEL_EVENTS.FRESHDESK_FORM_SUBMIT_BUTTON_CLICKED);
    if (validateForm()) {
      props.handleSubmit();
    }
  };
  const handleSupportChange = (e: HHSelectChangeEvent): void => {
    const request_0 = (e.target.value as RequestTypeNew);
    setRequest(request_0);
    setRequestError(false);
    setRequestSubType(undefined);
    setRequestDetails(undefined);
    if (e.target.value === USER_FEEDBACK.ET_PERSONALIZATION_OPTION) {
      props.updateCustomFields((prevData: FreshdeskTicketCustomFields) => ({
        ...prevData,
        cf_request_type_new: USER_FEEDBACK.CTT_CTM_OPTION,
        cf_care_team_product_affected: e.target.value
      }));
      return;
    }
    props.updateCustomFields((prevData_0: FreshdeskTicketCustomFields) => ({
      ...deleteCustomFieldKeys(prevData_0, [...requestSubTypeAvailableKeys, ...requestDetailsAvailableKeys]),
      cf_request_type_new: request_0
    }));
  };
  const handleTitleChange = (value_1: string): void => {
    if (value_1.length > 250) {
      setTitleHelperText(USER_FEEDBACK.CHARACTER_LIMIT_ERROR);
    } else if (value_1.length === 0) {
      setTitleHelperText(USER_FEEDBACK.ERROR_TEXT_VALUE);
    } else {
      setTitleHelperText('');
    }
    props.updateTicketData((prevData_1: FreshdeskTicketDetails) => ({
      ...prevData_1,
      subject: value_1
    }));
  };
  // Function to trigger file input click
  const handleButtonClick = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically click the file input
    }
  };
  const handleToggleBugFeatureChip = (type: string): void => {
    setTypeChip(type);
    props.handleChipChange(type);
  };
  const memberImpactedOptions: HHSelectOption[] = Object.entries(USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED).map(([key, value_2]) => ({
    displayName: value_2,
    value: key
  }));
  const handlePriorityChange = (e_0: HHSelectChangeEvent): void => {
    const value_3 = (e_0.target.value as string);
    setPriorityValue(value_3);
    props.updateTicketData((prevData_2: FreshdeskTicketDetails) => ({
      ...prevData_2,
      priority: value_3 === FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.PRIORITY_OPTIONAL[1] ? TicketPriority.High : TicketPriority.Low
    }));
  };
  const handleKnowledgeBaseUrlClick = (e_1: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    genericMixpanelEvents(MIXPANEL_EVENTS.FRESHDESK_FORM_KNOWLEGE_BASE_LINK_CLICKED);
    e_1.preventDefault();
    window.open(USER_FEEDBACK.KNOWLEDGE_BASE_URL, '_blank', 'noopener noreferrer');
  };
  const singleUserIdChange = (e_2: React.ChangeEvent<HTMLInputElement>): void => {
    const value_4 = e_2.target.value;
    const regex = /^\d*$/;
    if (regex.test(value_4)) {
      setUserId(value_4);
      props.updateCustomFields((prevData_3: FeedbackMessage) => ({
        ...prevData_3,
        cf_user_id: value_4 ?? currentUser.id
      }));
    }
  };
  const handleMultipleUserIdsChange = (e_3: React.ChangeEvent<HTMLInputElement>): void => {
    const input = e_3.target.value.trim();
    const regex_0 = /^[0-9, ]*$/;
    if (regex_0.test(input) && input.length >= 0) {
      setMultipleUserIdsError(false);
      setUserIds(e_3.target.value);
      const userIds_0 = input.split(',').map(id => id.trim()).filter(Boolean);
      props.updateCustomFields((prevData_4: FeedbackMessage) => ({
        ...prevData_4,
        cf_user_id: userIds_0
      }));
    }
  };
  const handleRequestSubTypeChange = (e_4: HHSelectChangeEvent, requestTypeMapValue: RequestTypeMapValue): void => {
    const value_5 = (e_4.target.value as CombinedRequestSubTypeUnion);
    setRequestDetails(undefined);
    setRequestSubType(value_5);
    setRequestSubTypeError(false);
    props.updateCustomFields((prevData_5: FreshdeskTicketCustomFields) => ({
      ...deleteCustomFieldKeys(prevData_5, [...requestSubTypeAvailableKeys, ...requestDetailsAvailableKeys]),
      [requestTypeMapValue.fieldKey]: value_5
    }));
  };
  const handleRequestDetailsChange = (e_5: HHSelectChangeEvent, requestTypeMapValue_0: RequestTypeMapValue): void => {
    const value_6 = (e_5.target.value as string);
    setRequestDetails(value_6);
    setRequestDetailsError(false);
    props.updateCustomFields((prevData_6: FreshdeskTicketCustomFields) => ({
      ...deleteCustomFieldKeys(prevData_6, requestDetailsAvailableKeys),
      [requestTypeMapValue_0.fieldKey]: value_6
    }));
  };
  useEffect(() => {
    if (memberSelectionOption === USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED.SINGLE.toUpperCase()) {
      if (!currentUser) {
        return;
      }
      const pathways = currentUser?.pathways;
      if (pathways && pathways.length) {
        const CurrentPathway = pathways.find(pathway => pathway.isActive === true) || pathways[0];
        // setting pathway and program
        const pathway_0 = CurrentPathway.indication;
        const program = CurrentPathway.type.charAt(0).toUpperCase() + CurrentPathway.type.slice(1).toLocaleLowerCase();
        setSelectedProgram(program || '');
        // map proper pathway
        const pathwayFromPredefinedFields = FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_PATHWAY.find(pathwayOption => pathwayOption.toLowerCase().includes(pathway_0.toLowerCase()));
        setSelectedIndication(pathwayFromPredefinedFields || '');
        props.updateCustomFields((prevData_7: FeedbackMessage) => ({
          ...prevData_7,
          cf_pathway: pathwayFromPredefinedFields,
          cf_program: program,
          cf_user_id: currentUser.id
        }));
      }
    } else {
      setSelectedProgram('');
      setSelectedIndication('');
      props.updateCustomFields((prevData_8: FeedbackMessage) => ({
        ...prevData_8,
        cf_pathway: '',
        cf_program: ''
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberSelectionOption, currentUser]);
  const getRequestDetailsSelect = (requestSubType_0: CombinedRequestSubTypeUnion): JSX.Element | null => {
    const requestSubTypeMapValue = CF_SUB_REQUEST_TYPE_MAP.get(requestSubType_0);
    if (requestSubTypeMapValue) {
      return <HHSelect label={requestSubTypeMapValue.label} initialSelectValue={requestDetails} onChange={(e_6): void => handleRequestDetailsChange(e_6, requestSubTypeMapValue)} size="small" error={requestDetailsError} layoutStyles={({
        width: '50%'
      } as LayoutStyles)} selectOptions={createSelectOptions(requestSubTypeMapValue.options)} helperText={requestDetailsError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} inputProps={{
        'data-testid': USER_FEEDBACK.TEST_IDS.REQUEST_DETAIL_SELECT
      }} />;
    }
    return null;
  };
  const getRequestSubtypeSelect = (requestType: RequestTypeNew): JSX.Element | null => {
    const requestTypeMapValue_1 = REQUEST_TYPE_MAP.get(requestType);
    if (requestTypeMapValue_1) {
      return <HHStack flexDirection={'row'} columnGap={3}>
          <HHSelect label={requestTypeMapValue_1.label} initialSelectValue={requestSubType} onChange={(e_7): void => handleRequestSubTypeChange(e_7, requestTypeMapValue_1)} size="small" error={requestSubTypeError} layoutStyles={({
          width: '50%'
        } as LayoutStyles)} selectOptions={createSelectOptions(requestTypeMapValue_1.options)} helperText={requestSubTypeError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} inputProps={{
          'data-testid': USER_FEEDBACK.TEST_IDS.REQUEST_SUB_TYPE_SELECT
        }} />
          {getRequestDetailsSelect((requestSubType as CombinedRequestSubTypeUnion))}
        </HHStack>;
    }
    return null;
  };
  const prefillDefaultSupportType = (role_0: CfInternalRequesterType): void => {
    let defaultSupportValue: RequestTypeNew | undefined = undefined;
    if (OS_ROLES.includes(role_0)) {
      defaultSupportValue = 'OS Requests';
    } else if (MANAGER_ROLES.includes(role_0)) {
      defaultSupportValue = 'Manager Requests';
    } else if (VIP_PROGRAM_ROLES.includes(role_0)) {
      defaultSupportValue = 'Trial Program (fka VIP) Requests';
    }
    if (defaultSupportValue) {
      handleSupportChange(({
        target: {
          value: defaultSupportValue
        }
      } as HHSelectChangeEvent));
    }
  };
  const handleRoleChange = (e_8: HHSelectChangeEvent): void => {
    const role_1 = (e_8.target.value as string);
    setRole(role_1);
    setRoleError(false);
    props.updateCustomFields((prevData_9: FreshdeskTicketCustomFields) => ({
      ...prevData_9,
      cf_internal_requester_type: role_1
    }));
    prefillDefaultSupportType((role_1 as CfInternalRequesterType));
  };
  return <>
      <HHDialogContent sx={{
      paddingX: 6,
      paddingBottom: 2
    }}>
        <HHStack rowGap={4}>
          <HHStack rowGap={4}>
            <HHTypography hhVariant="h4">
              {USER_FEEDBACK.DIALOG_TEXTS.ABOUT_YOU}
            </HHTypography>
            <HHStack direction={'row'} columnGap={3}>
              <HHSelect initialSelectValue={role} label={USER_FEEDBACK.DIALOG_TEXTS.ROLE} onChange={handleRoleChange} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_INTERNAL_REQUESTER_TYPE.map(option => ({
              value: option,
              label: option,
              displayName: option
            }))} size={'small'} error={roleError} layoutStyles={({
              flex: 1
            } as LayoutStyles)} helperText={roleError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} />
              <HHSelect initialSelectValue={request} label={USER_FEEDBACK.DIALOG_TEXTS.SUPPORT} onChange={handleSupportChange} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_REQUEST_TYPE_NEW.map(option_0 => ({
              value: option_0,
              label: option_0,
              displayName: option_0
            }))} size={'small'} error={requestError} layoutStyles={({
              flex: 1
            } as LayoutStyles)} helperText={requestError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} inputProps={{
              'data-testid': USER_FEEDBACK.TEST_IDS.REQUEST_TYPE_SELECT
            }} />
            </HHStack>
          </HHStack>
          <HHStack rowGap={4}>
            <HHTypography hhVariant="h4">
              {USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED_TITLE}
            </HHTypography>
            <HHSelect onChange={(e_9: HHSelectChangeEvent): void => {
            setMemberSelectionOption(String(e_9.target.value));
          }} id="member-impacted-select" initialSelectValue={memberSelectionOption} selectOptions={memberImpactedOptions} size="small" layoutStyles={({
            flex: 1
          } as LayoutStyles)} />
            {memberSelectionOption === USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED.SINGLE.toUpperCase() && <HHStack direction="row" columnGap={3} width={'100%'}>
                <HHTextField label={USER_FEEDBACK.USER_ID} onChange={singleUserIdChange} hhVariant="outlined" variant="standard" value={userId} layoutStyles={({
              flex: 1
            } as LayoutStyles)} />

                <HHSelect label={USER_FEEDBACK.PROGRAM} id={programSelectInputId} initialSelectValue={selectedProgram} onChange={(e_10: HHSelectChangeEvent): void => {
              const value_7 = e_10.target.value;
              setSelectedProgram(value_7.toString());
              props.updateCustomFields((prevData_10: FeedbackMessage) => ({
                ...prevData_10,
                cf_program: value_7
              }));
            }} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_PROGRAM.map(option_1 => ({
              value: option_1,
              label: option_1,
              displayName: option_1
            }))} layoutStyles={({
              flex: 1
            } as LayoutStyles)} />

                <HHSelect label={USER_FEEDBACK.INDICATION_LABEL} initialSelectValue={selectedIndication} id={indicationSelectInputId} onChange={(e_11: HHSelectChangeEvent): void => {
              const value_8 = e_11.target.value;
              setSelectedIndication(value_8.toString());
              props.updateCustomFields((prevData_11: FeedbackMessage) => ({
                ...prevData_11,
                cf_pathway: value_8
              }));
            }} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.CF_PATHWAY.map(option_2 => ({
              value: option_2,
              label: option_2,
              displayName: option_2
            }))} layoutStyles={({
              flex: 1
            } as LayoutStyles)} />
              </HHStack>}

            {memberSelectionOption === USER_FEEDBACK.DIALOG_TEXTS.MEMBER_IMPACTED.MULTIPLE.toUpperCase() && <HHTextField label={USER_FEEDBACK.MULTIPLE_USER_ID} hhVariant="outlined" variant="standard" onChange={handleMultipleUserIdsChange} placeholder={USER_FEEDBACK.DIALOG_TEXTS.MEMBER_ID_PLACEHOLDER} value={userIds} error={multipleUserIdsError} helperText={multipleUserIdsError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} layoutStyles={({
            flex: 1
          } as LayoutStyles)} />}
          </HHStack>
          <HHStack rowGap={4}>
            <HHTypography hhVariant="h4">
              {USER_FEEDBACK.DIALOG_TEXTS.TITLE_BUG_REQUEST_QUES}
            </HHTypography>
            <HHStack flexDirection={'row'} columnGap={2}>
              <HHChip clickable color={typeChip === USER_FEEDBACK.BUG_STRING ? 'secondary' : 'default'} hhVariant={'filled'} label={USER_FEEDBACK.BUG_STRING.charAt(0).toUpperCase() + USER_FEEDBACK.BUG_STRING.slice(1).toLocaleLowerCase()} onClick={(): void => {
              handleToggleBugFeatureChip(USER_FEEDBACK.BUG_STRING);
            }} layoutStyles={({
              flex: '0 auto'
            } as LayoutStyles)} />

              <HHChip clickable hhVariant={'filled'} color={typeChip === USER_FEEDBACK.REQUEST_STRING ? 'secondary' : 'default'} label={USER_FEEDBACK.REQUEST_STRING.charAt(0).toUpperCase() + USER_FEEDBACK.REQUEST_STRING.slice(1).toLocaleLowerCase()} onClick={(): void => {
              handleToggleBugFeatureChip(USER_FEEDBACK.REQUEST_STRING);
            }} layoutStyles={({
              flex: '0 auto'
            } as LayoutStyles)} />
            </HHStack>
            {getRequestSubtypeSelect(request)}
            <HHTextField hhVariant="outlined" label={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].TITLE_PLACEHOLDER} InputProps={{
            minRows: 1,
            className: 'title-input'
          }} placeholder={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].TITLE_PLACEHOLDER} size={'small'} inputProps={{
            id: 'search'
          }} defaultValue={props.freshdeskTicketDetails.subject} fullWidth onChange={(e_12): void => {
            handleTitleChange(e_12.target.value.trim());
          }} error={Boolean(titleHelperText)} helperText={titleHelperText} />

            <HHTextField hhVariant="outlined" label={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].DESCRIPTION_PLACEHOLDER} multiline fullWidth rows={4} placeholder={USER_FEEDBACK.DIALOG_TEXTS[(typeChip as FeedbackType)].DESCRIPTION_PLACEHOLDER} size={'medium'} defaultValue={props.freshdeskTicketDetails.description} onChange={(e_13): void => {
            setDescriptionError(false);
            props.updateTicketData((prevData_12: FreshdeskTicketDetails) => ({
              ...prevData_12,
              description: e_13.target.value.trim()
            }));
          }} error={descriptionError} helperText={descriptionError ? USER_FEEDBACK.ERROR_TEXT_VALUE : ''} />

            <HHStack direction={'row'} columnGap={3}>
              <HHDatePicker onChange={(date: Date | null): void => handleDateChange(date, setDateValue, setDateError, props.updateCustomFields)} value={dateValue ? dayjs(dateValue).toDate() : null} label={USER_FEEDBACK.DIALOG_TEXTS.BUG.DATE_OF_ISSUE} disableFuture renderInput={(params): JSX.Element => <HHTextField {...(params as HHTextFieldProps)} hhVariant="outlined" size="small" fullWidth error={dateError} value={dateValue} inputProps={{
              ...params.inputProps,
              id: 'search'
            }} helperText={dateError ? USER_FEEDBACK.DATE_ERROR : ''} layoutStyles={({
              flex: 1
            } as LayoutStyles)} />} PopperProps={{
              placement: 'bottom-start'
            }} />
              <HHSelect initialSelectValue={priorityValue} label={USER_FEEDBACK.PRIORITY_TITLE} id={USER_FEEDBACK.PRIORITY_ID} onChange={handlePriorityChange} selectOptions={FRESHDESK_FIELDS.CUSTOM_DROPDOWN_OPTION.PRIORITY_OPTIONAL.map(option_3 => ({
              value: option_3,
              label: option_3,
              displayName: option_3
            }))} MenuProps={{
              sx: {
                '& .MuiMenuItem-root': {
                  whiteSpace: 'normal',
                  lineHeight: '1.2'
                }
              }
            }} layoutStyles={({
              flex: 1
            } as LayoutStyles)} />
            </HHStack>
          </HHStack>
          <HHStack rowGap={2}>
            <HHTypography hhVariant="h4">
              {USER_FEEDBACK.DIALOG_TEXTS.ATTACHMENT_TEXT}
            </HHTypography>
            <HHBox>
              <input data-testid={USER_FEEDBACK.TEST_IDS.FILE_INPUT} type={'file'} accept={'image/*, video/*, .har, .doc, .docx, .pdf'} multiple ref={fileInputRef} style={{
              display: 'none'
            }} onClick={(e_14): void => e_14.stopPropagation()} onChange={props.handleFileUpload} />
              <HHButton hhVariant={'outlined'} onClick={handleButtonClick} startIcon={<UploadFileSharp />}>
                {USER_FEEDBACK.UPLOAD_FILES_TEXT}
              </HHButton>
            </HHBox>
            {props.fileUploadSizeError && <HHBox sx={layoutStyles.fileUploadErrorBox}>
                {props.fileUploadSizeError}
              </HHBox>}
            <HHStack direction={'row'} columnGap={1} sx={props.sxStyles.phiDisclaimerBox} alignItems={'center'}>
              <InfoOutlined sx={{
              color: 'rgba(103, 111, 109, 1)'
            }} />
              <HHTypography hhVariant="body1" color={'rgba(103, 111, 109, 1)'} align={'center'}>
                {USER_FEEDBACK.DISCLAIMER_TEXT}
              </HHTypography>
            </HHStack>
            {/* Uploaded files will show here */}
            {props.feedbackFiles && props.feedbackFiles.length > 0 && Array.from((props.feedbackFiles as File[])).map((file: File, index: number) => <HHStack key={file.name} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} sx={props.sxStyles.hhListItem}>
                    <HHStack flexDirection={'row'} columnGap={2} alignItems="center">
                      <DoneRounded sx={{
                color: 'rgba(0, 0, 0, 0.54)'
              }} />
                      <Typography variant="body1" sx={{
                flex: 1
              }}>
                        {file.name}
                      </Typography>
                    </HHStack>
                    <HHIconButton hhVariant="medium" onClick={(): void => props.handleDeleteItem(index)} layoutStyles={{
              padding: 0
            }}>
                      <DeleteOutlineRounded data-testid={USER_FEEDBACK.TEST_IDS.DELETE_BUTTON + index} sx={{
                color: 'rgba(0, 0, 0, 0.54)'
              }} />
                    </HHIconButton>
                  </HHStack>)}
          </HHStack>
        </HHStack>
      </HHDialogContent>
      <HHDialogActions sx={{
      paddingX: 6,
      paddingY: 0
    }}>
        <HHStack width={'100%'}>
          <HHDivider sx={props.sxStyles.divider} />
          <HHStack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} paddingY={6}>
            <HHTypography sx={{
            fontSize: '14px'
          }} hhVariant="variant-bypass" color={ctpTokens.palette.neutral[10]}>
              {' Review '}
              <button style={props.sxStyles.baseUrl} onClick={handleKnowledgeBaseUrlClick}>
                Knowledge Base URL
              </button>
              {' before submitting'}
            </HHTypography>
            <HHButton sx={props.sxStyles.submitButton} hhVariant="variant-bypass" onClick={handleSubmit} fullWidth={false} disabled={props.loader}>
              {USER_FEEDBACK.SUBMIT_FORM_BUTTON_TEXT}
            </HHButton>
          </HHStack>
        </HHStack>
      </HHDialogActions>
    </>;
};