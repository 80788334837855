import { ApolloError } from '@apollo/client';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { OsWorkflowTypes } from '../../../custom-types';
import { SseEvents } from '../constants/events';
import { OVERDUE_CHECK_TIME } from '../constants/gql-query-constants';
import { ON, SPLIT_TREATMENTS } from '../constants/splits';
import { useShellContext } from '../contexts/shell.context';
import { totalAllTypesCount, totalAllTypesCountLowPriority, totalUnreadCount, totalUnreadCountLowPriority } from '../jotai/atoms';
import { CountWorkflowsFiltersInputDto, useCountWorkflowsWithWorkflowTypesQuery, WorkflowCount } from '../types';
import { commonOsCompletedStates, commonOsExcludedStates, CommonOsState, OsDashboardFilters } from '../types/os';
import { OsWorkflowFilter } from '../types/workflow-filter';
import { getEndOfDay, getEndOfTomorrowInBusinessDays, getStartOfDay } from '../utils/get-formatted-date';
export type CountRecord = Record<OsDashboardFilters, number>;
export type UseGetCountOutcome = {
  counts: CountRecord;
  loading: boolean;
  error: ApolloError | undefined;
};
type IncompleteCount = Omit<CountRecord, 'Completed today'>;

// fix interface
const initialIncompleteCount: IncompleteCount = {
  [OsDashboardFilters.All]: 0,
  [OsDashboardFilters.UnreadMessage]: 0,
  [OsDashboardFilters.UnreadMessageNoResponse]: 0,
  [OsDashboardFilters.AlertReminder]: 0,
  [OsDashboardFilters.OutreachNeeded]: 0,
  [OsDashboardFilters.ReEngager]: 0,
  [OsDashboardFilters.Nuto]: 0,
  [OsDashboardFilters.Ruto]: 0
};
const getNewCount = (workflows: number | undefined): number => workflows || 0;
const getFilteredWorkflowsCount = (workflows: WorkflowCount[] | undefined, workflowType: OsWorkflowTypes, excludedStates: string[] = commonOsExcludedStates): number => {
  if (!workflows) {
    return 0;
  }
  return workflows.reduce((count, curr: WorkflowCount) => {
    if (curr.workflowType === workflowType && !excludedStates.includes((curr.state as CommonOsState))) {
      return count + curr.count;
    }
    return count;
  }, 0);
};
export const useGetOsCounts = (filterState: OsWorkflowFilter): UseGetCountOutcome => {
  const [incompleteCount, setIncompleteCount] = useState<IncompleteCount>(initialIncompleteCount);
  const [completeCount, setCompleteCount] = useState<number>(0);
  const {
    adminProfile,
    useSse
  } = useShellContext();
  const [allTypesCountTotal, setTotalUnreadCount] = useAtom(totalAllTypesCount);
  const [messageCountTotal, setTotalMessageCount] = useAtom(totalUnreadCount);
  const [, setUnreadLowPriority] = useAtom(totalUnreadCountLowPriority);
  const [, setAllTypesLowPriority] = useAtom(totalAllTypesCountLowPriority);
  const sseEvents = [SseEvents.WorkflowCreated, SseEvents.WorkflowTransitioned, SseEvents.TaskUpdated];
  const splitClient = useClient();
  const enableWorkflowStateFilteringTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.ENABLE_WORKFLOW_STATE_FILTERING, {
    adminUuid: adminProfile?.uuid || '*'
  }) === ON;
  const sseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, sseEvents, {
    replayLastEvent: true
  });
  const bulkMarkSseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, [SseEvents.BulkWorkflowTransitionCompleted, SseEvents.BulkWorkflowTransitionFailed], {
    replayLastEvent: true
  });
  const countWorkflowsFiltersInputDto: CountWorkflowsFiltersInputDto = {
    adminUuids: [adminProfile.uuid],
    ...filterState,
    useCoverage: true
  };
  const {
    data: incompleteData,
    loading: incompleteLoading,
    error: incompleteError,
    refetch: refetchIncomplete
  } = useCountWorkflowsWithWorkflowTypesQuery({
    variables: {
      countWorkflowsFilters: {
        ...countWorkflowsFiltersInputDto,
        dueTaskEndDate: getEndOfTomorrowInBusinessDays(),
        countByPriority: true,
        ...(enableWorkflowStateFilteringTreatment ? {
          excludeAllCompletedStates: true,
          excludeAllPassiveStates: true
        } : {
          excludeStates: commonOsExcludedStates,
          completedStates: commonOsCompletedStates
        })
      }
    },
    pollInterval: OVERDUE_CHECK_TIME,
    errorPolicy: 'all',
    returnPartialData: true,
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });
  const {
    data: completeData,
    loading: completeLoading,
    error: completeError,
    refetch: refetchComplete
  } = useCountWorkflowsWithWorkflowTypesQuery({
    variables: {
      countWorkflowsFilters: {
        ...countWorkflowsFiltersInputDto,
        completedAfterDate: getStartOfDay(),
        completedBeforeDate: getEndOfDay(),
        ...(enableWorkflowStateFilteringTreatment ? {
          includeOnlyCompletedStates: true
        } : {
          completedStates: commonOsCompletedStates
        })
      }
    },
    pollInterval: OVERDUE_CHECK_TIME,
    errorPolicy: 'all',
    returnPartialData: true,
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });
  const incompleteResults = incompleteData?.countWorkflowsWithWorkflowTypes?.results;
  const [unreadMessageLowPriorityCount_0] = useMemo(() => {
    let unreadMessageLowPriorityCount = 0;
    const subtotals = incompleteData?.countWorkflowsWithWorkflowTypes?.subtotals || [];
    const unreadMessageSubtotal = subtotals.find(item => item.workflowType === OsWorkflowTypes.UnreadMessages);
    if (unreadMessageSubtotal) {
      unreadMessageLowPriorityCount = unreadMessageSubtotal.countByPriority?.low || 0;
      setUnreadLowPriority(unreadMessageLowPriorityCount);
    }
    return [unreadMessageLowPriorityCount];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incompleteData?.countWorkflowsWithWorkflowTypes]);
  useEffect(() => {
    setAllTypesLowPriority(unreadMessageLowPriorityCount_0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessageLowPriorityCount_0]);
  useEffect(() => {
    const refetchCounts = async (): Promise<void> => {
      if (sseData || bulkMarkSseData) {
        await Promise.all([refetchIncomplete(), refetchComplete()]);
      }
    };
    refetchCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseData, bulkMarkSseData]);
  useEffect(() => {
    // Deriving completedStates from what existed for OS. Excluding all the 'completed' workflows from incompleteResults.
    const allWorkflows = incompleteResults?.reduce((acc, curr: WorkflowCount) => {
      if (!commonOsCompletedStates.includes((curr.state as CommonOsState))) {
        return acc + curr.count;
      }
      return acc;
    }, 0);
    const unreadMessageWorkflows = getFilteredWorkflowsCount(incompleteResults, OsWorkflowTypes.UnreadMessages);
    const memberAlertReminderWorkflows = getFilteredWorkflowsCount(incompleteResults, OsWorkflowTypes.MemberAlertReminder);
    const outreachNeededWorkflows = getFilteredWorkflowsCount(incompleteResults, OsWorkflowTypes.OutreachNeeded);
    const reEngagerWorkflows = getFilteredWorkflowsCount(incompleteResults, OsWorkflowTypes.ReEngager);
    const nutoWorkflows = getFilteredWorkflowsCount(incompleteResults, OsWorkflowTypes.Nuto);
    const rutoWorkflows = getFilteredWorkflowsCount(incompleteResults, OsWorkflowTypes.Ruto);
    setTotalMessageCount(unreadMessageWorkflows);
    setTotalUnreadCount(unreadMessageWorkflows);
    const newCounts: IncompleteCount = {
      [OsDashboardFilters.All]: getNewCount(allWorkflows),
      [OsDashboardFilters.UnreadMessage]: getNewCount(unreadMessageWorkflows),
      [OsDashboardFilters.Messages]: messageCountTotal,
      [OsDashboardFilters.AllTypes]: allTypesCountTotal,
      [OsDashboardFilters.AlertReminder]: getNewCount(memberAlertReminderWorkflows),
      [OsDashboardFilters.OutreachNeeded]: getNewCount(outreachNeededWorkflows),
      [OsDashboardFilters.ReEngager]: getNewCount(reEngagerWorkflows),
      [OsDashboardFilters.Nuto]: getNewCount(nutoWorkflows),
      [OsDashboardFilters.Ruto]: getNewCount(rutoWorkflows),
      [OsDashboardFilters.UnreadMessageNoResponse]: unreadMessageLowPriorityCount_0
    };
    setIncompleteCount(newCounts);
  }, [allTypesCountTotal, incompleteResults, unreadMessageLowPriorityCount_0, messageCountTotal, setTotalMessageCount, setTotalUnreadCount]);
  const completedCount = completeData?.countWorkflowsWithWorkflowTypes.total;
  useEffect(() => {
    if (completedCount !== undefined && completedCount !== null) {
      setCompleteCount(completedCount);
    }
  }, [completedCount]);
  const counts: Record<OsDashboardFilters, number> = {
    ...incompleteCount,
    [OsDashboardFilters.Completed]: completeCount
  };
  return {
    counts,
    loading: incompleteLoading || completeLoading,
    error: incompleteError || completeError
  };
};