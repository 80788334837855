/* eslint-disable react-hooks/exhaustive-deps */
import { HHAutocomplete } from '@hinge-health/react-component-library';
import { OptionType } from '@hinge-health/react-component-library/dist/components/atoms/hh-autocomplete';
import { useAtom, useSetAtom } from 'jotai';
import { useCallback, useContext, useEffect, useState } from 'react';
import { PtContext } from '../../../../app';
import { useGenericMixpanelTrack } from '../../../../hooks/use-generic-mixpanel';
import { NewAdminSelected } from '../../../../lib/mixpanel/events/workflows';
import { MIXPANEL_EVENTS } from '../../../../lib/mixpanel/mixpanel-event-names';
import { PtPooledDashboardChangeProperties } from '../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { trackEvent } from '../../../../utils/mixpanel';
import { CurrentMemberSessionIdContext } from '../../../member-360/components/profile/session-manager';
import { PT_LIST_NO_RECENT_SEARCHES, PT_LIST_NO_RESULTS } from '../../constants/strings/pt-layout';
import { useShellContext } from '../../contexts/shell.context';
import { useLastSearchedPt } from '../../hooks/use-last-searched-pt';
import { changePT, ptPooledNewDashboardID, selectAllPTWorkflowAtom, selectedPtDashboardFilters } from '../../jotai/atoms';
import { AdminProps, useGetAdminsList } from '../../queries/get-pt-list.graphql';
export const PTList = (adminProfile: AdminProps): JSX.Element => {
  const {
    adminProfile: adminData
  } = useShellContext();
  const [GetPTList, {
    data: ptListData
  }] = useGetAdminsList();
  const [ptList, setPtList] = useState<[AdminProps]>([adminProfile]);
  const [, setAllPTWorkflowFlag] = useAtom(selectAllPTWorkflowAtom);
  const [, setIsPTChanged] = useAtom(changePT);
  const [, setPtPooledAdminId] = useAtom(ptPooledNewDashboardID);
  const setSelectedDashboardFilter = useSetAtom(selectedPtDashboardFilters);
  const [, setPtSelectionChangedContext] = useContext(PtContext);
  const {
    currentMemberSessionId
  } = useContext(CurrentMemberSessionIdContext);
  const [lastSearchedPTs, appendLastSearchedPt] = useLastSearchedPt(adminProfile.uuid);
  const [selectedInputValue, setSelectedInputValue] = useState<OptionType>({
    label: adminProfile.firstName + ' ' + adminProfile.lastName,
    value: adminProfile.uuid
  });
  const [lastSearchedOptions, setLastSearchedOptions] = useState<OptionType[]>([]);
  const [showLastSearchedOptions, setShowLastSearchedOptions] = useState<boolean>(true);
  const [showPTNameInInput, setShowPTNameInInput] = useState<boolean>(true);
  const mixPanelTrack = useGenericMixpanelTrack();
  const getPTList = async (roles: Array<string>): Promise<boolean> => {
    await GetPTList({
      variables: {
        roles,
        itemsPerPage: 1000
      }
    });
    return true;
  };
  useEffect(() => {
    getPTList(['physical_therapist']);
  }, []);
  useEffect(() => {
    if (ptListData && ptListData.findAdmins) {
      if (lastSearchedPTs) {
        const lastSearchedPTsDetails = lastSearchedPTs.lastSearchedPTs.map(ptAdminUuid => {
          const foundAdmin = (ptListData.findAdmins || []).find(admin => admin.uuid === ptAdminUuid);
          const deafultAdmin: AdminProps = {
            uuid: ptAdminUuid,
            firstName: '',
            lastName: '',
            email: ''
          };
          return foundAdmin || deafultAdmin;
        }).filter(admin_0 => admin_0.firstName && admin_0.lastName);
        setLastSearchedOptions(formatOptions(lastSearchedPTsDetails));
      }
      setPtList(ptListData.findAdmins);
    }
  }, [ptListData, lastSearchedPTs]);
  const formatOptions = (adminDetails: AdminProps[]): Array<OptionType> => adminDetails.map(admin_1 => ({
    label: admin_1.firstName + ' ' + admin_1.lastName,
    value: admin_1.uuid
  }));
  const handleChangePT = useCallback((data: {
    label: string;
    value: string;
  }): void => {
    trackEvent(new NewAdminSelected({
      adminCtx: adminData,
      shadowedAdminUUID: data.value,
      sessionId: currentMemberSessionId || ''
    }));
    (document.activeElement as HTMLElement)?.blur();
    setSelectedInputValue(data);
    const adminDetails_0 = (data as {
      label: string;
      value: string;
    });
    appendLastSearchedPt(adminDetails_0.value);
    mixPanelTrack<PtPooledDashboardChangeProperties>(MIXPANEL_EVENTS.PT_POOLED_DASHBOARD_CHANGED, {
      ptPooledAdminUuid: adminDetails_0.value,
      ptPooledAdminName: adminDetails_0.label
    });
    setPtPooledAdminId(adminDetails_0.value);
    if (setPtSelectionChangedContext) setPtSelectionChangedContext(adminDetails_0.value);
    setSelectedDashboardFilter(prevFilter => ({
      ...prevFilter,
      filterPageNumber: 0
    }));
    window.dispatchEvent(new CustomEvent('onPtPooledDashboardChange', {
      detail: adminDetails_0.value
    }));
    setAllPTWorkflowFlag(false);
    setIsPTChanged(true);
  }, [lastSearchedPTs]);
  useEffect(() => {
    if (setPtSelectionChangedContext) setPtSelectionChangedContext('default');
  }, []);
  return <HHAutocomplete fullWidth disableClearable value={showPTNameInInput ? selectedInputValue : undefined} inputProps={{
    hhVariant: 'variant-bypass',
    variant: 'standard',
    label: '',
    onFocus: (): void => setShowPTNameInInput(false),
    onBlur: (): void => setShowPTNameInInput(true)
  }} sx={{
    width: '98%'
  }} options={showLastSearchedOptions ? lastSearchedOptions : formatOptions(ptList)} size="small" autoHighlight onChange={(_event, data_0): void => {
    handleChangePT((data_0 as {
      label: string;
      value: string;
    }));
  }} onInputChange={(_event_0, data_1): void => {
    const searchedText = data_1 || '';
    const showLastSearchedOptions_0 = searchedText.length === 0;
    setShowLastSearchedOptions(showLastSearchedOptions_0);
  }} noOptionsText={showLastSearchedOptions ? PT_LIST_NO_RECENT_SEARCHES : PT_LIST_NO_RESULTS} />;
};
export type { AdminProps };