import { HHBox, HHGrid, HHStack } from '@hinge-health/react-component-library';
import TopicIcon from '@mui/icons-material/Topic';
import { useContext, useMemo } from 'react';
import { ReactComponent as FreshDeskIcon } from '../components/assets/images/freshdeskfigma.svg';
import { ReactComponent as HelpScoutIcon } from '../components/assets/images/helpscoutfigma.svg';
import { QUICK_LINKS } from '../constants/strings/coach';
import { NAV_SPLIT_TREATMENTS } from '../constants/strings/split';
import { FeatureFlagContext } from '../contexts/feature-flag-context';
import { generateKey } from '../utils/app-utils';
import { QuickLinkIcon, QuickLinkIconProps } from './quick-link-icon';
const styles: Record<string, React.CSSProperties> = {
  boxStyle: {
    width: 'auto',
    height: 'auto',
    borderRadius: 1,
    padding: '5px'
  }
};
export const getQuickLinks = (qaReviewFeatureFlag: boolean): Array<Omit<QuickLinkIconProps, 'setShowQuickLinksPopover'>> => [{
  text: QUICK_LINKS.HELPSCOUT,
  icon: <HelpScoutIcon />,
  url: 'https://secure.helpscout.net/members/login/'
}, {
  text: QUICK_LINKS.FRESHDESK,
  icon: <FreshDeskIcon />,
  url: 'https://hingehealth.freshdesk.com/support/tickets/new'
}, ...(qaReviewFeatureFlag ? [{
  text: QUICK_LINKS.QA_REVIEW,
  icon: <TopicIcon style={{
    width: '44px',
    height: '44px'
  }} />,
  url: '/qa-review'
}] : [])];
export const QuickLinks = ({
  setShowQuickLinksPopover
}: {
  setShowQuickLinksPopover: (isOpen: boolean) => void;
}): JSX.Element => {
  const {
    featureFlags
  } = useContext(FeatureFlagContext);
  const quickLinks = useMemo(() => {
    const qaReviewFeatureFlag = Boolean(featureFlags && featureFlags[NAV_SPLIT_TREATMENTS.QA_REVIEW_RECORDING]);
    return getQuickLinks(qaReviewFeatureFlag);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [featureFlags]);
  return <HHBox data-testid="quick-links-container" sx={styles.boxStyle}>
      <HHStack flexDirection={'row'} spacing={'space-around'}>
        {quickLinks.map((quickLink, id) => <HHGrid item xs={6} key={generateKey(quickLink.text, id)}>
            <HHBox sx={{
          padding: 2
        }}>
              <QuickLinkIcon url={quickLink.url} icon={quickLink.icon} text={quickLink.text} setShowQuickLinksPopover={setShowQuickLinksPopover} />
            </HHBox>
          </HHGrid>)}
      </HHStack>
    </HHBox>;
};