import { HHBox, HHButton, HHTypography } from '@hinge-health/react-component-library';
import { useContext } from 'react';
import { QUICK_LINKS } from '../constants/strings/coach';
import { SESSION_ID, TIME_SPENT_ON_MEMBER } from '../constants/strings/member';
import { useGenericMixpanelTrack } from '../hooks/use-generic-mixpanel';
import { MemberRecordExitProps, MixpanelPropertiesContext } from '../lib/mixpanel/types/generic-mixpanel-event-types';
import { CurrentMemberSessionIdContext } from '../modules/member-360/components/profile/session-manager';
import { getLocalStorageItem } from '../utils/local-storage-utils';
export interface QuickLinkIconProps {
  url: string;
  icon: JSX.Element;
  text: string;
  setShowQuickLinksPopover: (isOpen: boolean) => void;
}
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    '&:hover': {
      background: 'white'
    },
    '&.MuiButton-root:hover': {
      backgroundColor: 'white'
    }
  },
  typography: {
    fontSize: 12,
    cursor: 'pointer'
  }
};
export const QuickLinkIcon = (props: QuickLinkIconProps): JSX.Element => {
  const {
    url,
    icon,
    text,
    setShowQuickLinksPopover
  } = props;
  const genericMixpanelEvents = useGenericMixpanelTrack([MixpanelPropertiesContext.MemberContext]);
  const {
    endSession
  } = useContext(CurrentMemberSessionIdContext);
  const handleClick = (): void => {
    window.open(url, '_blank', 'noopener');
    const sessionId = getLocalStorageItem(SESSION_ID) || null;
    endSession();
    const timeSpent = getLocalStorageItem(TIME_SPENT_ON_MEMBER) || null;
    const iconEventMap = {
      [QUICK_LINKS.FRESHDESK]: 'carehub_home.left_navigation_pane.freshdesk_icon_clicked',
      [QUICK_LINKS.HELPSCOUT]: 'carehub_home.left_navigation_pane.helpscout_icon_clicked'
    };
    const eventName = iconEventMap[text];
    if (eventName) {
      genericMixpanelEvents<MemberRecordExitProps>(eventName, {
        sessionId,
        timeSpent
      });
    }
    setShowQuickLinksPopover(false);
  };
  return <HHBox sx={styles.container} onClick={handleClick} data-testid="quick-link-icon">
      <HHButton hhVariant="variant-bypass" sx={styles.button}>
        {icon}
      </HHButton>
      <HHTypography hhVariant="variant-bypass" sx={styles.typography}>
        {text}
      </HHTypography>
    </HHBox>;
};