import { HHTypography } from '@hinge-health/react-component-library';
import { startCase } from 'lodash';
import { OsWorkflowTypes, PtWorkflowTypes, UnreadMessagesWorkflowTypes } from '../../../../../../custom-types';
import { CARE_TEAM, UNREAD_MESSAGE } from '../../../../../member-360/modules/panel-layouts/constants/panel';
import { COACH_WORKFLOW_TYPES } from '../../../../constants/strings/coach-workflow-types';
import { OS_WORKFLOW_TYPES } from '../../../../constants/strings/os-workflow-types';
import { PT_WORKFLOW_TYPES } from '../../../../constants/strings/pt-workflow-types';
import { WorkflowPayload, WorkflowTypeCustomFieldsActionPlansFragment } from '../../../../types';
import { CoachWorkflowTypes } from '../../../../types/coach';
import { getPainScoreChange, isSignificantPainChange } from '../../../../utils/get-workflow-subtitle';
import { WorkflowTypeSecondaryText } from './workflow-type-secondary-text';
interface WorkflowTypeCellProps {
  workflow: WorkflowTypeCustomFieldsActionPlansFragment;
}
export const TitleCell = ({
  workflow
}: WorkflowTypeCellProps): JSX.Element => {
  const getTitle = (): JSX.Element => {
    const {
      type,
      customFields
    } = workflow;
    const isCareTeamMessage = customFields?.type === UnreadMessagesWorkflowTypes.CareTeamMessage;
    const isInAppMessage = customFields?.type === UnreadMessagesWorkflowTypes.InAppMessage && workflow?.type === PtWorkflowTypes.ModularIamUnreadMessages;
    const renderTitle = (): string => {
      switch (true) {
        case type === PtWorkflowTypes.PtMemberAlertReminder:
          return startCase(customFields?.memberAlert?.category);
        case isCareTeamMessage:
          return CARE_TEAM;
        case isInAppMessage:
          return UNREAD_MESSAGE;
        default:
          return COACH_WORKFLOW_TYPES[(type as CoachWorkflowTypes)] || PT_WORKFLOW_TYPES[(type as PtWorkflowTypes)] || OS_WORKFLOW_TYPES[(type as OsWorkflowTypes)];
      }
    };
    return <HHTypography hhVariant="body">{renderTitle()}</HHTypography>;
  };
  return <>
      {getTitle()}
      {workflow.type === PtWorkflowTypes.ModularIamUnreadMessages && isSignificantPainChange((workflow as WorkflowPayload)) && getPainScoreChange(workflow)}
      <WorkflowTypeSecondaryText workflow={workflow} />
    </>;
};