import { c as _c } from "react-compiler-runtime";
import { ApolloError } from '@apollo/client';
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { SecurityContext } from '@hinge-health/react-component-library/dist/components/auth/hinge-health-security-context';
import * as Sentry from '@sentry/react';
import { useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ROUTES } from '../constants';
import { NAV_SPLIT_TREATMENTS } from '../constants/strings/split';
import { FeatureFlagContext, FeatureFlags } from '../contexts/feature-flag-context';
import { OktaRole } from '../types/okta-roles';
import { useCurrentStoredAdminHook } from './use-current-stored-admin';
type NavigateToDashboardReturn = {
  loading: boolean;
  error: string | ApolloError | undefined;
  navigateToDashboard: () => void;
};
const determineRoute = (featureFlags: FeatureFlags | null, hingeHealthAuthState: SecurityContext['hingeHealthAuthState']): string => {
  const roles = hingeHealthAuthState?.accessToken?.claims?.roles;
  const {
    email = '',
    sub: userId = ''
  } = hingeHealthAuthState?.accessToken?.claims || {};
  if (!Array.isArray(roles)) {
    Sentry.captureMessage('Unexpected roles format in authentication token, expected array but got ' + typeof roles, {
      level: 'error',
      extra: {
        roleType: typeof roles,
        roleValue: roles,
        email,
        userId,
        accessTokenClaims: hingeHealthAuthState?.accessToken?.claims
      }
    });
    return ROUTES.NOT_FOUND;
  }
  if (featureFlags?.[NAV_SPLIT_TREATMENTS.MPE_DASHBOARD]) {
    return ROUTES.HOME;
  }
  if (roles.includes(OktaRole.OrthopedicSpecialist)) {
    return ROUTES.ORTHO_SPEC;
  } else if (roles.includes(OktaRole.PhysicalTherapist)) {
    return ROUTES.PT;
  } else if (roles.includes(OktaRole.OnboardingSpecialist)) {
    return ROUTES.OS;
  } else if (roles.includes(OktaRole.Coach)) {
    return ROUTES.COACH;
  }
  return ROUTES.NOT_FOUND;
};

/**
 * Handles navigation to role-specific dashboard routes based on user's Okta roles and feature flags.
 * It considers feature flags and authentication state to determine the appropriate destination.
 *
 * @param navigateOnRender - If true, automatically navigates when the component renders. Defaults to true.
 * @returns {NavigateToDashboardReturn} Object containing:
 *  - navigateToDashboard: Function to trigger navigation to the appropriate dashboard route. Useful when you don't want to navigate on render.
 *  - loading: Boolean indicating if auth/feature flags are loading
 *  - error: Any errors from admin hook or feature flags
 */
export const useNavigateToDashboard = t0 => {
  const $ = _c(15);
  const navigateOnRender = t0 === undefined ? true : t0;
  const navigate = useNavigate();
  const location = useLocation();
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const {
    loading: adminLoading,
    adminError
  } = useCurrentStoredAdminHook();
  const {
    loading: featureFlagLoading,
    featureFlags,
    error: featureFlagError
  } = useContext(FeatureFlagContext);
  const loading = adminLoading || featureFlagLoading;
  const error = adminError ?? featureFlagError;
  let t1;
  if ($[0] !== loading || $[1] !== error || $[2] !== featureFlags || $[3] !== hingeHealthAuthState || $[4] !== location.pathname || $[5] !== navigate) {
    t1 = () => {
      if (loading || error) {
        return;
      }
      const navigateTo = determineRoute(featureFlags, hingeHealthAuthState);
      if (navigateTo !== location.pathname) {
        navigate(navigateTo, {});
      }
    };
    $[0] = loading;
    $[1] = error;
    $[2] = featureFlags;
    $[3] = hingeHealthAuthState;
    $[4] = location.pathname;
    $[5] = navigate;
    $[6] = t1;
  } else {
    t1 = $[6];
  }
  const navigateToDashboard = t1;
  let t2;
  let t3;
  if ($[7] !== navigateOnRender || $[8] !== navigateToDashboard) {
    t2 = () => {
      if (navigateOnRender) {
        navigateToDashboard();
      }
    };
    t3 = [navigateOnRender, navigateToDashboard];
    $[7] = navigateOnRender;
    $[8] = navigateToDashboard;
    $[9] = t2;
    $[10] = t3;
  } else {
    t2 = $[9];
    t3 = $[10];
  }
  useEffect(t2, t3);
  let t4;
  if ($[11] !== navigateToDashboard || $[12] !== loading || $[13] !== error) {
    t4 = {
      navigateToDashboard,
      loading,
      error
    };
    $[11] = navigateToDashboard;
    $[12] = loading;
    $[13] = error;
    $[14] = t4;
  } else {
    t4 = $[14];
  }
  return t4;
};