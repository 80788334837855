import { ApolloError } from '@apollo/client';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { CoachSseData, commonCoachCompletedStates } from '../../../custom-types';
import { getWorkflowSseData } from '../../../utils/coach-sse';
import { SseEvents } from '../constants/events';
import { OVERDUE_CHECK_TIME } from '../constants/gql-query-constants';
import { ON, SPLIT_TREATMENTS } from '../constants/splits';
import { useShellContext } from '../contexts/shell.context';
import { CountWorkflowsFiltersInputDto, useCountWorkflowsWithWorkflowTypesQuery, WorkflowCount } from '../types';
import { CoachDashboardFilters, CoachVideoVisits, CoachWorkflowTypes, CommonCoachState, commonCompletedStates, commonExcludedStates, CountWorkflowsWithWorkflowTypesQueryResults } from '../types/coach';
import { VideoVisitsState } from '../types/video-visit';
import { CoachWorkflowFilter } from '../types/workflow-filter';
import { getEndOfDay, getEndOfTomorrowInBusinessDays, getStartOfDay } from '../utils/get-formatted-date';
import { WorkflowTransitionedSseData } from '../../../contexts/sse.context';
import { isArchiveState, isUnaccountedTransition } from '../../../utils/workflow-counts';
import { incompleteResultsData } from '../jotai/atoms';
import { commonPtCompletedStates } from '../types/pt';
import { getSeparatedWorkflowCounts, WorkflowResult } from '../utils/get-separated-workflow-counts';
export type CountRecord = Record<CoachDashboardFilters, number>;
export type UseGetCountOutcome = {
  counts: CountRecord;
  loading: boolean;
  error: ApolloError | undefined;
};
type IncompleteCount = Omit<CountRecord, 'Completed'>;
export const completedStates: CommonCoachState[] = [CommonCoachState.CoachComplete, CommonCoachState.CoachDismiss, CommonCoachState.CoachClosed, CommonCoachState.CoachOutreachSentToMember, CommonCoachState.CoachDismissed, CommonCoachState.CoachUserTransitionedToNewProgram, CommonCoachState.CoachVideoVisitCancelVideoVisit];
const initialIncompleteCount: IncompleteCount = {
  [CoachDashboardFilters.All]: 0,
  [CoachDashboardFilters.UnreadMessages]: 0,
  [CoachDashboardFilters.MemberHealthLog]: 0,
  [CoachDashboardFilters.MemberHealthLogNoResponse]: 0,
  [CoachDashboardFilters.UnreadMessagesNoResponse]: 0,
  [CoachDashboardFilters.ActionPlanCheckIn]: 0,
  [CoachDashboardFilters.FirstWeekCheckIn]: 0,
  [CoachDashboardFilters.CoachCalls]: 0,
  [CoachDashboardFilters.CoachCallsPostCallTasks]: 0,
  [CoachDashboardFilters.CoachCallsUpcomingCoachCalls]: 0,
  [CoachDashboardFilters.ReEngager]: 0,
  [CoachDashboardFilters.CoachEscalation]: 0,
  [CoachDashboardFilters.WeeklyEnsoReview]: 0,
  [CoachDashboardFilters.OutreachNeeded]: 0,
  [CoachDashboardFilters.BalanceAssessment]: 0,
  [CoachDashboardFilters.AlertReminder]: 0,
  [CoachDashboardFilters.MaternityMilestones]: 0,
  [CoachDashboardFilters.PregnancyStatusChange]: 0,
  [CoachDashboardFilters.ReviewAndRecalibrate]: 0,
  [CoachDashboardFilters.PainUptick]: 0,
  [CoachDashboardFilters.FirstWeekReview]: 0,
  [CoachDashboardFilters.CoachWeeklyGoalExpiry]: 0,
  [CoachDashboardFilters.CoachMemberUpdatedWeeklyGoal]: 0,
  [CoachDashboardFilters.CoachVideoVisit]: 0,
  [CoachDashboardFilters.CoachVideoVisitUpcoming]: 0,
  [CoachDashboardFilters.CoachVideoVisitIncomplete]: 0
};
const getNewCount = (workflows: number | undefined): number => workflows || 0;
const defaultExcludedStates = [CommonCoachState.CoachComplete, CommonCoachState.CoachDismiss, CommonCoachState.CoachAwaitingPlan, CommonCoachState.CoachAwaitingNew, CommonCoachState.AwaitingMessage, CommonCoachState.AwaitingTag];
const getFilteredWorkflowsCount = (workflows: WorkflowCount[] | undefined, workflowType: CoachWorkflowTypes, excludedStates: string[] = defaultExcludedStates): number => {
  if (!workflows) {
    return 0;
  }
  return workflows.reduce((count, curr: WorkflowCount) => {
    if (curr.workflowType === workflowType && !excludedStates.includes((curr.state as CommonCoachState))) {
      return count + curr.count;
    }
    return count;
  }, 0);
};
export const useGetCoachCounts = (filterState: CoachWorkflowFilter): UseGetCountOutcome => {
  const [incompleteCount, setIncompleteCount] = useState<IncompleteCount>(initialIncompleteCount);
  const splitClient = useClient();
  const [completeCount, setCompleteCount] = useState<number>(0);
  const [incompleteResults, setIncompleteResults] = useAtom(incompleteResultsData);
  const [lowCountHLN, seLowCountHLN] = useState<number>(0);
  const [countLowPriorityUnreadMessages, setCountLowPriorityUnreadMessages] = useState<number>(0);
  const {
    adminProfile,
    useSse
  } = useShellContext();
  const enableWorkflowStateFilteringTreatment = splitClient?.getTreatment(SPLIT_TREATMENTS.ENABLE_WORKFLOW_STATE_FILTERING, {
    adminUuid: adminProfile?.uuid || '*'
  }) === ON;
  const sseEvents = [SseEvents.WorkflowCreated, SseEvents.WorkflowTransitioned, SseEvents.TaskUpdated];
  const sseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, sseEvents, {
    replayLastEvent: true
  });
  const bulkSseEvents: SseEvents[] = [SseEvents.BulkWorkflowTransitionCompleted, SseEvents.BulkWorkflowTransitionFailed];
  const bulkMarkSseData = useSse(`${process.env.REACT_APP_BFF_URL}/user-workflows/subscribe`, bulkSseEvents, {
    replayLastEvent: true
  });
  const countWorkflowsFiltersInputDto: CountWorkflowsFiltersInputDto = {
    adminUuids: [adminProfile.uuid],
    ...filterState,
    useCoverage: true
  };
  const {
    data: incompleteData,
    loading: incompleteLoading,
    error: incompleteError,
    refetch: refetchIncomplete
  } = useCountWorkflowsWithWorkflowTypesQuery({
    variables: {
      countWorkflowsFilters: {
        ...countWorkflowsFiltersInputDto,
        dueTaskEndDate: getEndOfTomorrowInBusinessDays(),
        countByPriority: true,
        ...(enableWorkflowStateFilteringTreatment ? {
          excludeAllCompletedStates: true,
          excludeAllPassiveStates: true
        } : {
          excludeStates: commonExcludedStates,
          completedStates
        })
      }
    },
    pollInterval: OVERDUE_CHECK_TIME,
    errorPolicy: 'all',
    returnPartialData: true,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  const {
    data: completeData,
    loading: completeLoading,
    error: completeError,
    refetch: refetchComplete
  } = useCountWorkflowsWithWorkflowTypesQuery({
    variables: {
      countWorkflowsFilters: {
        ...countWorkflowsFiltersInputDto,
        completedAfterDate: getStartOfDay(),
        completedBeforeDate: getEndOfDay(),
        ...(enableWorkflowStateFilteringTreatment ? {
          includeOnlyCompletedStates: true
        } : {
          completedStates
        })
      }
    },
    pollInterval: OVERDUE_CHECK_TIME,
    errorPolicy: 'all',
    returnPartialData: true,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });
  useEffect(() => {
    const refetchCounts = async (): Promise<void> => {
      if (bulkMarkSseData) {
        await Promise.all([refetchIncomplete(), refetchComplete()]);
      }
    };
    refetchCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState?.workflowTypes, bulkMarkSseData]);
  useEffect(() => {
    setIncompleteResults(incompleteData?.countWorkflowsWithWorkflowTypes?.results);
    const lowCountHLNTotal = incompleteData?.countWorkflowsWithWorkflowTypes?.subtotals?.filter(item => item.workflowType === CoachWorkflowTypes.MemberHealthLog);
    if (lowCountHLNTotal) {
      seLowCountHLN(lowCountHLNTotal[0]?.countByPriority?.low ?? 0);
    }
    const countLowPriorityUnreadMessagesTotal = incompleteData?.countWorkflowsWithWorkflowTypes?.subtotals?.filter(item_0 => item_0.workflowType === CoachWorkflowTypes.UnreadMessages);
    if (countLowPriorityUnreadMessagesTotal) {
      setCountLowPriorityUnreadMessages(countLowPriorityUnreadMessagesTotal[0]?.countByPriority?.low ?? 0);
    }
  }, [incompleteData, setIncompleteResults]);
  useEffect(() => {
    // function to get the new count values and set in the state
    const updateIncompleteCounts = ({
      workflow
    }: {
      workflow: CoachSseData;
    }): void => {
      // Check if incompleteResults is present and the check if the
      // stateName is in the list provided to check the workflow has
      // moved to final state eg: completed, dismiss
      if (incompleteResults && [...commonCoachCompletedStates, ...commonPtCompletedStates,
      // Archive is a passive state, hence should not contribute to incomplete workflows nor complete workflows.
      // When member alert reminder workflow moves from open to archive, it moves from a considered state to this passive state
      // Hence, decrement the incomplete count
      // At the same time, archive is not a complete state hence complete count should not increment.
      CommonCoachState.CoachArchive].includes(workflow.stateName) && workflow.type === SseEvents.WorkflowTransitioned &&
      // We fetch workflows of members the logged in admin covers for.
      // Logged in admin can resolve workflows of other members (PT pooled dashboard, not under their cov) and receive SSE for same.
      // As long as the logged in admin is part of the care team, we update the count.
      ((workflow as unknown) as WorkflowTransitionedSseData)?.careTeamUuids?.includes(adminProfile?.uuid)) {
        if (!isUnaccountedTransition(((workflow as unknown) as WorkflowTransitionedSseData))) {
          // This is used to update one of the information object
          // There might be multiple result for workflowType with different
          // state so we are gonna reduce the count from one result
          let found = false;
          const results: CountWorkflowsWithWorkflowTypesQueryResults[] = incompleteResults.map(item_1 => {
            // Matching the workflowType
            if (workflow.serviceName === item_1.workflowType) {
              // Checking the state and then increment the count
              // Once the workflow is moved to complete state then
              // increase the count
              if (workflow.stateName === item_1.state) {
                return {
                  ...item_1,
                  count: item_1.count + 1
                };
              } else if (!found && item_1.count) {
                // If not found and then a valid count then from
                // here make found as true and reduce the count also
                found = true;
                return {
                  ...item_1,
                  count: item_1.count - 1
                };
              }
            }
            return item_1;
          });
          setIncompleteResults(results);
        }
        setCompleteCount(prevCount => {
          if (isUnaccountedTransition(((workflow as unknown) as WorkflowTransitionedSseData))) {
            return prevCount - 1;
          }
          if (isArchiveState(workflow)) {
            return prevCount;
          }
          return prevCount + 1;
        });
      } else if (workflow.type === SseEvents.WorkflowCreated && incompleteResults) {
        // This is to updates the counts when new workflow is created
        const results_0: CountWorkflowsWithWorkflowTypesQueryResults[] = incompleteResults.map(item_2 => {
          // Condition to check workflowType and stateName
          if (workflow.serviceName === item_2.workflowType && workflow.stateName === item_2.state) {
            return {
              ...item_2,
              count: item_2.count + 1
            };
          }
          return item_2;
        });
        // Once the counts are updated, then updating in state
        setIncompleteResults(results_0);
      }
    };
    if (sseData) {
      const workflow_0 = getWorkflowSseData(sseData);
      updateIncompleteCounts({
        workflow: workflow_0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sseData]);
  useEffect(() => {
    const allWorkflows = incompleteResults?.reduce((acc, curr: WorkflowCount) => {
      if (curr.state !== CommonCoachState.CoachComplete && curr.state !== CommonCoachState.CoachAwaitingNew && curr.state !== CommonCoachState.CoachAwaitingPlan && curr.state !== CommonCoachState.CoachDismiss) {
        return acc + curr.count;
      }
      return acc;
    }, 0);
    const unreadMessageWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.UnreadMessages);
    const memberHealthLogWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.MemberHealthLog);
    const reEngagerWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.ReEngager);
    const weeklyEnsoReviewWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.WeeklyEnsoReview);
    const coachEscalationWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.CoachEscalation);
    const actionPlanCheckInWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.ActionPlanCheckIn);
    const firstWeekCheckInWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.FirstWeekCheckIn);
    const coachCalls = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.CoachCall);
    const coachCallsWorkflowsPostCallTasks = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.CoachCall, [VideoVisitsState.CompleteVideoVisit]);
    const coachCallsWorkflowsUpcomingCoachCalls = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.CoachCall, [VideoVisitsState.PerformVideoVisitFollowup]);
    const outreachNeededWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.OutreachNeeded);
    const maternityMileStoneWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.MaternityMilestones);
    const pregnancyStatusChangeWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.PregnancyStatusChange);
    const alertReminderWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.MemberAlertReminder);
    const reviewAndRecalibrateWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.ActionPlanReview);
    const painUptickWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.MemberPainUptick);
    const balanceAssessmentWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.BalanceAssessment);
    const firstWeekReviewWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.FirstWeekGoal);
    const coachWeeklyGoalExpiryWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.CoachWeeklyGoalExpiry);
    const coachMemberUpdatedWeeklyGoalWorkflows = getFilteredWorkflowsCount(incompleteResults, CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal);
    const videoVisitWorkflowsCounts = getSeparatedWorkflowCounts({
      results: ((incompleteResults || []) as WorkflowResult[]),
      workflowTypes: CoachVideoVisits,
      upcomingStates: [VideoVisitsState.CompleteVideoVisit],
      incompleteStates: [VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup],
      excludedStates: [...commonExcludedStates, ...commonCompletedStates]
    });
    const newCounts: IncompleteCount = {
      [CoachDashboardFilters.All]: getNewCount(allWorkflows),
      [CoachDashboardFilters.UnreadMessages]: getNewCount(unreadMessageWorkflows),
      [CoachDashboardFilters.MemberHealthLog]: getNewCount(memberHealthLogWorkflows),
      [CoachDashboardFilters.MemberHealthLogNoResponse]: lowCountHLN,
      [CoachDashboardFilters.UnreadMessagesNoResponse]: countLowPriorityUnreadMessages,
      [CoachDashboardFilters.ActionPlanCheckIn]: getNewCount(actionPlanCheckInWorkflows),
      [CoachDashboardFilters.FirstWeekCheckIn]: getNewCount(firstWeekCheckInWorkflows),
      [CoachDashboardFilters.CoachCalls]: getNewCount(coachCalls),
      [CoachDashboardFilters.CoachCallsPostCallTasks]: getNewCount(coachCallsWorkflowsPostCallTasks),
      [CoachDashboardFilters.CoachCallsUpcomingCoachCalls]: getNewCount(coachCallsWorkflowsUpcomingCoachCalls),
      [CoachDashboardFilters.ReEngager]: getNewCount(reEngagerWorkflows),
      [CoachDashboardFilters.CoachEscalation]: getNewCount(coachEscalationWorkflows),
      [CoachDashboardFilters.WeeklyEnsoReview]: getNewCount(weeklyEnsoReviewWorkflows),
      [CoachDashboardFilters.OutreachNeeded]: getNewCount(outreachNeededWorkflows),
      [CoachDashboardFilters.MaternityMilestones]: getNewCount(maternityMileStoneWorkflows),
      [CoachDashboardFilters.PregnancyStatusChange]: getNewCount(pregnancyStatusChangeWorkflows),
      [CoachDashboardFilters.AlertReminder]: getNewCount(alertReminderWorkflows),
      [CoachDashboardFilters.ReviewAndRecalibrate]: getNewCount(reviewAndRecalibrateWorkflows),
      [CoachDashboardFilters.PainUptick]: getNewCount(painUptickWorkflows),
      [CoachDashboardFilters.BalanceAssessment]: getNewCount(balanceAssessmentWorkflows),
      [CoachDashboardFilters.FirstWeekReview]: getNewCount(firstWeekReviewWorkflows),
      [CoachDashboardFilters.CoachWeeklyGoalExpiry]: getNewCount(coachWeeklyGoalExpiryWorkflows),
      [CoachDashboardFilters.CoachMemberUpdatedWeeklyGoal]: getNewCount(coachMemberUpdatedWeeklyGoalWorkflows),
      [CoachDashboardFilters.CoachVideoVisit]: getNewCount(videoVisitWorkflowsCounts.all),
      [CoachDashboardFilters.CoachVideoVisitUpcoming]: getNewCount(videoVisitWorkflowsCounts.upcoming),
      [CoachDashboardFilters.CoachVideoVisitIncomplete]: getNewCount(videoVisitWorkflowsCounts.incomplete)
    };
    setIncompleteCount(newCounts);
  }, [incompleteResults, lowCountHLN, countLowPriorityUnreadMessages]);
  const completedCount = completeData?.countWorkflowsWithWorkflowTypes?.total;
  useEffect(() => {
    if (completedCount !== undefined && completedCount !== null) {
      setCompleteCount(completedCount);
    }
  }, [completedCount]);
  const counts: Record<CoachDashboardFilters, number> = {
    ...incompleteCount,
    [CoachDashboardFilters.Completed]: completeCount
  };
  return {
    counts,
    loading: incompleteLoading || completeLoading,
    error: incompleteError || completeError
  };
};