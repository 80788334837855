import { AccessToken } from '@okta/okta-auth-js';
import dayjs from 'dayjs';
import { defaultErrorHandler } from './errors';
interface EventCallback {
  callbacks: Record<string, (event: Event) => void>;
  last?: MessageEvent;
}
export interface SseClientParams {
  url: string;
  accessToken: AccessToken;
  getOrRenewAccessToken: () => Promise<string | null>;
}
function setManagedInterval(intervalId: NodeJS.Timeout | undefined, callback: () => void, delay: number): NodeJS.Timeout {
  if (intervalId) {
    clearInterval(intervalId);
  }
  return setInterval(callback, delay);
}
function setManagedTimeout(timeoutId: NodeJS.Timeout | undefined, callback: () => void, delay: number): NodeJS.Timeout {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  return setTimeout(callback, delay);
}
export class SseClient {
  private eventSource?: EventSource;
  private readonly eventCallbacks: Map<string, EventCallback>;
  public initialized = false;
  private intervalId?: NodeJS.Timeout; // TODO: This should be named connResetTimer / idleResetTimer. MERU-3816
  private retryTimeout?: NodeJS.Timeout;
  private readonly retryDelay = 10000;
  private lastEventId = '';
  constructor() {
    this.eventCallbacks = new Map();
    this.eventSource = undefined;
  }
  private appendLastEventIdToURL(url: string, lastEventId: string): string {
    try {
      const urlString = new URL(url);
      urlString.searchParams.set('lastEventId', lastEventId);
      return urlString.toString();
    } catch (error) {
      console.warn('Error parsing URL', error);
    }
    return url;
  }
  private setTokenCookieForDomain(url: string, token: string): void {
    try {
      const urlString = new URL(url);
      const hostname = urlString.hostname;
      if (hostname === 'localhost') {
        document.cookie = `token=${token}; Secure`;
      } else {
        const domain = `.${hostname.split('.').slice(1).join('.')}`;
        document.cookie = `token=${token}; domain=${domain}; Secure`;
      }
    } catch (error) {
      console.warn('Error setting domain in cookie:', error);
      document.cookie = `token=${token}; Secure`;
    }
  }
  private appendTokenToURL(url: string, token: string): string {
    try {
      const urlString = new URL(url);
      urlString.searchParams.set('token', token); // add or update the token query parameter
      return urlString.toString();
    } catch (error) {
      console.warn('Error parsing URL', error);
    }
    return url;
  }
  public async init({
    url,
    accessToken,
    getOrRenewAccessToken
  }: SseClientParams): Promise<void> {
    const token = await this.maybeRenewToken({
      url: this.appendLastEventIdToURL(url, this.lastEventId),
      accessToken,
      getOrRenewAccessToken
    }).catch(error => {
      defaultErrorHandler(`Okta error: ${error.message}`);
    });
    if (!token) {
      console.warn('SSEClient: no token');
      this.initialized = false;
      return;
    }
    if (process.env.DISABLE_SSE_COOKIES === 'true') {
      url = this.appendTokenToURL(url, token);
    } else {
      this.setTokenCookieForDomain(url, token);
    }
    this.eventSource = new EventSource(this.appendLastEventIdToURL(url, this.lastEventId), {
      withCredentials: true
    });
    const params = {
      url: this.appendLastEventIdToURL(url, this.lastEventId),
      accessToken,
      getOrRenewAccessToken
    };
    this.eventSource.onopen = this.onOpenCallback(params);
    this.eventSource.onerror = this.onErrorCallback(params);
    this.eventSource.onmessage = this.onMessageCallback(params);
  }
  private onErrorCallback(params: SseClientParams) {
    return async (event: Event): Promise<void> => {
      // This is to handle cases where network connection is disrupted or goes offline
      console.warn('SSEClient: error callback', event);
      this.close();
      if (this.retryTimeout) {
        clearTimeout(this.retryTimeout);
      }
      this.retryTimeout = setManagedTimeout(this.retryTimeout, async () => {
        await this.init(params);
      }, this.retryDelay);
    };
  }
  private onOpenCallback(params: SseClientParams) {
    return (): void => {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      } else {
        console.warn('No interval initialized');
      }
      this.intervalId = setManagedInterval(this.intervalId, async () => {
        await this.maybeResetConnection(params);
      }, 25 * 1000);
    };
  }
  private onMessageCallback(params: SseClientParams) {
    return (messageEvent: MessageEvent): void => {
      this.lastEventId = messageEvent.lastEventId;
      const parsedEvent = JSON.parse(messageEvent.data);
      const eventType = parsedEvent?.type;
      const eventHandler = this.eventCallbacks.get(eventType);
      const eventHandlerCallbacks = Object.values(eventHandler?.callbacks || {});
      if (eventHandlerCallbacks.length) {
        eventHandlerCallbacks.forEach(callback => {
          callback(parsedEvent);
        });
      } else {
        if (eventType !== 'keepAlive') {
          console.warn('No handler for incoming event', parsedEvent?.type);
        }
      }

      // TODO: exclude keepAlive events being replayed here. MERU-3816
      this.eventCallbacks.set(eventType, {
        callbacks: eventHandler?.callbacks || {},
        last: parsedEvent
      });
      if (!this.intervalId) {
        console.warn('No interval initialized in onMessageHandler');
        return;
      }
      clearInterval(this.intervalId);
      this.intervalId = setManagedInterval(this.intervalId, async () => {
        await this.maybeResetConnection(params);
      }, 25 * 1000);
    };
  }
  private async maybeResetConnection(params: SseClientParams): Promise<void> {
    console.debug('Resetting the SSE connection');
    this.close();
    await this.init(params);
  }
  private async maybeRenewToken({
    accessToken,
    getOrRenewAccessToken
  }: SseClientParams): Promise<string> {
    let token = accessToken?.accessToken;
    const expiresAt = dayjs.unix(accessToken.expiresAt); // unix takes time
    if (expiresAt.diff(dayjs(), 'seconds') > 10) {
      return token;
    }
    try {
      const renewedToken = await getOrRenewAccessToken();
      if (renewedToken) {
        token = renewedToken;
      }
    } catch (error) {
      throw new Error(`[SSE Client] > ${(error as Error)?.message}`);
    }
    return token;
  }
  public replay(eventType: string): void {
    const eventHandler = this.eventCallbacks.get(eventType);
    const callbacks = Object.values(eventHandler?.callbacks || {});
    if (!callbacks.length || !eventHandler?.last) {
      return;
    }
    callbacks.forEach(callback => {
      if (eventHandler.last) {
        callback(eventHandler.last);
      }
    });
  }
  public subscribe(eventType: string, subscriberId: string, callback: (event: Event) => unknown): void {
    const eventHandlers = this.eventCallbacks.get(eventType);
    const callbacks = eventHandlers?.callbacks || {};
    this.eventCallbacks.set(eventType, {
      callbacks: {
        ...callbacks,
        [subscriberId]: callback
      },
      last: eventHandlers?.last
    });
  }
  public unsubscribe(eventType: string, subscriberId: string): void {
    const eventHandler = this.eventCallbacks.get(eventType);
    if (!eventHandler) {
      return;
    }
    const {
      [subscriberId]: removedSubscriberId,
      ...otherCallbacks
    } = eventHandler.callbacks;
    this.eventCallbacks.set(eventType, {
      callbacks: otherCallbacks,
      last: eventHandler.last
    });
  }
  public close(): void {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = undefined;
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
    this.eventSource?.close();
    this.initialized = false;
  }
}