import { OsWorkflowTypes } from '../../../custom-types';
export enum OsDashboardFilters {
  All = 'All',
  UnreadMessage = 'Unread messages',
  UnreadMessageNoResponse = 'Unread messages (No Response)',
  AlertReminder = 'Alert reminder',
  Completed = 'Completed today',
  Messages = 'Messages',
  AllTypes = 'All Types',
  ReEngager = 'Re-engager',
  OutreachNeeded = 'Outreach needed',
  Nuto = 'New User To Onboard',
  Ruto = 'Returning User To Onboard',
}
export const defaultOsWorkflows = [OsWorkflowTypes.UnreadMessages, OsWorkflowTypes.MemberAlertReminder, OsWorkflowTypes.ReEngager, OsWorkflowTypes.OutreachNeeded, OsWorkflowTypes.Nuto, OsWorkflowTypes.Ruto];
export const unreadMessageFilters = [OsDashboardFilters.UnreadMessage, OsDashboardFilters.UnreadMessageNoResponse];
export enum CommonOsState {
  InactiveMember = 'inactive-member',
  AcuteComplete = 'acute-complete',
  AcuteDismiss = 'acute-dismiss',
  VideoVisitComplete = 'complete',
  Dismiss = 'dismiss',
  AwaitingNew = 'awaiting-new',
  Complete = 'complete',
  Closed = 'closed',
  Archive = 'archive',
}
export const commonOsExcludedStates = [CommonOsState.AcuteComplete, CommonOsState.InactiveMember, CommonOsState.Dismiss, CommonOsState.AwaitingNew, CommonOsState.Closed, CommonOsState.Complete, CommonOsState.Archive];
export const commonOsCompletedStates: CommonOsState[] = [CommonOsState.Dismiss, CommonOsState.AcuteDismiss, CommonOsState.AcuteComplete, CommonOsState.Closed, CommonOsState.Complete];