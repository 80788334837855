import { HHAvatar, HHItemNavLinkProps } from '@hinge-health/react-component-library';
import { Campaign, Chat, Dashboard, HomeRounded, List, LocalHospital, People, Science, Subscriptions } from '@mui/icons-material';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import { QueueCountNavIcon } from '../components/navigation-components/queue-count-nav-icon';
import { QueueNextNavIcon } from '../components/navigation-components/queue-next-nav-icon';
import { LEGACY_NAVIGATION_LINK_NAMES, NAVIGATION_LINK_NAMES, NESTED_ROUTES, ROUTES } from '../constants';
import { NAVIGATION_TOOLTIPS } from '../constants/strings/navigation-items';
import { QUEUE_TOOLTIPS } from '../constants/strings/workflows-queue';
import { OktaRole } from '../types/okta-roles';
import { getAdminRole } from './admin-role';
import { isProduction } from './environment';
export const getRoleBasedNavItems = (roles: OktaRole[], massMessagingSplitFlag: boolean, mpeQueueTreatment: boolean, redirectCttToCtpSplitFlag: boolean): HHItemNavLinkProps[] => {
  const navLinks = {
    home: {
      to: ROUTES.HOME,
      primary: NAVIGATION_LINK_NAMES.HOME,
      icon: <HomeRounded />,
      key: NAVIGATION_LINK_NAMES.HOME
    },
    member: {
      to: `${ROUTES.MEMBER}/${NESTED_ROUTES.ID}`,
      primary: NAVIGATION_LINK_NAMES.MEMBER,
      icon: <HHAvatar hhVariant="small" />,
      key: NAVIGATION_LINK_NAMES.MEMBER
    },
    pt: {
      to: ROUTES.PT,
      primary: NAVIGATION_LINK_NAMES.PT,
      icon: <Dashboard />,
      key: NAVIGATION_LINK_NAMES.PT,
      tooltip: QUEUE_TOOLTIPS.DASHBOARD
    },
    os: {
      to: ROUTES.OS,
      primary: NAVIGATION_LINK_NAMES.OS,
      icon: <Dashboard />,
      key: NAVIGATION_LINK_NAMES.OS,
      tooltip: QUEUE_TOOLTIPS.DASHBOARD
    },
    orthoSpec: {
      to: ROUTES.ORTHO_SPEC,
      primary: NAVIGATION_LINK_NAMES.ORTHO_SPEC,
      icon: <Dashboard />,
      key: NAVIGATION_LINK_NAMES.ORTHO_SPEC,
      tooltip: QUEUE_TOOLTIPS.DASHBOARD
    },
    acute: {
      to: ROUTES.ACUTE,
      primary: NAVIGATION_LINK_NAMES.ACUTE,
      icon: <Dashboard />,
      key: NAVIGATION_LINK_NAMES.ACUTE
    },
    coach: {
      to: ROUTES.COACH,
      primary: NAVIGATION_LINK_NAMES.COACH,
      icon: <Dashboard />,
      key: NAVIGATION_LINK_NAMES.COACH,
      tooltip: QUEUE_TOOLTIPS.DASHBOARD
    },
    queue: {
      primary: NAVIGATION_LINK_NAMES.QUEUE,
      icon: <QueueCountNavIcon />,
      key: NAVIGATION_LINK_NAMES.QUEUE,
      to: '',
      tooltip: QUEUE_TOOLTIPS.QUEUE_LIST
    },
    next: {
      primary: NAVIGATION_LINK_NAMES.NEXT,
      icon: <QueueNextNavIcon />,
      to: '',
      key: NAVIGATION_LINK_NAMES.NEXT
    },
    test: {
      to: ROUTES.TEST,
      primary: NAVIGATION_LINK_NAMES.TEST,
      icon: <Science />,
      key: NAVIGATION_LINK_NAMES.TEST,
      tooltip: NAVIGATION_TOOLTIPS.TEST_USERS
    },
    // legacy CTT
    tasks: {
      to: ROUTES.PORTAL,
      primary: LEGACY_NAVIGATION_LINK_NAMES.TASKS,
      icon: <List />,
      key: LEGACY_NAVIGATION_LINK_NAMES.TASKS,
      tooltip: NAVIGATION_TOOLTIPS.CTT_TASKS
    },
    teams: {
      to: ROUTES.PORTAL,
      primary: LEGACY_NAVIGATION_LINK_NAMES.TEAMS,
      icon: <People />,
      key: LEGACY_NAVIGATION_LINK_NAMES.TEAMS,
      tooltip: NAVIGATION_TOOLTIPS.MY_TEAMS
    },
    messaging: {
      to: ROUTES.PORTAL,
      primary: LEGACY_NAVIGATION_LINK_NAMES.MESSAGING,
      icon: <Chat />,
      key: LEGACY_NAVIGATION_LINK_NAMES.MESSAGING,
      tooltip: NAVIGATION_TOOLTIPS.CTT_MESSAGING
    },
    carePlans: {
      to: ROUTES.PORTAL,
      primary: LEGACY_NAVIGATION_LINK_NAMES.CARE_PLANS,
      icon: <LocalHospital />,
      key: LEGACY_NAVIGATION_LINK_NAMES.CARE_PLANS
    },
    massMessaging: {
      to: massMessagingSplitFlag ? ROUTES.MASS_MESSAGE_LIST : ROUTES.PORTAL,
      primary: NAVIGATION_LINK_NAMES.MASS_MESSAGING,
      icon: <Campaign />,
      key: NAVIGATION_LINK_NAMES.MASS_MESSAGING,
      tooltip: NAVIGATION_TOOLTIPS.MASS_MESSENGER
    },
    memberSearch: {
      to: ROUTES.MEMBER_SEARCH,
      primary: NAVIGATION_LINK_NAMES.MEMBER_SEARCH,
      icon: <PersonSearchRoundedIcon />,
      key: NAVIGATION_LINK_NAMES.MEMBER_SEARCH,
      tooltip: NAVIGATION_TOOLTIPS.MEMBER_SEARCH
    },
    mpeQueue: {
      to: ROUTES.MPE_QUEUE,
      primary: NAVIGATION_LINK_NAMES.MPE_QUEUE,
      icon: <Subscriptions />,
      key: NAVIGATION_LINK_NAMES.MPE_QUEUE,
      tooltip: NAVIGATION_TOOLTIPS.MPE_QUEUE
    }
  };
  const ptMassMessage = massMessagingSplitFlag ? [navLinks.massMessaging] : [];
  switch (getAdminRole(roles)) {
    case OktaRole.PhysicalTherapist:
      return [navLinks.pt, navLinks.queue, navLinks.next, navLinks.memberSearch, ...(isProduction ? [] : [navLinks.test]), ...ptMassMessage, ...(redirectCttToCtpSplitFlag ? [] : [navLinks.teams]), ...(mpeQueueTreatment ? [navLinks.mpeQueue] : [])];
    case OktaRole.Coach:
      return [navLinks.coach, navLinks.queue, navLinks.next, navLinks.memberSearch, ...(redirectCttToCtpSplitFlag ? [] : [navLinks.teams, navLinks.messaging]), ...(isProduction ? [] : [navLinks.test]), ...ptMassMessage, ...(mpeQueueTreatment ? [navLinks.mpeQueue] : [])];
    case OktaRole.NursePractitioner:
      return [navLinks.memberSearch, ...(redirectCttToCtpSplitFlag ? [] : [navLinks.tasks, navLinks.teams, navLinks.messaging])];
    case OktaRole.OnboardingSpecialist:
      return [navLinks.os, navLinks.queue, navLinks.next, navLinks.memberSearch, ...(redirectCttToCtpSplitFlag ? [] : [navLinks.tasks, navLinks.teams, navLinks.messaging])];
    case OktaRole.OrthopedicSpecialist:
      return [navLinks.memberSearch];
    default:
      return [navLinks.member];
  }
};