import { c as _c } from "react-compiler-runtime";
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import dayjs from 'dayjs';
import { includes, orderBy, some, uniqBy } from 'lodash';
import { useCallback, useEffect, useReducer } from 'react';
import { SseEvents } from '../../../constants/strings/events';
import { useSseContext, WorkflowCreatedSseData, WorkflowTransitionedSseData } from '../../../contexts/sse.context';
import { commonCoachCompletedStates, CommonCoachState } from '../../../custom-types';
import { useCurrentStoredAdminHook } from '../../../hooks/use-current-stored-admin';
import { useGetAppointmentsQuery, WorkflowPayload } from '../../../types';
import { OktaRole } from '../../../types/okta-roles';
import { commonPtCompletedStates, CommonPtState } from '../../dashboard/types/pt';
import { useGetWorkflowLazyQuery } from '../../member-360/modules/panel-layouts/types';
import { APPOINTMENT_WORKFLOW_TYPES } from '../constants';
import { getWorkflowCallDate } from '../utils';
import isToday from 'dayjs/plugin/isToday';
dayjs.extend(isToday);
interface AppointmentsState {
  loading: boolean;
  appointments: WorkflowPayload[];
  incompleteAppointmentsCount: number;
  completedAppointmentsCount: number;
  error: Error | undefined;
}
type SetIncompleteAppointmentsAction = {
  type: 'SetIncompleteAppointments';
  payload: WorkflowPayload[];
};
type SetCompletedAppointmentsAction = {
  type: 'SetCompletedAppointments';
  payload: WorkflowPayload[];
};
type SetError = {
  type: 'SetError';
  payload: Error;
};
type WorkflowCreatedAction = {
  type: 'WorkflowCreated';
  payload: WorkflowPayload[];
};
type WorkflowTransitionedAction = {
  type: 'WorkflowTransitioned';
  payload: WorkflowTransitionedSseData;
};
type Action = SetIncompleteAppointmentsAction | SetCompletedAppointmentsAction | SetError | WorkflowCreatedAction | WorkflowTransitionedAction;
const INITIAL_STATE: AppointmentsState = {
  loading: true,
  appointments: [],
  incompleteAppointmentsCount: 0,
  completedAppointmentsCount: 0,
  error: undefined
};
const appointmentsReducer = (state: AppointmentsState, action: Action): AppointmentsState => {
  switch (action.type) {
    case 'SetIncompleteAppointments':
      {
        const appointments = [...state.appointments, ...action.payload];
        return {
          ...state,
          loading: false,
          appointments: orderBy(uniqBy(appointments, 'id'), getWorkflowCallDate),
          incompleteAppointmentsCount: action.payload.length
        };
      }
    case 'SetCompletedAppointments':
      {
        const appointments = [...state.appointments, ...action.payload];
        return {
          ...state,
          loading: false,
          appointments: orderBy(uniqBy(appointments, 'id'), getWorkflowCallDate),
          completedAppointmentsCount: action.payload.length
        };
      }
    case 'WorkflowCreated':
      {
        const appointments = [...state.appointments, ...action.payload];
        return {
          ...state,
          loading: false,
          appointments: orderBy(uniqBy(appointments, 'id'), getWorkflowCallDate),
          incompleteAppointmentsCount: state.incompleteAppointmentsCount + 1
        };
      }
    case 'WorkflowTransitioned':
      {
        const appointments = state.appointments.map(appointment => {
          if (appointment.id !== action.payload.workflowId) {
            return appointment;
          } else {
            return {
              ...appointment,
              state: action.payload.stateName
            };
          }
        });
        const updatedState = {
          ...state,
          loading: false,
          appointments: orderBy(uniqBy(appointments, 'id'), getWorkflowCallDate)
        };
        const workflowState = (action.payload?.stateName as CommonCoachState | CommonPtState);
        const completedStates = [...commonCoachCompletedStates, ...commonPtCompletedStates];
        if (completedStates.includes(workflowState)) {
          updatedState.completedAppointmentsCount += 1;
          updatedState.incompleteAppointmentsCount -= 1;
        }
        return updatedState;
      }
    case 'SetError':
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
export const useAppointments = () => {
  const $ = _c(39);
  const [state, dispatch] = useReducer(appointmentsReducer, INITIAL_STATE);
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const {
    sseData
  } = useSseContext();
  const roles = (hingeHealthAuthState?.accessToken?.claims.roles as OktaRole[]);
  const coachRole = roles?.find(role => role === OktaRole.Coach);
  const ptRole = roles?.find(role_0 => role_0 === OktaRole.PhysicalTherapist);
  const t0 = currentStoredAdmin?.uuid ?? "";
  let t1;
  if ($[0] !== t0) {
    t1 = [t0];
    $[0] = t0;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const t2 = APPOINTMENT_WORKFLOW_TYPES[coachRole ?? ptRole ?? "default"];
  const t3 = !ptRole;
  let t4;
  if ($[2] !== t1 || $[3] !== t2 || $[4] !== t3) {
    t4 = {
      adminUuids: t1,
      workflowTypes: t2,
      pageSize: 50,
      useCoverage: t3,
      order: "DESC",
      orderBy: "latestTaskDate"
    };
    $[2] = t1;
    $[3] = t2;
    $[4] = t3;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  const commonVariables = t4;
  const [fetchWorkflowById] = useGetWorkflowLazyQuery();
  let t5;
  if ($[6] !== fetchWorkflowById || $[7] !== dispatch) {
    t5 = sseData_0 => {
      const workflowId = sseData_0.workflowId;
      const workflowType = sseData_0.serviceName;
      const isAppointmentWorkflow = some(APPOINTMENT_WORKFLOW_TYPES, workflowArray => includes(workflowArray, workflowType));
      if (!isAppointmentWorkflow) {
        return;
      }
      fetchWorkflowById({
        variables: {
          id: workflowId
        },
        onCompleted: data => {
          const workflow = (data?.getWorkflow as WorkflowPayload);
          if (workflow && dayjs(workflow.customFields.callDate).isToday()) {
            dispatch({
              type: "WorkflowCreated",
              payload: [workflow]
            });
          }
        }
      });
    };
    $[6] = fetchWorkflowById;
    $[7] = dispatch;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  const handleWorkflowCreatedSseEvent = t5;
  const t6 = !currentStoredAdmin?.uuid || !(coachRole ?? ptRole);
  let t7;
  if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = dayjs().startOf("day").toISOString();
    $[9] = t7;
  } else {
    t7 = $[9];
  }
  let t8;
  if ($[10] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = dayjs().endOf("day").toISOString();
    $[10] = t8;
  } else {
    t8 = $[10];
  }
  let t9;
  if ($[11] !== commonVariables) {
    t9 = {
      workflowFiltersInput: {
        ...commonVariables,
        dueTaskStartDate: t7,
        dueTaskEndDate: t8,
        excludeAllPassiveStates: true,
        excludeAllCompletedStates: true
      }
    };
    $[11] = commonVariables;
    $[12] = t9;
  } else {
    t9 = $[12];
  }
  let t10;
  let t11;
  if ($[13] !== dispatch) {
    t10 = data_0 => {
      dispatch({
        type: "SetIncompleteAppointments",
        payload: ((data_0?.getWorkflowsByFilter?.workflows ?? []) as WorkflowPayload[])
      });
    };
    t11 = error => {
      dispatch({
        type: "SetError",
        payload: error
      });
    };
    $[13] = dispatch;
    $[14] = t10;
    $[15] = t11;
  } else {
    t10 = $[14];
    t11 = $[15];
  }
  let t12;
  if ($[16] !== t6 || $[17] !== t9 || $[18] !== t10 || $[19] !== t11) {
    t12 = {
      skip: t6,
      fetchPolicy: "no-cache",
      variables: t9,
      onCompleted: t10,
      onError: t11
    };
    $[16] = t6;
    $[17] = t9;
    $[18] = t10;
    $[19] = t11;
    $[20] = t12;
  } else {
    t12 = $[20];
  }
  useGetAppointmentsQuery(t12);
  const t13 = !currentStoredAdmin?.uuid || !(coachRole ?? ptRole);
  let t14;
  if ($[21] === Symbol.for("react.memo_cache_sentinel")) {
    t14 = dayjs().startOf("day").toISOString();
    $[21] = t14;
  } else {
    t14 = $[21];
  }
  let t15;
  if ($[22] !== commonVariables) {
    t15 = {
      workflowFiltersInput: {
        ...commonVariables,
        includeOnlyCompletedStates: true,
        completedAfterDate: t14
      }
    };
    $[22] = commonVariables;
    $[23] = t15;
  } else {
    t15 = $[23];
  }
  let t16;
  let t17;
  if ($[24] !== dispatch) {
    t16 = data_1 => {
      dispatch({
        type: "SetCompletedAppointments",
        payload: ((data_1?.getWorkflowsByFilter?.workflows ?? []) as WorkflowPayload[])
      });
    };
    t17 = error_0 => {
      dispatch({
        type: "SetError",
        payload: error_0
      });
    };
    $[24] = dispatch;
    $[25] = t16;
    $[26] = t17;
  } else {
    t16 = $[25];
    t17 = $[26];
  }
  let t18;
  if ($[27] !== t13 || $[28] !== t15 || $[29] !== t16 || $[30] !== t17) {
    t18 = {
      skip: t13,
      fetchPolicy: "no-cache",
      variables: t15,
      onCompleted: t16,
      onError: t17
    };
    $[27] = t13;
    $[28] = t15;
    $[29] = t16;
    $[30] = t17;
    $[31] = t18;
  } else {
    t18 = $[31];
  }
  useGetAppointmentsQuery(t18);
  let t19;
  if ($[32] !== sseData || $[33] !== handleWorkflowCreatedSseEvent || $[34] !== dispatch) {
    t19 = () => {
      if (!sseData) {
        return;
      }
      const type = (sseData.type as string);
      if (type === SseEvents.WorkflowCreated) {
        handleWorkflowCreatedSseEvent((sseData as WorkflowCreatedSseData));
      }
      if (type === SseEvents.WorkflowTransitioned) {
        dispatch({
          type: "WorkflowTransitioned",
          payload: (sseData as WorkflowTransitionedSseData)
        });
      }
    };
    $[32] = sseData;
    $[33] = handleWorkflowCreatedSseEvent;
    $[34] = dispatch;
    $[35] = t19;
  } else {
    t19 = $[35];
  }
  let t20;
  if ($[36] !== sseData || $[37] !== handleWorkflowCreatedSseEvent) {
    t20 = [sseData, handleWorkflowCreatedSseEvent];
    $[36] = sseData;
    $[37] = handleWorkflowCreatedSseEvent;
    $[38] = t20;
  } else {
    t20 = $[38];
  }
  useEffect(t19, t20);
  return state;
};