import { PtWorkflowTypes, UnreadMessagesWorkflowTypes } from '../../../../custom-types';
import { PtDashboardFilters } from '../../types/pt';
export const PT_VIDEO_VISIT_NAMES: Partial<Record<PtWorkflowTypes, string>> = {
  [PtWorkflowTypes.Chronic]: 'Chronic video visit',
  [PtWorkflowTypes.Acute]: 'Acute video visit',
  [PtWorkflowTypes.Surgery]: 'Surgery video visit',
  [PtWorkflowTypes.FifteenMinute]: '15 minute video visit'
};
export const PT_WORKFLOW_TYPES: Record<PtWorkflowTypes | UnreadMessagesWorkflowTypes, string> = {
  [PtWorkflowTypes.PainUptick]: PtDashboardFilters.PainUptick,
  [PtWorkflowTypes.UnreadMessages]: 'Unread',
  [PtWorkflowTypes.Closing]: PtDashboardFilters.DischargeAndClosePlan,
  [PtWorkflowTypes.Expiring]: PtDashboardFilters.ExpiringCarePlans,
  [PtWorkflowTypes.HealthLog]: PtDashboardFilters.HealthLogNote,
  [PtWorkflowTypes.HouseCall]: PtDashboardFilters.HouseCall,
  [(PtWorkflowTypes.FifteenMinute as string)]: PT_VIDEO_VISIT_NAMES[PtWorkflowTypes.FifteenMinute],
  [(PtWorkflowTypes.Surgery as string)]: PT_VIDEO_VISIT_NAMES[PtWorkflowTypes.Surgery],
  [(PtWorkflowTypes.Acute as string)]: PT_VIDEO_VISIT_NAMES[PtWorkflowTypes.Acute],
  [(PtWorkflowTypes.Chronic as string)]: PT_VIDEO_VISIT_NAMES[PtWorkflowTypes.Chronic],
  [PtWorkflowTypes.EtModification]: PtDashboardFilters.EtModification,
  [PtWorkflowTypes.IndicationUpdate]: PtDashboardFilters.IndicationUpdate,
  [PtWorkflowTypes.ClinicalEscalation]: PtDashboardFilters.ClinicalEscalation,
  [PtWorkflowTypes.MedicalEmergencyFollowUp]: PtDashboardFilters.MedicalEmergencyFollowUp,
  [PtWorkflowTypes.SetupActionPlan]: PtDashboardFilters.SetupActionPlan,
  [PtWorkflowTypes.SurgeryTrend]: PtDashboardFilters.SurgeryTrend,
  [UnreadMessagesWorkflowTypes.CareTeamMessage]: 'Care team message',
  [PtWorkflowTypes.AqRedFlags]: PtDashboardFilters.AqRedFlags,
  [PtWorkflowTypes.FirstWeekEnsoCheckIn]: PtDashboardFilters.FirstWeekEnsoCheckIn,
  [PtWorkflowTypes.PtMemberAlertReminder]: PtDashboardFilters.PtMemberAlertReminder,
  [PtWorkflowTypes.ActionPlanReview]: PtDashboardFilters.ActionPlanReview,
  [PtWorkflowTypes.PelvicTrainerOutreach]: PtDashboardFilters.PelvicTrainerOutreach,
  [PtWorkflowTypes.OrthoClinicalEscalation]: 'Clinical Escalation (Ortho)'
};