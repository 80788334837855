import { ctpTokens } from '@hinge-health/react-component-library';
export const sxStyles = {
  errorOutlineRounded: {
    color: 'error',
    bgcolor: 'rgba(252, 225, 222, 1)',
    width: '40px',
    height: '40px',
    padding: '8px',
    borderRadius: '8px'
  },
  hhListItem: {
    bgcolor: 'rgba(249, 247, 242, 1)',
    borderRadius: '12px',
    padding: '12px'
  },
  hhDialogActions: {
    position: 'sticky',
    bottom: 0
  },
  submitButton: {
    width: '161px !important',
    minWidth: '161px !important',
    backgroundColor: ctpTokens.palette.green[40],
    color: ctpTokens.palette.neutral[100],
    '&:hover': {
      backgroundColor: ctpTokens.palette.green[40],
      color: ctpTokens.palette.neutral[100]
    }
  },
  baseUrl: {
    textDecoration: 'underline',
    color: ctpTokens.palette.green[40],
    pointerEvents: 'auto',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none'
  },
  divider: {
    width: '100%'
  },
  closeButton: {
    float: 'right',
    cursor: 'pointer',
    color: 'rgba(5, 46, 46, 0.5)'
  },
  hhDialogPaperProps: {
    minWidth: '640px',
    minHeight: '258px',
    width: 'auto',
    height: 'auto',
    top: '124px',
    left: '600px',
    borderRadius: '12px',
    gap: '0px',
    position: 'inherit'
  },
  hhDialogContentText: {
    marginTop: '15px',
    marginBottom: '8px'
  },
  fileUploadErrorTypography: {
    color: 'rgba(207, 49, 43, 1)',
    fontSize: '12px',
    marginLeft: '8px'
  },
  phiDisclaimerBox: {
    bgcolor: 'rgba(207, 185, 145, 0.12)',
    borderRadius: '32px',
    paddingX: 1.5,
    paddingY: 1
  },
  doneIconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    radius: '8px',
    gap: '10px'
  },
  doneIcon: {
    background: 'rgba(233, 247, 238, 1)',
    borderRadius: '8px',
    padding: '8px',
    gap: '8px',
    display: 'flex',
    color: 'rgba(0, 123, 52, 1)'
  },
  errorIcon: {
    background: 'rgba(252, 225, 222, 1)',
    color: 'rgba(207, 49, 43, 1)',
    borderRadius: '8px',
    padding: '8px',
    gap: '8px',
    display: 'flex'
  },
  textcolor: {
    color: '#656D6D'
  }
};
export const layoutStyles = {
  typography: {
    marginTop: '10px',
    marginBottom: '8px',
    textAlign: 'center',
    Padding: '18px'
  },
  fileUploadErrorTypography: {
    top: '8px',
    paddingTop: '8px'
  },
  fileUploadErrorBox: {
    color: '#CF312B',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.4px',
    textAlign: 'left',
    textUnderlinePosition: 'from - font',
    textDecorationSkipInk: 'none',
    padding: '5px 5px 0px 5px'
  }
};