import { c as _c } from "react-compiler-runtime";
import { useLazyQuery } from '@apollo/client';
import { HHTableCell, HHTableRow } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CURRENT_TAB_CHANGED, SESSION_ID } from '../../../../../constants/strings/member';
import { acuteLayoutUrl, memberLayoutUrl, portalSidebarUrl } from '../../../../../constants/strings/workflow-urls';
import { acuteSidebarWorkflows, IamMessageSource, PtWorkflowTypes, threePanelLayoutWorkflows } from '../../../../../custom-types';
import { useGenericMixpanelTrack } from '../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../lib/mixpanel/mixpanel-event-names';
import { WorkflowSelectedFromDashboardProperties } from '../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { getLocalStorageItem } from '../../../../../utils/local-storage-utils';
import { AcuteGroupType } from '../../../../acute-physical-therapy/custom-types';
import { isVideoVisitsWorkflowType } from '../../../../acute-physical-therapy/utils/video-visits';
import { SIMPLIFIED_WORKFLOWS } from '../../../../member-360/constants/splits';
import { GET_USER_INFO } from '../../../../member-360/queries/user.graphql';
import { ON } from '../../../constants/splits';
import { LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME } from '../../../constants/strings/coach-dashboard-default';
import { NO_RESPONSE_PREDICTED_TAB_NAME } from '../../../constants/strings/pt-layout';
import { useShellContext } from '../../../contexts/shell.context';
import { completeWorkflowFilters, DashboardType, UnreadFilterType } from '../../../custom-types';
import { listOfIncompletedWorkflowsObject, listOfWorkflowsObject, listToMarkWorkflowComplete, listToMarkWorkflowPending, selectAllPTWorkflowAtom, selectAllWorkflowAtom, selectedCoachDashboardFilters, selectedDashboardType, selectedOsDashboardFilters, selectedPtDashboardFilters } from '../../../jotai/atoms';
import { WorkflowPayload } from '../../../types';
import { CoachWorkflowTypes } from '../../../types/coach';
import { ptCarePlanFilters, PtDashboardFilters } from '../../../types/pt';
import { getCompleteWorkflowFilters } from '../../../utils/get-complete-workflow-filters';
import { getPriorityString } from '../../../utils/get-workflow-priority';
import { WorkflowBulkMark } from './table-row-cells/workflow-bulk-mark';
import { WorkflowDateCell } from './table-row-cells/workflow-created-on';
import { WorkflowDueCell } from './table-row-cells/workflow-due';
import { WorkflowMarkCell } from './table-row-cells/workflow-mark';
import { WorkflowMemberCell } from './table-row-cells/workflow-member';
import { WorkflowMemberStateCell } from './table-row-cells/workflow-member-state';
import { WorkflowTypeCell } from './table-row-cells/workflow-type';
import WorkflowUnreadTypeCell from './table-row-cells/workflow-unread-type-cell';
export type CoachWorkflowsTableRowProps = {
  workflow: WorkflowPayload;
};
export const WorkflowsTableRow = t0 => {
  const $ = _c(84);
  const {
    workflow
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = [];
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const mixpanelTrack = useGenericMixpanelTrack(t1);
  const [selectedDashboardFilter] = useAtom(selectedCoachDashboardFilters);
  const [selectedPtDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [selectedOsDashboardFilter] = useAtom(selectedOsDashboardFilters);
  const {
    adminProfile
  } = useShellContext();
  const navigate = useNavigate();
  const splitClient = useClient();
  const workflowName = workflow?.customFields?.source === IamMessageSource.HLN ? UnreadFilterType.HealthLog : UnreadFilterType.Messages;
  const [displayCompleteIcon, setDisplayCompleteIcon] = useState(false);
  let t2;
  if ($[1] !== workflow.type) {
    t2 = threePanelLayoutWorkflows.includes((workflow.type as CoachWorkflowTypes));
    $[1] = workflow.type;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  const navigateToThreePanelMemberView = t2;
  const [, setListToMarkComplete] = useAtom(listToMarkWorkflowComplete);
  const [, setListToMarkPending] = useAtom(listToMarkWorkflowPending);
  const [displayEncounterDate, setDisplayEncounterDate] = useState(workflow?.createdAt);
  const isCompleteWorkflowFilter = getCompleteWorkflowFilters([selectedDashboardFilter.filterLabel, selectedPtDashboardFilter.filterLabel, selectedOsDashboardFilter.filterLabel]);
  const [currentDashboard] = useAtom(selectedDashboardType);
  const [, setListOfSelectedWorkflows] = useAtom(listOfWorkflowsObject);
  const [, setListOfUnselectedWorkflows] = useAtom(listOfIncompletedWorkflowsObject);
  const allWorkflowFlag = useAtomValue(selectAllWorkflowAtom);
  const allPtWorkflowFlag = useAtomValue(selectAllPTWorkflowAtom);
  const [userInfoPrefetched, setUserInfoPrefetched] = useState(false);
  const [getUserInfo] = useLazyQuery(GET_USER_INFO);
  const isSimplifiedWorkflowsSplitOn = splitClient?.getTreatment(SIMPLIFIED_WORKFLOWS, {
    adminUuid: adminProfile.uuid || "*"
  }) === ON;
  const t3 = (workflow?.priority as number);
  let t4;
  if ($[3] !== t3) {
    t4 = getPriorityString(t3);
    $[3] = t3;
    $[4] = t4;
  } else {
    t4 = $[4];
  }
  const priorityString = (t4 as string);
  let t5;
  if ($[5] !== mixpanelTrack || $[6] !== workflow || $[7] !== priorityString || $[8] !== selectedDashboardFilter.filterTabName || $[9] !== isSimplifiedWorkflowsSplitOn || $[10] !== navigate || $[11] !== selectedPtDashboardFilter.filterLabel || $[12] !== navigateToThreePanelMemberView) {
    t5 = () => {
      localStorage.setItem(CURRENT_TAB_CHANGED, "");
      const sessionId = getLocalStorageItem(SESSION_ID) || null;
      mixpanelTrack(MIXPANEL_EVENTS.WORKFLOW_SELECTED_FROM_DASHBOARD, {
        selectedWorkflowID: workflow.id,
        selectedWorkflowName: workflow.type,
        selectedWorkflowState: workflow.state,
        selectedWorkflowCreatedAt: workflow.createdAt,
        selectedWorkflowMemberUuid: (workflow.patient?.uuid as string),
        selectedWorkflowPriority: priorityString,
        selectedWorkflowTabName: selectedDashboardFilter.filterTabName,
        timeStamp: Date.now().toString(),
        sessionId
      });
      if (isSimplifiedWorkflowsSplitOn) {
        navigate(memberLayoutUrl(workflow.id, workflow.type, workflow?.patient?.id, {
          isSimplifiedWorkflowsSplitOn
        }));
        return;
      }
      if (acuteSidebarWorkflows.includes((workflow?.type as PtWorkflowTypes)) && !isVideoVisitsWorkflowType(workflow)) {
        navigate(acuteLayoutUrl(workflow.id, workflow.type));
        return;
      }
      let vvGroupType = ("" as AcuteGroupType);
      if (selectedPtDashboardFilter.filterLabel === PtDashboardFilters.VideoVisitUpcoming) {
        vvGroupType = AcuteGroupType.Upcoming;
      } else {
        if (selectedPtDashboardFilter.filterLabel === PtDashboardFilters.VideoVisitIncomplete) {
          vvGroupType = AcuteGroupType.Incomplete;
        }
      }
      if (isVideoVisitsWorkflowType(workflow)) {
        localStorage.setItem("showMemberSidebar", "true");
      }
      navigateToThreePanelMemberView ? navigate(memberLayoutUrl(workflow.id, workflow.type, workflow?.patient?.id, {
        isSimplifiedWorkflowsSplitOn
      })) : navigate(portalSidebarUrl(workflow.id, workflow.type, vvGroupType));
    };
    $[5] = mixpanelTrack;
    $[6] = workflow;
    $[7] = priorityString;
    $[8] = selectedDashboardFilter.filterTabName;
    $[9] = isSimplifiedWorkflowsSplitOn;
    $[10] = navigate;
    $[11] = selectedPtDashboardFilter.filterLabel;
    $[12] = navigateToThreePanelMemberView;
    $[13] = t5;
  } else {
    t5 = $[13];
  }
  const navigateToMemberView = t5;
  let t6;
  if ($[14] !== selectedPtDashboardFilter.filterLabel) {
    t6 = ptCarePlanFilters.includes(selectedPtDashboardFilter.filterLabel);
    $[14] = selectedPtDashboardFilter.filterLabel;
    $[15] = t6;
  } else {
    t6 = $[15];
  }
  const carePlanWorkflow = t6;
  const displayMarkCell = selectedDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME;
  const displayOsMarkCell = selectedOsDashboardFilter.filterTabName === LOW_PRIORITY_COACH_DASHBOARD_TAB_NAME;
  const displayPtMarkCell = selectedPtDashboardFilter.filterTabName === NO_RESPONSE_PREDICTED_TAB_NAME;
  const displayMemberStateCell = currentDashboard === DashboardType.PhysicalTherapist;
  const displayDueCell = !isCompleteWorkflowFilter && !carePlanWorkflow;
  const displayWorkflowUnreadTypeCell = currentDashboard === DashboardType.PhysicalTherapist && selectedPtDashboardFilter.filterLabel === PtDashboardFilters.UnreadMessage;
  let t7;
  if ($[16] !== workflow || $[17] !== carePlanWorkflow || $[18] !== selectedPtDashboardFilter.filterLabel || $[19] !== selectedDashboardFilter.filterLabel || $[20] !== selectedOsDashboardFilter.filterLabel) {
    t7 = () => {
      if (!workflow) {
        return;
      }
      const {
        customFields,
        carePlans
      } = workflow;
      let encounterDate = workflow.createdAt;
      if (customFields?.callDate) {
        encounterDate = customFields?.callDate;
      }
      if (customFields?.scheduledAt) {
        encounterDate = customFields?.scheduledAt;
      }
      if (carePlanWorkflow) {
        if (carePlans?.length && carePlans[0].expires) {
          encounterDate = carePlans[0].expires;
        } else {
          encounterDate = "";
        }
      }
      if (completeWorkflowFilters.includes(selectedPtDashboardFilter.filterLabel) || completeWorkflowFilters.includes(selectedDashboardFilter.filterLabel) || completeWorkflowFilters.includes(selectedOsDashboardFilter.filterLabel)) {
        encounterDate = workflow.lastCompletedAt || "";
      }
      setDisplayEncounterDate(encounterDate);
    };
    $[16] = workflow;
    $[17] = carePlanWorkflow;
    $[18] = selectedPtDashboardFilter.filterLabel;
    $[19] = selectedDashboardFilter.filterLabel;
    $[20] = selectedOsDashboardFilter.filterLabel;
    $[21] = t7;
  } else {
    t7 = $[21];
  }
  let t8;
  if ($[22] !== carePlanWorkflow || $[23] !== workflow || $[24] !== selectedDashboardFilter || $[25] !== selectedPtDashboardFilter || $[26] !== selectedOsDashboardFilter) {
    t8 = [carePlanWorkflow, workflow, selectedDashboardFilter, selectedPtDashboardFilter, selectedOsDashboardFilter];
    $[22] = carePlanWorkflow;
    $[23] = workflow;
    $[24] = selectedDashboardFilter;
    $[25] = selectedPtDashboardFilter;
    $[26] = selectedOsDashboardFilter;
    $[27] = t8;
  } else {
    t8 = $[27];
  }
  useEffect(t7, t8);
  let t9;
  if ($[28] !== setListToMarkComplete || $[29] !== setListOfSelectedWorkflows || $[30] !== workflow.type || $[31] !== setListToMarkPending || $[32] !== setListOfUnselectedWorkflows || $[33] !== allWorkflowFlag || $[34] !== allPtWorkflowFlag) {
    t9 = (checked, workflowId, workflowName_0) => {
      if (checked) {
        setListToMarkComplete(prevList => [...prevList, workflowId]);
        setListOfSelectedWorkflows(prevList_0 => [...prevList_0, {
          id: workflowId,
          name: workflowName_0,
          workflowType: workflow.type
        }]);
        setListToMarkPending(prevList_1 => prevList_1.filter(id => id !== workflowId));
        setListOfUnselectedWorkflows(prevList_2 => [...prevList_2, {
          id: workflowId,
          name: workflowName_0,
          workflowType: workflow.type
        }]);
      } else {
        const shouldAdd = allWorkflowFlag || allPtWorkflowFlag;
        setListOfUnselectedWorkflows(prev => shouldAdd ? [...prev, {
          id: workflowId,
          name: workflowName_0,
          workflowType: workflow.type
        }] : []);
        setListToMarkPending(prev_0 => shouldAdd ? [...prev_0, workflowId] : []);
        setListToMarkComplete(prevList_3 => prevList_3.filter(id_0 => id_0 !== workflowId));
        setListOfSelectedWorkflows(prevList_4 => prevList_4.filter(item => item.id !== workflowId));
      }
    };
    $[28] = setListToMarkComplete;
    $[29] = setListOfSelectedWorkflows;
    $[30] = workflow.type;
    $[31] = setListToMarkPending;
    $[32] = setListOfUnselectedWorkflows;
    $[33] = allWorkflowFlag;
    $[34] = allPtWorkflowFlag;
    $[35] = t9;
  } else {
    t9 = $[35];
  }
  const toggleIndividualWorkflows = t9;
  let t10;
  if ($[36] !== workflow.patient || $[37] !== userInfoPrefetched || $[38] !== getUserInfo) {
    t10 = () => {
      if (workflow.patient?.id && !userInfoPrefetched) {
        getUserInfo({
          variables: {
            uuid: workflow.patient.id
          }
        }).then(() => {
          setUserInfoPrefetched(true);
        });
      }
    };
    $[36] = workflow.patient;
    $[37] = userInfoPrefetched;
    $[38] = getUserInfo;
    $[39] = t10;
  } else {
    t10 = $[39];
  }
  const handlePrefetchUserInfo = t10;
  let t11;
  if ($[40] === Symbol.for("react.memo_cache_sentinel")) {
    t11 = {
      cursor: "pointer",
      borderBottom: "1px solid rgb(214, 214, 208)"
    };
    $[40] = t11;
  } else {
    t11 = $[40];
  }
  const t12 = `workflow-row-${workflow.id}`;
  let t13;
  if ($[41] !== handlePrefetchUserInfo) {
    t13 = () => {
      setDisplayCompleteIcon(true);
      handlePrefetchUserInfo();
    };
    $[41] = handlePrefetchUserInfo;
    $[42] = t13;
  } else {
    t13 = $[42];
  }
  let t14;
  if ($[43] === Symbol.for("react.memo_cache_sentinel")) {
    t14 = () => {
      setDisplayCompleteIcon(false);
    };
    $[43] = t14;
  } else {
    t14 = $[43];
  }
  let t15;
  if ($[44] !== displayMarkCell || $[45] !== displayPtMarkCell || $[46] !== displayOsMarkCell || $[47] !== workflow || $[48] !== toggleIndividualWorkflows || $[49] !== workflowName) {
    t15 = (displayMarkCell || displayPtMarkCell || displayOsMarkCell) && <HHTableCell style={{
      borderBottom: "1px solid #d6d6d0"
    }}><WorkflowBulkMark workflowId={workflow.id} markType="body" onSelect={toggleIndividualWorkflows} workflowName={workflowName} workflowCreatedAt={workflow.createdAt} workflowState={workflow.state} workflowMemberUuid={workflow.patient?.uuid} /></HHTableCell>;
    $[44] = displayMarkCell;
    $[45] = displayPtMarkCell;
    $[46] = displayOsMarkCell;
    $[47] = workflow;
    $[48] = toggleIndividualWorkflows;
    $[49] = workflowName;
    $[50] = t15;
  } else {
    t15 = $[50];
  }
  let t16;
  if ($[51] !== workflow) {
    t16 = <WorkflowTypeCell workflow={workflow} />;
    $[51] = workflow;
    $[52] = t16;
  } else {
    t16 = $[52];
  }
  let t17;
  if ($[53] !== displayWorkflowUnreadTypeCell || $[54] !== workflow) {
    t17 = displayWorkflowUnreadTypeCell && <WorkflowUnreadTypeCell workflow={workflow} />;
    $[53] = displayWorkflowUnreadTypeCell;
    $[54] = workflow;
    $[55] = t17;
  } else {
    t17 = $[55];
  }
  let t18;
  if ($[56] !== workflow) {
    t18 = <WorkflowMemberCell workflow={workflow} />;
    $[56] = workflow;
    $[57] = t18;
  } else {
    t18 = $[57];
  }
  let t19;
  if ($[58] !== displayMemberStateCell || $[59] !== workflow) {
    t19 = displayMemberStateCell && <WorkflowMemberStateCell workflow={workflow} />;
    $[58] = displayMemberStateCell;
    $[59] = workflow;
    $[60] = t19;
  } else {
    t19 = $[60];
  }
  let t20;
  if ($[61] !== displayEncounterDate) {
    t20 = <WorkflowDateCell date={displayEncounterDate} />;
    $[61] = displayEncounterDate;
    $[62] = t20;
  } else {
    t20 = $[62];
  }
  let t21;
  if ($[63] !== displayDueCell || $[64] !== workflow) {
    t21 = displayDueCell && <WorkflowDueCell workflow={workflow} />;
    $[63] = displayDueCell;
    $[64] = workflow;
    $[65] = t21;
  } else {
    t21 = $[65];
  }
  let t22;
  if ($[66] !== displayMarkCell || $[67] !== displayPtMarkCell || $[68] !== displayOsMarkCell || $[69] !== workflow || $[70] !== displayCompleteIcon) {
    t22 = (displayMarkCell || displayPtMarkCell || displayOsMarkCell) && <WorkflowMarkCell workflow={workflow} displayCompleteIcon={displayCompleteIcon} />;
    $[66] = displayMarkCell;
    $[67] = displayPtMarkCell;
    $[68] = displayOsMarkCell;
    $[69] = workflow;
    $[70] = displayCompleteIcon;
    $[71] = t22;
  } else {
    t22 = $[71];
  }
  let t23;
  if ($[72] !== navigateToMemberView || $[73] !== t12 || $[74] !== t13 || $[75] !== t15 || $[76] !== t16 || $[77] !== t17 || $[78] !== t18 || $[79] !== t19 || $[80] !== t20 || $[81] !== t21 || $[82] !== t22) {
    t23 = <HHTableRow onClick={navigateToMemberView} sx={t11} hover={true} data-testid={t12} onMouseOver={t13} onMouseLeave={t14}>{t15}{t16}{t17}{t18}{t19}{t20}{t21}{t22}</HHTableRow>;
    $[72] = navigateToMemberView;
    $[73] = t12;
    $[74] = t13;
    $[75] = t15;
    $[76] = t16;
    $[77] = t17;
    $[78] = t18;
    $[79] = t19;
    $[80] = t20;
    $[81] = t21;
    $[82] = t22;
    $[83] = t23;
  } else {
    t23 = $[83];
  }
  return t23;
};