import { SseEvents } from './constants/strings/events';
export enum CoachWorkflowTypes {
  FirstActionPlan = 'first-action-plan',
  UnreadMessages = 'unread-messages',
  MemberAlertReminder = 'member-alert-reminder',
  ActionPlanReview = 'action-plan-review',
  FirstWeekGoal = 'first-week-goal',
  PregnancyStatusChange = 'pregnancy-status-change',
  OfferVideoVisit = 'offer-video-visit',
  MemberHealthLog = 'member-health-log',
  MemberPainUptick = 'member-pain-uptick',
  MaternityMilestones = 'maternity-milestones',
  ActionPlanCheckIn = 'action-plan-check-in',
  FirstWeekCheckIn = 'first-week-check-in',
  CoachCall = 'coach-call',
  ReEngager = 'reengager',
  BalanceAssessment = 'balance-assessment',
  CoachEscalation = 'coach-escalation',
  WeeklyEnsoReview = 'weekly-enso-review',
  OutreachNeeded = 'outreach-needed',
  // this is a back-end name 'coach-weekly-goal-expiry' in UI it will be mapped to 'Review plan'
  CoachWeeklyGoalExpiry = 'coach-weekly-goal-expiry',
  // this is a back-end name 'coach-member-updated-weekly-goal' in UI it will be mapped to 'Member updated plan'
  CoachMemberUpdatedWeeklyGoal = 'coach-member-updated-weekly-goal',
  CoachVideoVisit = 'coach-video-visit',
  OrthoCoachEscalation = 'ortho-coach-escalation',
} // When a split protected coach workflow is added, make sure to conditionally add it to categoriesToSkip in side-navigation.tsx
export enum PtWorkflowTypes {
  UnreadMessages = 'pt-unread-messages',
  HealthLog = 'acute-health-log',
  PainUptick = 'acute-pain-uptick',
  MemberPainUptick = 'member-pain-uptick',
  Acute = 'acute-video-visits',
  Chronic = 'chronic-video-visit',
  Surgery = 'surgery-video-visit',
  FifteenMinute = '15-min-video-visits',
  Expiring = 'acute-expiring-care-plan',
  Closing = 'acute-care-plan-to-close',
  SurgeryTrend = 'surgery-trend',
  HouseCall = 'house-call',
  EtModification = 'et-modification',
  ClinicalEscalation = 'clinical-escalation',
  OrthoClinicalEscalation = 'ortho-clinical-escalation',
  MedicalEmergencyFollowUp = 'medical-emergency-follow-up',
  FirstWeekEnsoCheckIn = 'enso-first-week-checkin',
  AqRedFlags = 'aq-red-flags',
  PtMemberAlertReminder = 'pt-member-alert-reminder',
  ActionPlanReview = 'pt-action-plan-review',
  SetupActionPlan = 'setup-action-plan',
  ModularIamUnreadMessages = 'modular-iam-unread-messages',
  IndicationUpdate = 'indication-update',
  PelvicTrainerOutreach = 'pelvic-trainer-outreach',
} // When a split protected pt workflow is added, make sure to conditionally add it to categoriesToSkip in side-navigation.tsx
export enum OsWorkflowTypes {
  UnreadMessages = 'os-unread-messages',
  Nuto = 'new-user-to-onboard',
  Ruto = 'returning-user-to-onboard',
  MemberAlertReminder = 'os-member-alert-reminder',
  OutreachNeeded = 'os-outreach-needed',
  ReEngager = 'os-reengager',
} // When a split protected os workflow is added, make sure to conditionally add it to categoriesToSkip in side-navigation.tsx
export type WorkflowTypes = PtWorkflowTypes | CoachWorkflowTypes | OsWorkflowTypes;
export type CoachOrOsWorkflowTypes = CoachWorkflowTypes | OsWorkflowTypes;
export enum UnreadMessagesWorkflowTypes {
  CareTeamMessage = 'group-messaging',
  InAppMessage = 'in-app',
  Sms = 'sms',
}
export enum PtWorkflowCategories {
  UnreadMessages = 'pt-unread-messages',
  VideoVisitsWorkflowGroup = 'video-visits-workflow-group',
  HealthLog = 'acute-health-log',
  PainUptick = 'acute-pain-uptick',
  CarePlanGroup = 'acute-care-plan-group',
  ClinicalEscalation = 'clinical-escalation',
  PtMemberAlertReminder = 'pt-member-alert-reminder',
  ActionPlans = 'action-plans',
}
export enum CoachWorkflowState {
  AwaitingNew = 'awaiting-new',
  AwaitingPlan = 'awaiting-plan',
  NoPlan = 'no-plan',
  NewPlan = 'new-plan',
  New = 'new',
  PendingCreation = 'action-plan-pending-creation',
  PendingCreationImmediate = 'action-plan-pending-creation-immediate',
  PendingReview = 'action-plan-pending-review',
  AdminCreatedPlan = 'admin-created-action-plan',
  MemberUpdatedPlan = 'member-updated-action-plan',
  PregnancyStatusChange = 'pregnancy-status-change',
  MaternityMilestones = 'maternity-milestones',
  NeedsVideoVisitOffer = 'needs-video-visit-offer',
  NeedsResponse = 'needs-response',
  OutreachSentToMember = 'outreach-sent-to-member',
  Dismissed = 'dismissed',
  Pending = 'pending',
  Complete = 'complete',
  VideoVisitAlreadyScheduledOrComplete = 'video-visit-already-scheduled-or-complete',
  NewHealthLogNote = 'new-health-log-note',
  NewPainUptick = 'new-pain-uptick',
  Active = 'active',
  ReviewActionPlanFwg = 'review-action-plan-fwg',
  MemberCreatedNextActionPlanPostFwg = 'member-created-next-action-plan-post-fwg',
  Closed = 'closed',
  Open = 'open',
}
export const workflowsWithSubtitles = [CoachWorkflowTypes.UnreadMessages, PtWorkflowTypes.UnreadMessages, CoachWorkflowTypes.MemberHealthLog, CoachWorkflowTypes.ReEngager, CoachWorkflowTypes.MemberPainUptick, PtWorkflowTypes.PainUptick, CoachWorkflowTypes.ActionPlanCheckIn, CoachWorkflowTypes.BalanceAssessment, CoachWorkflowTypes.CoachEscalation, PtWorkflowTypes.FirstWeekEnsoCheckIn, CoachWorkflowTypes.WeeklyEnsoReview, PtWorkflowTypes.HealthLog, PtWorkflowTypes.ClinicalEscalation, CoachWorkflowTypes.OutreachNeeded, PtWorkflowTypes.EtModification, PtWorkflowTypes.MedicalEmergencyFollowUp, PtWorkflowTypes.SurgeryTrend, PtWorkflowTypes.SetupActionPlan, PtWorkflowTypes.ModularIamUnreadMessages, CoachWorkflowTypes.MemberAlertReminder, PtWorkflowTypes.PtMemberAlertReminder, PtWorkflowTypes.IndicationUpdate, OsWorkflowTypes.UnreadMessages, OsWorkflowTypes.MemberAlertReminder, OsWorkflowTypes.OutreachNeeded];
export const sidebarWorkflowsWithSubtitles = [PtWorkflowTypes.Acute, PtWorkflowTypes.Chronic, PtWorkflowTypes.FifteenMinute, PtWorkflowTypes.Surgery, PtWorkflowTypes.HouseCall, CoachWorkflowTypes.CoachVideoVisit];
export const threePanelLayoutWorkflows: string[] = [...workflowsWithSubtitles, CoachWorkflowTypes.FirstWeekCheckIn, PtWorkflowTypes.UnreadMessages, PtWorkflowTypes.PainUptick, PtWorkflowTypes.HealthLog, CoachWorkflowTypes.ReEngager, CoachWorkflowTypes.BalanceAssessment, CoachWorkflowTypes.MemberAlertReminder, PtWorkflowTypes.AqRedFlags, PtWorkflowTypes.PtMemberAlertReminder, PtWorkflowTypes.ModularIamUnreadMessages, CoachWorkflowTypes.CoachWeeklyGoalExpiry, PtWorkflowTypes.Closing, PtWorkflowTypes.Expiring, CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal, OsWorkflowTypes.UnreadMessages, OsWorkflowTypes.MemberAlertReminder, OsWorkflowTypes.OutreachNeeded, OsWorkflowTypes.ReEngager, OsWorkflowTypes.Nuto, OsWorkflowTypes.Ruto, PtWorkflowTypes.PelvicTrainerOutreach, PtWorkflowTypes.OrthoClinicalEscalation, CoachWorkflowTypes.OrthoCoachEscalation];
export const carePlanWorkflowTypes: PtWorkflowTypes[] = [PtWorkflowTypes.Closing, PtWorkflowTypes.Expiring];
export const videoVisitWorkflowTypes: PtWorkflowTypes[] = [PtWorkflowTypes.Acute, PtWorkflowTypes.Chronic, PtWorkflowTypes.Surgery, PtWorkflowTypes.FifteenMinute];
export const acuteSidebarWorkflows: PtWorkflowTypes[] = [PtWorkflowTypes.HouseCall, ...videoVisitWorkflowTypes];
export const careCoordinationWorkflows: Array<PtWorkflowTypes | CoachWorkflowTypes> = [PtWorkflowTypes.EtModification, PtWorkflowTypes.ClinicalEscalation, PtWorkflowTypes.MedicalEmergencyFollowUp, CoachWorkflowTypes.CoachEscalation];
export enum CommonCoachState {
  CoachComplete = 'complete',
  CoachDismiss = 'dismiss',
  CoachAwaitingNew = 'awaiting-new',
  CoachAwaitingPlan = 'awaiting-plan',
  CoachArchive = 'archive',
  CoachClosed = 'closed',
  CoachOutreachSentToMember = 'outreach-sent-to-member',
  CoachDismissed = 'dismissed',
  CoachPending = 'pending',
  CoachVideoVisitAlreadyScheduledOrComplete = 'video-visit-already-scheduled-or-complete',
  Active = 'active',
  Closed = 'closed',
  CoachUserTransitionedToNewProgram = 'user-transitioned-to-new-program',
  AwaitingMessage = 'awaiting-message',
  AwaitingTag = 'awaiting-tag',
  PostFwg = 'post-fwg',
  CompleteVideoVisit = 'complete-video-visit',
  PerformVideoVisitFollowup = 'perform-video-visit-followup',
  CancelVideoVisit = 'cancel-video-visit',
  AcuteComplete = 'acute-complete',
  EnsoAssignedToWorkflow = 'assigned-to-workflow',
  EnsoOrdered = 'enso-ordered',
}
export enum QueueIconTypes {
  Overdue = 'overdue',
  DueSoon = 'due-soon',
  Total = 'total',
  Event = 'event',
}
export const queueSummaryCategories: QueueIconTypes[] = [QueueIconTypes.Overdue, QueueIconTypes.DueSoon, QueueIconTypes.Total];

/*
1. The order of the below elements in coachQueueWorkflowCategories should match the order of the elements in
listOfCoachWorkflowCategories in src/state/queue-atoms.ts
and COACH_CATEGORY_NAME_ARRAY_INDEX in src/constants/strings/workflows-queue.ts.
2. The order of the elements in listOfCoachWorkflowCategories in src/state/queue-atoms.ts
is used to determine order the queue categories in the Queue panel.
3. Since COACH_CATEGORY_NAME_ARRAY_INDEX is an array,
the labels are accessed based on index values generated using COACH_CATEGORY_NAME_ARRAY_INDEX.
Ex: Line 234 on src/components/workflows-queue-components/queue-accordion.tsx
4. The order of the elements in COACH_CATEGORY_NAME_ARRAY_INDEX and coachQueueWorkflowCategories below
are used to determine tooltip text on Next button.
*/

export const coachQueueWorkflowCategories = [[CoachWorkflowTypes.CoachVideoVisit], [CoachWorkflowTypes.UnreadMessages], [CoachWorkflowTypes.MemberPainUptick], [CoachWorkflowTypes.MemberHealthLog], [CoachWorkflowTypes.ReEngager], [CoachWorkflowTypes.FirstWeekGoal], [CoachWorkflowTypes.ActionPlanReview], [CoachWorkflowTypes.ActionPlanCheckIn], [CoachWorkflowTypes.FirstWeekCheckIn], [CoachWorkflowTypes.MemberAlertReminder], [CoachWorkflowTypes.CoachEscalation], [CoachWorkflowTypes.WeeklyEnsoReview], [CoachWorkflowTypes.OutreachNeeded], [CoachWorkflowTypes.CoachWeeklyGoalExpiry], [CoachWorkflowTypes.MaternityMilestones], [CoachWorkflowTypes.PregnancyStatusChange], [CoachWorkflowTypes.BalanceAssessment], [CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal], [CoachWorkflowTypes.CoachCall]];

/*
1. The order of the below elements in ptQueueWorkflowCategories should match the order of the elements in
listOfPtWorkflowCategories in src/state/queue-atoms.ts
and PT_CATEGORY_NAME_ARRAY_INDEX in src/constants/strings/workflows-queue.ts.
2. The order of the elements in listOfPtWorkflowCategories in src/state/queue-atoms.ts
is used to determine order the queue categories in the Queue panel.
3. Since PT_CATEGORY_NAME_ARRAY_INDEX is an array,
the labels are accessed based on index values generated using PT_CATEGORY_NAME_ARRAY_INDEX.
Ex: Line 234 on src/components/workflows-queue-components/queue-accordion.tsx
4. The order of the elements in PT_CATEGORY_NAME_ARRAY_INDEX and ptQueueWorkflowCategories below
are used to determine tooltip text on Next button.
*/

export const ptQueueWorkflowCategories = [[PtWorkflowTypes.Acute, PtWorkflowTypes.Surgery, PtWorkflowTypes.FifteenMinute, PtWorkflowTypes.Chronic], [PtWorkflowTypes.UnreadMessages, PtWorkflowTypes.ModularIamUnreadMessages], [PtWorkflowTypes.HealthLog], [PtWorkflowTypes.PainUptick, PtWorkflowTypes.MemberPainUptick], [PtWorkflowTypes.SurgeryTrend], [PtWorkflowTypes.ClinicalEscalation], [PtWorkflowTypes.EtModification], [PtWorkflowTypes.IndicationUpdate], [PtWorkflowTypes.MedicalEmergencyFollowUp], [PtWorkflowTypes.SetupActionPlan], [PtWorkflowTypes.ActionPlanReview], [PtWorkflowTypes.Expiring], [PtWorkflowTypes.Closing], [PtWorkflowTypes.AqRedFlags], [PtWorkflowTypes.FirstWeekEnsoCheckIn], [PtWorkflowTypes.PelvicTrainerOutreach], [PtWorkflowTypes.PtMemberAlertReminder], [PtWorkflowTypes.Chronic, PtWorkflowTypes.Acute, PtWorkflowTypes.Surgery, PtWorkflowTypes.FifteenMinute], [PtWorkflowTypes.HouseCall]];
export const osQueueWorkflowCategories = [[OsWorkflowTypes.UnreadMessages], [OsWorkflowTypes.MemberAlertReminder], [OsWorkflowTypes.ReEngager], [OsWorkflowTypes.OutreachNeeded], [OsWorkflowTypes.Nuto], [OsWorkflowTypes.Ruto]];
export const coachQueueWorkflowCategoriesWithoutHealthLogPainUpticks = [[CoachWorkflowTypes.UnreadMessages], [CoachWorkflowTypes.ReEngager], [CoachWorkflowTypes.CoachEscalation], [CoachWorkflowTypes.MemberAlertReminder]];
export const commonCoachExcludedStates: CommonCoachState[] = [CommonCoachState.CoachComplete, CommonCoachState.CoachDismiss, CommonCoachState.CoachAwaitingNew, CommonCoachState.CoachAwaitingPlan, CommonCoachState.CoachArchive, CommonCoachState.CoachClosed, CommonCoachState.CoachOutreachSentToMember, CommonCoachState.CoachDismissed, CommonCoachState.CoachUserTransitionedToNewProgram, CommonCoachState.CoachPending, CommonCoachState.CoachVideoVisitAlreadyScheduledOrComplete, CommonCoachState.AwaitingMessage, CommonCoachState.AwaitingTag, CommonCoachState.PostFwg, CommonCoachState.CancelVideoVisit, CommonCoachState.EnsoAssignedToWorkflow, CommonCoachState.EnsoOrdered, CommonCoachState.AcuteComplete];
export const commonCoachCompletedStates: CommonCoachState[] = [CommonCoachState.CoachComplete, CommonCoachState.CoachDismiss, CommonCoachState.CoachAwaitingPlan, CommonCoachState.CoachClosed, CommonCoachState.CoachOutreachSentToMember, CommonCoachState.CoachDismissed, CommonCoachState.CoachUserTransitionedToNewProgram, CommonCoachState.CoachPending, CommonCoachState.CoachVideoVisitAlreadyScheduledOrComplete, CommonCoachState.Closed, CommonCoachState.CancelVideoVisit, CommonCoachState.AcuteComplete];
export const commonCompletedStatesInteractionTab: CommonCoachState[] = [CommonCoachState.CoachComplete, CommonCoachState.CoachAwaitingPlan, CommonCoachState.CoachClosed, CommonCoachState.CoachOutreachSentToMember, CommonCoachState.CoachUserTransitionedToNewProgram, CommonCoachState.CoachVideoVisitAlreadyScheduledOrComplete, CommonCoachState.Closed, CommonCoachState.CancelVideoVisit, CommonCoachState.AcuteComplete];
export interface CoachSseData {
  type: SseEvents;
  workflowId: number;
  serviceName: CoachWorkflowTypes | OsWorkflowTypes;
  stateName: CommonCoachState;
  latestTaskDate?: string | null;
}
export const coachQueueHealthLogPainUptickWorkflowTypes = [CoachWorkflowTypes.MemberHealthLog, CoachWorkflowTypes.MemberPainUptick];
export const coachQueueHealthLogkWorkflowTypes = [CoachWorkflowTypes.MemberHealthLog];
export const baseCoachQueueWorkflowTypes = [CoachWorkflowTypes.UnreadMessages, CoachWorkflowTypes.FirstActionPlan, CoachWorkflowTypes.ActionPlanReview, CoachWorkflowTypes.FirstWeekGoal, CoachWorkflowTypes.MemberAlertReminder, CoachWorkflowTypes.PregnancyStatusChange, CoachWorkflowTypes.MaternityMilestones, CoachWorkflowTypes.BalanceAssessment, CoachWorkflowTypes.WeeklyEnsoReview, CoachWorkflowTypes.OutreachNeeded, CoachWorkflowTypes.CoachEscalation, CoachWorkflowTypes.MemberHealthLog, CoachWorkflowTypes.ActionPlanCheckIn, CoachWorkflowTypes.FirstWeekCheckIn, CoachWorkflowTypes.ReEngager, CoachWorkflowTypes.CoachCall];
export enum QueueCategoryType {
  Workflow = 'workflow',
  Encounter = 'encounter',
}
export const QueueCategoryTypeIndex = {
  [QueueCategoryType.Workflow]: 0,
  [QueueCategoryType.Encounter]: 1
};
export const encounterWorkflowTypes: Array<CoachWorkflowTypes | PtWorkflowTypes | OsWorkflowTypes> = [CoachWorkflowTypes.CoachCall, PtWorkflowTypes.Chronic, PtWorkflowTypes.Acute, PtWorkflowTypes.Surgery, PtWorkflowTypes.FifteenMinute, PtWorkflowTypes.HouseCall];
export const PT_VIDEO_VISIT_LABEL = 'Video visit';
export const IamMessageSource = {
  HLN: 'HLN'
};
export const workflowsWithoutCarePlanTitle = [CoachWorkflowTypes.CoachWeeklyGoalExpiry, CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal];
export const workflowsWithoutWeeklyEts = [CoachWorkflowTypes.CoachWeeklyGoalExpiry, CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal];
export type WorkflowCategoryTypes = CoachWorkflowTypes[][] | PtWorkflowTypes[][] | OsWorkflowTypes[][];
export const unreadMessagesWorkflowsForPtAndCoach: string[] = [CoachWorkflowTypes.UnreadMessages, PtWorkflowTypes.UnreadMessages, PtWorkflowTypes.ModularIamUnreadMessages];