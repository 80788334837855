import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox, HHStack, HHTypography } from '@hinge-health/react-component-library';
import { KeyboardArrowRightRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants';
export interface Member {
  id: string;
  name: string;
  nextAction: string;
  isActive: boolean;
}
const styles = {
  card: {
    borderRadius: 2,
    padding: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    flexDirection: 'row',
    gap: '4px',
    '&:hover': {
      '& .arrow-icon': {
        opacity: 1
      }
    }
  },
  active: {
    card: {
      backgroundColor: ctpTokens.palette.green['30']
    },
    memberName: {
      color: ctpTokens.palette.neutral['100']
    },
    nextAction: {
      color: ctpTokens.palette.neutral['80']
    },
    arrowIcon: {
      backgroundColor: ctpTokens.palette.neutral['100']
    }
  },
  inactive: {
    card: {
      backgroundColor: ctpTokens.palette.sand.shades['8p']
    },
    memberName: {
      color: ctpTokens.palette.neutral['10']
    },
    nextAction: {
      color: ctpTokens.palette.neutral['40']
    },
    arrowIcon: {
      backgroundColor: ctpTokens.palette.sand[80]
    }
  }
};
export const MemberCard = t0 => {
  const $ = _c(25);
  const {
    member
  } = t0;
  const sx = member.isActive ? styles.active : styles.inactive;
  const navigate = useNavigate();
  let t1;
  if ($[0] !== navigate || $[1] !== member.id) {
    t1 = () => {
      navigate(`${ROUTES.MEMBER}/${member.id}/profile`);
    };
    $[0] = navigate;
    $[1] = member.id;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  const handleClick = t1;
  let t2;
  if ($[3] !== sx.card) {
    t2 = {
      ...sx.card,
      ...styles.card
    };
    $[3] = sx.card;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  let t3;
  if ($[5] !== sx.memberName || $[6] !== member.name) {
    t3 = <HHTypography hhVariant="variant-bypass" fontSize="16px" fontWeight="500" color={sx.memberName}>{member.name}</HHTypography>;
    $[5] = sx.memberName;
    $[6] = member.name;
    $[7] = t3;
  } else {
    t3 = $[7];
  }
  let t4;
  if ($[8] !== sx.nextAction || $[9] !== member.nextAction) {
    t4 = <HHTypography hhVariant="variant-bypass" fontSize="14px" fontWeight="400" color={sx.nextAction}>{member.nextAction}</HHTypography>;
    $[8] = sx.nextAction;
    $[9] = member.nextAction;
    $[10] = t4;
  } else {
    t4 = $[10];
  }
  let t5;
  if ($[11] !== t3 || $[12] !== t4) {
    t5 = <HHStack gap="4px" flexGrow={1}>{t3}{t4}</HHStack>;
    $[11] = t3;
    $[12] = t4;
    $[13] = t5;
  } else {
    t5 = $[13];
  }
  const t6 = Number(member.isActive);
  let t7;
  if ($[14] !== sx.arrowIcon.backgroundColor || $[15] !== t6) {
    t7 = {
      backgroundColor: sx.arrowIcon.backgroundColor,
      borderRadius: "50%",
      height: "24px",
      width: "24px",
      opacity: t6
    };
    $[14] = sx.arrowIcon.backgroundColor;
    $[15] = t6;
    $[16] = t7;
  } else {
    t7 = $[16];
  }
  let t8;
  if ($[17] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = <KeyboardArrowRightRounded />;
    $[17] = t8;
  } else {
    t8 = $[17];
  }
  let t9;
  if ($[18] !== t7) {
    t9 = <HHStack><HHBox className="arrow-icon" sx={t7}>{t8}</HHBox></HHStack>;
    $[18] = t7;
    $[19] = t9;
  } else {
    t9 = $[19];
  }
  let t10;
  if ($[20] !== t2 || $[21] !== handleClick || $[22] !== t5 || $[23] !== t9) {
    t10 = <HHStack role="button" sx={t2} onClick={handleClick}>{t5}{t9}</HHStack>;
    $[20] = t2;
    $[21] = handleClick;
    $[22] = t5;
    $[23] = t9;
    $[24] = t10;
  } else {
    t10 = $[24];
  }
  return t10;
};