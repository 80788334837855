// Alert reminder workflow can move from complete to archive.
// This transition should not be accounted for in case of incomplete workflows, as transition to complete is already accounted for.

import { UNACCOUNTED_TRANSITIONS } from '../constants/strings/workflows-queue';
import { WorkflowTransitionedSseData } from '../contexts/sse.context';
import { CoachSseData, CommonCoachState } from '../custom-types';

// Also, since its moving from complete to archive, decrement the complete count.
export const isUnaccountedTransition = (workflow: WorkflowTransitionedSseData): boolean => UNACCOUNTED_TRANSITIONS.includes(workflow?.transitionName);

// Archive is a passive state, and should not be considered in incomplete / complete workflows.
export const isArchiveState = (workflow: CoachSseData): boolean => {
  const stateName = (workflow?.stateName as string);
  return stateName === CommonCoachState.CoachArchive;
};