import { MenuOptions } from '@hinge-health/react-component-library/dist/components/molecules/hh-layout-panel';
import { CoachWorkflowTypes, PtWorkflowTypes } from '../../../../../../custom-types';
import { WORKFLOW_PANEL_TITLES } from '../../../../constants/strings/workflow';
import { BalanceAverageCategories } from '../../../../custom-types';
import { ClinicalEscalationForm } from '../create-workflow-forms/clinical-escalation-form';
import { CoachEscalationForm } from '../create-workflow-forms/coach-escalation-form';
import { ETModificationForm } from '../create-workflow-forms/et-modification-form';
import { MedicalEmergencyForm } from '../create-workflow-forms/medical-emergency-form';
export const TEST_IDS = {
  CLINICAL_TEMPLATE_PANEL: 'clinical-template-workflow-panel',
  RECORD_PANEL: 'records-panel',
  CLINICAL_TEMPLATE: 'clinical-template',
  PANEL_HEADING: 'clinical-panel-heading',
  PANEL_LINKS: 'clinical-panel-links',
  PANEL_INSIGHTS: 'clinical-panel-insights',
  PANEL_TASKS: 'clinical-panel-tasks',
  CIRCULAR_PROGRESS: 'circular-progress',
  ERROR_CONTAINER: 'error-container',
  BALANCE_INSIGHTS: 'balance-insights'
};
export const WORKFLOW_HEADER_TEXT = {
  DUE_DATE: 'Due date',
  ASSIGNEE: 'Assignee',
  BODY_PART: 'Body part',
  WORKFLOW_HEADER_NOT_FOUND: 'Workflow details could not be found',
  WORKFLOW_ASSIGNEE_NOT_FOUND: 'Assignee can not be found',
  WORKFLOW_UNASSIGNED: 'Unassigned',
  ERROR_FETCHING_BODY_PARTS: '-',
  TRY_REFRESHING: 'Try refreshing the page',
  PLAN_EXPIRATION: 'Plan expiration'
};
export const WORKFLOW_PANEL_TEXT = {
  STATUS_COULD_NOT_BE_SELECTED: 'The status could not be selected',
  OPEN: 'Open',
  COMPLETE: 'Complete',
  DONE: 'Done',
  DISMISS_WORKFLOW: 'Dismiss workflow',
  ADDITIONAL_RESOURCES: 'Additional resources',
  INSIGHTS: 'Insights',
  PAIN_SCORE: 'Pain score',
  MEMBER_NOTE: 'Member note',
  PAIN_HISTORY: 'Pain history',
  ACTIONS: 'Actions',
  BALANCE_HISTORY: 'Balance history',
  RESPONSE_TO_MEMBER: 'Response to member',
  FIVE_STAGE_BALANCE: '5-stage Balance',
  SIT_TO_STANDS: 'Sit to stands',
  ERROR: 'Unable to load data',
  FLAGS_TRIGGERED: 'Flags triggered',
  VIDEO_VISIT: 'Video visit',
  PREVIOUS_INDICATION: 'Previous indication',
  PELVIC_TRAINER_ORDERED: 'Ordered',
  OUTREACH_NEEDED: 'Outreach needed'
};
export const TASK_STATES = {
  COMPLETED: 'completed'
};
export const ACTION_PLAN_INSIGHTS_LABELS = {
  PROGRESS: 'Progress',
  ACTION_PLAN: 'Action plan #',
  WEEK: 'Week',
  WEEK_ETS_COMPLETED: 'Weekly ETs',
  CARE_PLAN: 'Care plan',
  ACTION_PLAN_WEEKS_COMPLETED: 'Action plan weeks completed',
  OF: 'of',
  ETS: 'ETs',
  VIEW_MORE: 'View More',
  WEEK_1: 'Week 1',
  REVIEW_WEEK: 'Review week',
  NO_ET_DATA_AVAILABLE: 'No ET data available in plan review week'
};
export const CARE_COORDINATION_WORKFLOW_INSIGHTS_LABEL = {
  TYPE: 'Type',
  ADDITIONAL_CONTEXT: 'Additional context',
  REASON_FOR_ESCALATION: 'Reason for escalation',
  DESCRIPTION: 'Description',
  BODY_PART: 'Body part'
};
export const SURGERY_TREND_INSIGHTS_FIELDS_LABEL = {
  MEMBER_SURVEY: 'Member survey',
  SURGERY_LIKELIHOOD: 'Surgery likelihood',
  HOW_DO_YOU_FEEL_ABOUT_SURGERY: 'How do you feel about surgery?',
  UPCOMING_SURGERY: 'Upcoming surgery',
  CHANGE_FROM_BASELINE: 'change from baseline',
  OTHER: 'Other',
  NONE: 'None',
  NOT_SCHEDULED: 'Not scheduled'
};
export const ENSO_WORKFLOWS_INSIGHTS_LABEL = {
  MEMBER_HAS_NOT_DONE_ANY_SESSION_IN_TWO_WEEKS: 'Member has not done any session in two weeks',
  SESSION_COMPLETED: 'session completed',
  SESSIONS_COMPLETED: 'sessions completed',
  ENSO_SNAPSHOT: 'Enso snapshot',
  LAST_SESSION: 'Last session',
  LAST_WEEK: 'Last week',
  THIS_WEEK: 'This week',
  ENSO_PROGRESS: 'Enso progress',
  WEEK: 'Week',
  NO_ENSO_DATA_TO_DISPLAY: 'No Enso data to display'
};
export const OUTREACH_NEEDED_WORKFLOW_INSIGHTS_LABEL = {
  LAST_OUTREACH: 'Last Outreach',
  OUTREACH_CHANNEL: 'Outreach Channel',
  NO_OUTREACH: 'No outreach sent to this member'
};
export const SURGERY_TREND_DATE_FORMAT = 'M/D/YYYY';
export const BALANCE_AVERAGES: Record<BalanceAverageCategories, string> = {
  [BalanceAverageCategories.BelowAverage]: 'below average',
  [BalanceAverageCategories.Average]: 'average',
  [BalanceAverageCategories.AboveAverage]: 'above average'
};
const NEW_WORKFLOW_TAB_LABEL = 'New workflow';
export const COACH_CREATE_WORKFLOW_OPTIONS: MenuOptions[] = [{
  label: WORKFLOW_PANEL_TITLES[PtWorkflowTypes.EtModification],
  createTabLabel: NEW_WORKFLOW_TAB_LABEL,
  value: PtWorkflowTypes.EtModification,
  tabContent: <ETModificationForm />
}, {
  label: WORKFLOW_PANEL_TITLES[PtWorkflowTypes.ClinicalEscalation],
  createTabLabel: NEW_WORKFLOW_TAB_LABEL,
  value: PtWorkflowTypes.ClinicalEscalation,
  tabContent: <ClinicalEscalationForm />
}, {
  label: WORKFLOW_PANEL_TITLES[PtWorkflowTypes.MedicalEmergencyFollowUp],
  createTabLabel: NEW_WORKFLOW_TAB_LABEL,
  value: PtWorkflowTypes.MedicalEmergencyFollowUp,
  tabContent: <MedicalEmergencyForm />
}];
export const ORTHO_CREATE_WORKFLOW_OPTIONS: MenuOptions[] = [{
  label: WORKFLOW_PANEL_TITLES[PtWorkflowTypes.OrthoClinicalEscalation],
  createTabLabel: NEW_WORKFLOW_TAB_LABEL,
  value: PtWorkflowTypes.OrthoClinicalEscalation,
  tabContent: <ClinicalEscalationForm isOrtho />
}, {
  label: WORKFLOW_PANEL_TITLES[CoachWorkflowTypes.OrthoCoachEscalation],
  createTabLabel: NEW_WORKFLOW_TAB_LABEL,
  value: CoachWorkflowTypes.OrthoCoachEscalation,
  tabContent: <CoachEscalationForm isOrtho />
}];
export const PT_CREATE_WORKFLOW_OPTIONS: MenuOptions[] = [{
  label: WORKFLOW_PANEL_TITLES[CoachWorkflowTypes.CoachEscalation],
  createTabLabel: NEW_WORKFLOW_TAB_LABEL,
  value: CoachWorkflowTypes.CoachEscalation,
  tabContent: <CoachEscalationForm />
}];
export const OS_CREATE_WORKFLOW_OPTIONS: MenuOptions[] = [...PT_CREATE_WORKFLOW_OPTIONS, ...COACH_CREATE_WORKFLOW_OPTIONS];
export const INDICATION = {
  MULTI_SHIM: 'multi_shim'
};
export const PELVIC_TRAINER_WORKFLOW_INSIGHTS_LABEL = {
  LAST_4_WEEKS: 'Sessions (last 4 weeks)',
  SESSION_COMPLETED: 'session completed',
  SESSIONS_COMPLETED: 'sessions completed',
  PELVIC_TRAINER_SNAPSHOT: 'Pelvic trainer snapshot',
  LAST_SESSION: 'Last session',
  CONTRACTION_QUALITY: 'Contraction quality of last session',
  NO_PELVIC_TRAINER_DATA_TO_DISPLAY: 'No pelvic trainer data to display'
};