import { CoachWorkflowTypes, PtWorkflowTypes } from '../../custom-types';
import { OktaRole } from '../../types/okta-roles';
export const MPE_TEST_ID = {
  MPE_DASHBOARD: 'mpe-dashboard'
};
export const APPOINTMENT_WORKFLOW_TYPES = {
  [OktaRole.PhysicalTherapist]: [PtWorkflowTypes.Acute, PtWorkflowTypes.Chronic, PtWorkflowTypes.FifteenMinute, PtWorkflowTypes.Surgery],
  [OktaRole.Coach]: [CoachWorkflowTypes.CoachCall, CoachWorkflowTypes.CoachVideoVisit],
  default: []
};
export const ONE_MINUTE_IN_MS = 1000 * 60;
export const THIRTY_SECONDS_IN_MS = 30 * 1000;
export enum VideoVisitStatus {
  Evaluation = 'Evaluation',
  FirstFollowup = '1st Follow up',
  SecondFollowup = '2nd Follow up',
  ThirdFollowup = '3rd Follow up',
  FourthFollowup = '4th Follow up',
  FifthFollowup = '5th Follow up',
  SixthFollowup = '6th Follow up',
}
export const VIDEO_VISIT_TYPES: string[] = [VideoVisitStatus.Evaluation, VideoVisitStatus.FirstFollowup, VideoVisitStatus.SecondFollowup, VideoVisitStatus.ThirdFollowup, VideoVisitStatus.FourthFollowup, VideoVisitStatus.FifthFollowup, VideoVisitStatus.SixthFollowup];