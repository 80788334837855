import { c as _c } from "react-compiler-runtime";
import { ctpTokens, HHBox, HHCollapse, HHStack, HHTypography } from '@hinge-health/react-component-library';
import { KeyboardArrowDownRounded } from '@mui/icons-material';
import { useState } from 'react';
import { Member, MemberCard } from './member-card';
import { ProgressIndicator } from './progress-indicator';
const mockData: Member[] = [{
  id: '1395522',
  name: 'Allison Richards',
  nextAction: 'Needs help with sudden pain increase',
  isActive: true
}, {
  id: '405646',
  name: 'Megan Thompson',
  nextAction: 'Needs help with sudden pain increase',
  isActive: false
}, {
  id: '1520461',
  name: 'Jessica Parker',
  nextAction: 'Wants to know more about her exercise plan',
  isActive: false
}, {
  id: '1452140',
  name: 'Emily Johnson',
  nextAction: 'Need assistance with my pain management',
  isActive: false
}, {
  id: '1491657',
  name: 'Michael Brown',
  nextAction: 'Needs help with sudden pain increase',
  isActive: false
}, {
  id: '1526427',
  name: 'Olivia Davis',
  nextAction: 'Needs help with sudden pain increase',
  isActive: false
}, {
  id: '1403758',
  name: 'Daniel Wilson',
  nextAction: 'Needs help with sudden pain increase',
  isActive: false
}, {
  id: '1383859',
  name: 'Olivia Davis',
  nextAction: 'Needs help with sudden pain increase',
  isActive: false
}, {
  id: '9',
  name: 'Olivia Davis',
  nextAction: 'Needs help with sudden pain increase',
  isActive: false
}, {
  id: '10',
  name: 'Olivia Davis',
  nextAction: 'Needs help with sudden pain increase',
  isActive: false
}];
const styles = {
  container: {
    backgroundColor: ctpTokens.palette.neutral['100'],
    borderRadius: '12px',
    overflow: 'hidden'
  },
  viewAll: {
    backgroundColor: ctpTokens.palette.sand.shades['8p'],
    borderRadius: 2,
    cursor: 'pointer',
    padding: '12px 24px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  loadMore: {
    borderRadius: 2,
    textAlign: 'start',
    cursor: 'pointer',
    padding: '12px 24px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
};
export const AsyncMembersQueue = () => {
  const $ = _c(21);
  const [isOpen, setIsOpen] = useState(false);
  let t0;
  if ($[0] !== isOpen) {
    t0 = () => {
      setIsOpen(!isOpen);
    };
    $[0] = isOpen;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const handleViewAll = t0;
  let t1;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => {};
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  const handleLoadMore = t1;
  let t2;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = <ProgressIndicator current={38} max={100} />;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  let t3;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = <HHBox paddingBottom="12px"><HHTypography hhVariant="variant-bypass" variant="h3" fontSize="16px" fontWeight="700">100/100 members{" "}<HHTypography component="span" hhVariant="variant-bypass" fontSize="16px" variant="body1" sx={{
          color: ctpTokens.palette.neutral["10"]
        }}>need help</HHTypography></HHTypography></HHBox>;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  let t4;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = <HHStack gap="12px">{mockData.slice(0, 4).map(member => <MemberCard key={member.id} member={member} />)}</HHStack>;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  const t5 = isOpen ? "View less" : "View more";
  let t6;
  if ($[6] !== t5) {
    t6 = <HHTypography hhVariant="variant-bypass" variant="body1" fontWeight="500" color={ctpTokens.palette.neutral["50"]}>{t5}</HHTypography>;
    $[6] = t5;
    $[7] = t6;
  } else {
    t6 = $[7];
  }
  const t7 = isOpen ? "rotate(-180deg)" : "rotate(0deg)";
  let t8;
  if ($[8] !== t7) {
    t8 = <HHBox><KeyboardArrowDownRounded sx={{
        color: ctpTokens.palette.neutral["10"],
        transition: "transform 100ms ease-in-out",
        transform: t7
      }} /></HHBox>;
    $[8] = t7;
    $[9] = t8;
  } else {
    t8 = $[9];
  }
  let t9;
  if ($[10] !== handleViewAll || $[11] !== t6 || $[12] !== t8) {
    t9 = <HHStack sx={styles.viewAll} onClick={handleViewAll}>{t6}{t8}</HHStack>;
    $[10] = handleViewAll;
    $[11] = t6;
    $[12] = t8;
    $[13] = t9;
  } else {
    t9 = $[13];
  }
  let t10;
  if ($[14] === Symbol.for("react.memo_cache_sentinel")) {
    t10 = <HHStack gap="12px">{mockData.slice(4).map(member_0 => <MemberCard key={member_0.id} member={member_0} />)}</HHStack>;
    $[14] = t10;
  } else {
    t10 = $[14];
  }
  let t11;
  if ($[15] === Symbol.for("react.memo_cache_sentinel")) {
    t11 = <HHBox sx={styles.loadMore} onClick={handleLoadMore}><HHTypography hhVariant="variant-bypass" variant="body1" fontWeight="500" color={ctpTokens.palette.green[40]}>Load more</HHTypography></HHBox>;
    $[15] = t11;
  } else {
    t11 = $[15];
  }
  let t12;
  if ($[16] !== isOpen) {
    t12 = <HHCollapse in={isOpen}>{t10}{t11}</HHCollapse>;
    $[16] = isOpen;
    $[17] = t12;
  } else {
    t12 = $[17];
  }
  let t13;
  if ($[18] !== t9 || $[19] !== t12) {
    t13 = <HHBox sx={styles.container}>{t2}<HHStack gap="12px" padding="24px">{t3}{t4}{t9}{t12}</HHStack></HHBox>;
    $[18] = t9;
    $[19] = t12;
    $[20] = t13;
  } else {
    t13 = $[20];
  }
  return t13;
};