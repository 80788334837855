import { OpenWorkflowsMxEventType } from '../../../modules/member-360/custom-types';
export enum MixpanelPropertiesContext {
  WorkflowContext = 'workflow-context',
  MemberContext = 'member-context',
}
export type GenericMixpanelTrack = <P>(eventName: string, eventProperties: P) => void;
export interface CommonAdminMixpanelProperties {
  currentAdminUuid: string;
  currentAdminID: string;
  currentAdminEmail: string;
  currentAdminRole: string;
  currentAdminOktaRole: string;
}
export interface CommonMemberMixpanelProperties {
  currentMemberID: string;
  currentMemberUuid: string;
  currentMemberProgramType: string;
  currentMemberProgramIndication: string;
}
export interface CommonWorkflowMixpanelProperties {
  currentWorkflowID: number;
  currentWorkflowName: string;
  currentWorkflowState: string;
  currentWorkflowCreatedAt: string;
  currentWorkflowPriority: string;
}
export interface CommonSessionMixpanelProperties {
  sessionId: string;
}
export enum MixpanelEventVersion {
  // Add new versions here
  V1 = 'v1',
}
export interface IamMessageListMixpanelProperties {
  channelId: string;
  conversationType: string;
}
export interface RobinAiMixpanelProperties extends IamMessageListMixpanelProperties {
  messageId?: string;
  sessionId?: string;
}
export interface IamMessageEventProperties {
  channelId: string;
  coachAssistantAiMessageId?: string;
  openWorkflows?: OpenWorkflowsMxEventType;
  sessionId?: string | null;
  workflowId?: number | null;
  workflowType?: string | null;
}
export interface MemberRecordProps {
  sessionId: string | null;
  searchFieldFilter?: string;
  searchString?: string;
  filterName?: string;
  insightCollectionUuid?: string;
  coverage?: string;
  startDate?: string;
  endDate?: string;
  selectedDateRange?: string;
  etSessionType?: string;
  updateCarePlanReason?: string;
  coverageFilter?: string;
  selectedDuration?: string;
  mode?: string;
  resultNumber?: string;
  openWorkflows: OpenWorkflowsMxEventType;
}
export interface MemberRecordExitProps {
  sessionId: string | null;
  timeSpent: string | null;
}
export interface EntryPointTimeStamp {
  timeStamp: string;
  searchFieldFilter?: string;
  searchString?: string;
  resultNumber?: string;
  sessionId?: string;
}
export interface IamAiMessageSentProperties extends IamMessageEventProperties {
  editDistance: number;
  sessionId?: string | null;
}
export interface DashboardTabSelectionType {
  selectedTabName: string;
}
export interface SelectedWorkflowMixpanelProperties {
  selectedWorkflowID: number;
  selectedWorkflowName: string;
  selectedWorkflowState: string;
  selectedWorkflowCreatedAt: string;
  selectedWorkflowMemberUuid: string;
}
export interface WorkflowResolutionProperties {
  workflowId: number;
  workflowTransitionState: string;
  workflowInitialState: string;
  workflowName: string;
  workflowCreatedAt: string;
  memberUuid: string;
}
export interface PtPooledDashboardChangeProperties {
  ptPooledAdminUuid: string;
  ptPooledAdminName: string;
}
export interface WorkflowSelectedFromDashboardProperties {
  selectedWorkflowID: number;
  selectedWorkflowName: string;
  selectedWorkflowState: string;
  selectedWorkflowCreatedAt: string;
  selectedWorkflowMemberUuid: string;
  selectedWorkflowPriority: string;
  selectedWorkflowTabName?: string;
  timeStamp: string;
  sessionId: string | null;
}
export interface MemberStrikethroughOptOutProps {
  reason: string;
}
export interface IamChannelSelectionProperties {
  channelId: string;
  conversationType: string;
  careTeamOwnerType: string;
}
export interface CommsPanelTabSelectionProperties {
  tabName: string;
  label: string;
}
export interface WorkflowResolvedProperties {
  sessionId: string | null;
  openWorkflows?: OpenWorkflowsMxEventType;
}
export interface WorkflowCardMixPanelProperties extends CommonSessionMixpanelProperties {
  currentWorkflowID: number;
  currentWorkflowName: string;
  currentWorkflowMemberUuid: string;
  currentWorkflowState: string;
  currentWorkflowCreatedAt: string;
  openWorkflows?: OpenWorkflowsMxEventType;
}
export interface AutoWorkflowTransitionMixpanelProperties {
  currentWorkflowID: number;
  currentWorkflowName: string;
  currentWorkflowMemberUuid: string;
  currentWorkflowState: string;
  adminUuid: string;
}
export interface PainScoreGraphMixpanelProperties {
  periodSelected: string;
}
export interface NextBestActionMixpanelProperties extends CommonSessionMixpanelProperties {
  openWorkflows: OpenWorkflowsMxEventType;
}
export interface NbaFeedbackSubmittedMixpanelProperties extends CommonSessionMixpanelProperties {
  currentNextBestAction: string;
  currentWorkflowId: string;
  [key: `currentNBAInsight${number}`]: string;
}
export interface VideoVisitRecordingProperties {
  videoCallId: string;
  isRecording: string;
  consentGiven: string;
}
export interface VideoVisitRecordingNotificationProperties {
  recordingStatus: string;
  recordedUserUuid: string;
  recordedAdminUuid: string;
}
export interface VideoVisitRecordingPlaybackProperties {
  recordingId: string;
  recordedUserUuid: string;
  recordedAdminUuid: string;
  recordedAdminName: string;
  recordingStatus: string;
  recordingFileName: string;
  recordingFileUrl: string;
  recordingRoomId: string;
}
export interface FeedbackSubmittedProperties {
  ticketId: string;
}
export interface VideoVisitCommonProperties {
  videoCallId: string | null;
  vendor: string;
}
export interface VideoVisitVideoToggleProperties extends VideoVisitCommonProperties {
  cameraStatus: string;
}
export interface VideoVisitMicToggleProperties extends VideoVisitCommonProperties {
  micStatus: string;
}
export interface VideoVisitRemoteAudioToggleProperties extends VideoVisitCommonProperties {
  audioStatus: string;
}
export interface VideoVisitRemoteVideoToggleProperties extends VideoVisitCommonProperties {
  videoStatus: string;
}
export interface VideoVisitRemoteVideoTrackSubscriptionProperties extends VideoVisitCommonProperties {
  subscription: string;
}
export interface VideoVisitRemoteOrientationProperties extends VideoVisitCommonProperties {
  orientation: string;
}
export interface QaReviewBulkDeleteMixpanelProperties {
  recordingsToDelete: Set<string>;
  recordingsCount: number;
}
export interface QaReviewSearchMixpanelProperties {
  searchQuery: string;
}
export interface QaReviewSelectAllRecordingsMixpanelProperties {
  recordingsToDelete: string[];
  recordingsCount: number;
  pageNumber: number;
}
export interface QaReviewUnselectAllRecordingsMixpanelProperties {
  pageNumber: number;
}