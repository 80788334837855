import { c as _c } from "react-compiler-runtime";
import { useQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { NAV_SPLIT_TREATMENTS } from '../constants/strings/split';
import { useCurrentStoredAdminHook } from '../hooks/use-current-stored-admin';
import { FeatureFlagContext } from './feature-flag-context';
import { GET_FEATURE_FLAG_TREATMENTS } from './queries/get-feature-flag.graphql';
const FeatureFlagProvider: React.FC = t0 => {
  const $ = _c(15);
  const {
    children
  } = t0;
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const key = currentStoredAdmin?.uuid || "";
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = Object.values(NAV_SPLIT_TREATMENTS);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const flags = t1;
  let t2;
  if ($[1] !== key) {
    t2 = {
      key,
      flags
    };
    $[1] = key;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  const t3 = !key || flags.length === 0;
  let t4;
  if ($[3] !== t2 || $[4] !== t3) {
    t4 = {
      variables: t2,
      skip: t3
    };
    $[3] = t2;
    $[4] = t3;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  const {
    loading,
    data,
    error
  } = useQuery(GET_FEATURE_FLAG_TREATMENTS, t4);
  const featureFlags = data?.getTreatments?.treatments;
  let t5;
  let t6;
  if ($[6] !== featureFlags) {
    t6 = featureFlags ?? {};
    $[6] = featureFlags;
    $[7] = t6;
  } else {
    t6 = $[7];
  }
  let t7;
  if ($[8] !== t6 || $[9] !== loading || $[10] !== error) {
    t7 = {
      featureFlags: t6,
      loading,
      error
    };
    $[8] = t6;
    $[9] = loading;
    $[10] = error;
    $[11] = t7;
  } else {
    t7 = $[11];
  }
  t5 = t7;
  const value = t5;
  let t8;
  if ($[12] !== value || $[13] !== children) {
    t8 = <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
    $[12] = value;
    $[13] = children;
    $[14] = t8;
  } else {
    t8 = $[14];
  }
  return t8;
};
export default FeatureFlagProvider;