import { ctpTokens, HHAccordion, HHAccordionDetails, HHAccordionSummary, HHBox, HHButton, HHCircularProgress, HHGrid, HHTypography, PriorityStates, useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { ExpandMore } from '@mui/icons-material';
import { useClient } from '@splitsoftware/splitio-react';
import { SetStateAction, useAtom, useAtomValue, useSetAtom, WritableAtom } from 'jotai';
import { isEqual, sortBy } from 'lodash';
import { Dispatch, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ON } from '../../constants/strings/split';
import { POSSIBLY_MORE_INDICATOR, PT_CATEGORY_NAME_ARRAY_INDEX, QUEUE_CATEGORY_NAMES, SHOW_MORE, UNKNOWN_COUNT } from '../../constants/strings/workflows-queue';
import { coachQueueWorkflowCategories, CoachWorkflowTypes, encounterWorkflowTypes, osQueueWorkflowCategories, ptQueueWorkflowCategories, PtWorkflowTypes, PT_VIDEO_VISIT_LABEL, QueueCategoryType, QueueCategoryTypeIndex, QueueIconTypes, queueSummaryCategories, WorkflowCategoryTypes } from '../../custom-types';
import { useCurrentStoredAdminHook } from '../../hooks/use-current-stored-admin';
import { useGetIncompleteQueueCounts } from '../../hooks/use-get-incomplete-queue-counts';
import { useGetQueueWorkflows } from '../../hooks/use-get-queue-workflows';
import useQueueQueryParams from '../../hooks/use-queue-query-params';
import { QueueWorkflowCategorySelected } from '../../lib/mixpanel/events/workflows';
import { HLN_COMPLETED, HLN_PROGRESS, UNREAD_COMPLETED, UNREAD_PROGRESS } from '../../modules/dashboard/constants/strings/workflows-table';
import { showTimeoutAlert } from '../../modules/dashboard/jotai/atoms';
import { CurrentMemberSessionIdContext } from '../../modules/member-360/components/profile/session-manager';
import { SIMPLIFIED_WORKFLOWS } from '../../modules/member-360/constants/splits';
import { useShellContext } from '../../modules/sidebar/contexts/shell.context';
import { coachWorkflowIdMapsByQueueCategorySplitAtom, currentQueueCategoryIndexAtom, currentQueueTabIndexAtom, currentQueueWorkflowIdAtom, fetchFirstPageCurrentCategoryAtom, fetchMoreCurrentWorkflowIdAtom, FetchMoreWorkflowsAtom, osWorkflowIdMapsByQueueCategorySplitAtom, ptWorkflowIdMapsByQueueCategorySplitAtom, QueueWorkflowIdentifiers, Sentinels, UpcomingEncounter, upcomingEncountersAtom, workflowCountsByQueueCategoryAtom } from '../../state/queue-atoms';
import { WorkflowPayload } from '../../types';
import { OktaRole } from '../../types/okta-roles';
import { getDueState } from '../../utils/get-due-state';
import { getPatientDetails } from '../../utils/get-patient-name';
import { trackEvent } from '../../utils/mixpanel';
import { buildWorkflowUrl, getByOktaRole } from '../utils';
import { QueueComplete } from './queue-accordion-header-complete';
import { QueueAccordionHeaderCount } from './queue-accordion-header-count';
import { QueueWorkflowRow } from './queue-workflow-row';
const summaryHeight = 52;
const collapseMs = 350;
const MINIMUM_WORKFLOWS_FOR_SHOW_MORE = 10;
export const WORKFLOW_CATEGORY = {
  UNREAD_MESSAGES: 'Unreads',
  HEALTH_LOG_NOTES: 'Health log notes'
};
const styles = {
  accordionSummary: {
    height: summaryHeight,
    '&.Mui-disabled': {
      opacity: 1
    }
  },
  gridContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap'
  },
  categoryNameGrid: {
    marginRight: 'auto',
    display: 'flex'
  },
  categoryNameContainer: {
    marginLeft: 6
  },
  expand: {
    marginRight: 4,
    marginLeft: 4
  },
  categoryName: {
    marginLeft: 6
  },
  buttonContainer: {
    marginLeft: 5,
    marginTop: 5,
    height: 40
  },
  accordion: {
    borderTop: `1px solid ${ctpTokens.palette.neutral[80]}`,
    flex: '0 0 auto',
    '&>div:not(:first-child)': {
      scrollbarGutter: 'stable',
      overflowY: 'auto',
      maxHeight: `calc(100% - ${summaryHeight}px)`
    }
  },
  circularProgress: {
    marginLeft: 5
  }
};
type EventHandler = (event: Event) => void;
interface DueCounts {
  newOverdueCount: number;
  newDueSoonCount: number;
}
export const useEventListener = (event: string, handler: EventHandler): void => {
  useEffect(() => {
    window.addEventListener(event, handler);
    return () => {
      window.removeEventListener(event, handler);
    };
  }, [event, handler]);
};
interface SpinnerProps {
  spinner: boolean;
  setTimoutAlert: Dispatch<SetStateAction<boolean>>;
}
export const useSpinnerTimeout = ({
  spinner,
  setTimoutAlert
}: SpinnerProps): void => {
  useEffect(() => {
    if (spinner) {
      const timeoutId = setTimeout(() => {
        setTimoutAlert(true);
      }, 60000);
      return () => clearTimeout(timeoutId);
    }
  }, [spinner, setTimoutAlert]);
};
interface QueueAccordionProps {
  workflowCategoryAtom: WritableAtom<FetchMoreWorkflowsAtom, unknown[], void>;
  type: QueueCategoryType;
}
export const changeQueueCategoryTab = (categoryIndex: number, workflowCategories: WorkflowCategoryTypes, setCurrentQueueTabIndex: Dispatch<SetStateAction<number>>, tabIndexValue: number, setCurrentQueueCategoryIndex: Dispatch<SetStateAction<number>>, roles?: OktaRole[], workflowType?: CoachWorkflowTypes | PtWorkflowTypes): void => {
  if (tabIndexValue === 1 && workflowType && encounterWorkflowTypes.includes(workflowType)) {
    const coachCallIndex = coachQueueWorkflowCategories.findIndex(category => category[0] === CoachWorkflowTypes.CoachCall);
    const currentCategoryIndex = roles?.includes(OktaRole.Coach) ? coachCallIndex : PT_CATEGORY_NAME_ARRAY_INDEX.indexOf(PT_VIDEO_VISIT_LABEL);
    setCurrentQueueCategoryIndex(currentCategoryIndex);
  }
  const isEncounterWorkflowCategory = tabIndexValue === 1 && encounterWorkflowTypes.includes(workflowCategories[categoryIndex][0]);

  // Focus the tab based on the workflow category
  const queueCategoryTabIndex = QueueCategoryTypeIndex[isEncounterWorkflowCategory ? 'encounter' : 'workflow'];
  setCurrentQueueTabIndex(queueCategoryTabIndex);
};
export const QueueAccordion = ({
  workflowCategoryAtom,
  type
}: QueueAccordionProps): JSX.Element => {
  const {
    currentMemberSessionId
  } = useContext(CurrentMemberSessionIdContext);
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  const {
    adminProfile
  } = useShellContext();
  const [showMore, setShowMore] = useState(false);
  const [workflowAtom, setWorkflowCategoryTotal] = useAtom(workflowCategoryAtom);
  const {
    loading: countLoading,
    subTotalCounts
  } = useGetIncompleteQueueCounts(type);
  const [completed, setCompleted] = useState(false);
  const [workflowCount, setWorkflowCount] = useState(UNKNOWN_COUNT);
  const [dueSoonCounts, setDueSoonCounts] = useState(UNKNOWN_COUNT);
  const [overdueCounts, setOverdueCounts] = useState(UNKNOWN_COUNT);
  const {
    workflows,
    loading,
    showMoreClickHandler,
    fetchQueueWorkflows
  } = useGetQueueWorkflows({
    workflowCategoryAtom,
    type
  });
  const setCurrentQueueCategoryIndex = useSetAtom(currentQueueCategoryIndexAtom);
  const splitClient = useClient();
  const [, setShowTimeout] = useAtom(showTimeoutAlert);
  const [expanded, setExpanded] = useState(false);
  const [fetchMoreCurrentWorkflowId] = useAtom(fetchMoreCurrentWorkflowIdAtom);
  const [coachWorkflowIdMaps] = useAtom(coachWorkflowIdMapsByQueueCategorySplitAtom);
  const [osWorkflowIdMaps] = useAtom(osWorkflowIdMapsByQueueCategorySplitAtom);
  const [ptWorkflowIdMaps] = useAtom(ptWorkflowIdMapsByQueueCategorySplitAtom);
  const [currentQueueCategoryIndex] = useAtom(currentQueueCategoryIndexAtom);
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const roles = hingeHealthAuthState?.accessToken?.claims.roles;
  const workflowIdMapsBasedOnSplit = getByOktaRole(coachWorkflowIdMaps, osWorkflowIdMaps, ptWorkflowIdMaps, roles);
  const setUpcomingEncountersAtom = useSetAtom(upcomingEncountersAtom);
  const [unreadSpinner, setUnreadSpinner] = useState(false);
  const [hlnSpinner, setHLNSpinner] = useState(false);
  const setWorkflowCountsByCategory = useSetAtom(workflowCountsByQueueCategoryAtom);
  const [fetchFirstPageCurrentCategory, setFetchFirstPageCurrentCategory] = useAtom(fetchFirstPageCurrentCategoryAtom);
  const [navigateToFirstWorkflow, setNavigateToFirstWorkflow] = useState(false);
  const workflowCategories = getByOktaRole(coachQueueWorkflowCategories, osQueueWorkflowCategories, ptQueueWorkflowCategories, roles);
  const queueCategoryIndex = useMemo(() => workflowCategories.findIndex(category => category[0] === workflowAtom.queueCategory[0]),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [workflowAtom.queueCategory]);
  useEffect(() => {
    if (fetchFirstPageCurrentCategory.fetchFirstPage && queueCategoryIndex === fetchFirstPageCurrentCategory.categoryIndex) {
      setExpanded(true);
      setFetchFirstPageCurrentCategory({
        ...fetchFirstPageCurrentCategory,
        fetchFirstPage: false
      });
      setNavigateToFirstWorkflow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFirstPageCurrentCategory]);
  const workflowAbsoluteCategoryIndex = workflowCategories.findIndex(category_0 => JSON.stringify(category_0) === JSON.stringify(workflowAtom.queueCategory));
  const setWorkflowIdMap = useSetAtom(workflowIdMapsBasedOnSplit[workflowAbsoluteCategoryIndex]);
  const setCurrentQueueTabIndex = useSetAtom(currentQueueTabIndexAtom);
  const tabIndexValue = useAtomValue(currentQueueTabIndexAtom);
  const [currentQueueWorkflowId, setCurrentQueueWorkflowId] = useAtom(currentQueueWorkflowIdAtom);
  const [loadedWorkflows, setLoadedWorkflows] = useState<WorkflowPayload[] | undefined>(undefined);
  const {
    workflowType
  } = useQueueQueryParams();
  const isSimplifiedWorkflowsSplitOn = splitClient?.getTreatment(SIMPLIFIED_WORKFLOWS, {
    adminUuid: currentStoredAdmin?.uuid || '*'
  }) === ON;
  useEffect(() => {
    // Collapse accordion if current queue category is not focused
    if (workflowAbsoluteCategoryIndex !== currentQueueCategoryIndex) {
      setExpanded(false);
    } else if (currentQueueWorkflowId && workflows?.length) {
      setExpanded(true);
    }

    // Change queue category tab if tab belonging to current queue category is focused
    changeQueueCategoryTab(currentQueueCategoryIndex, workflowCategories, setCurrentQueueTabIndex, tabIndexValue, setCurrentQueueCategoryIndex, hingeHealthAuthState?.accessToken?.claims.roles, (workflowType as PtWorkflowTypes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowAbsoluteCategoryIndex, currentQueueCategoryIndex, currentQueueWorkflowId]);
  useEffect(() => {
    if (workflows?.length && encounterWorkflowTypes.includes((workflows[0].type as CoachWorkflowTypes | PtWorkflowTypes))) {
      const atomData: UpcomingEncounter[] = workflows.map(workflow => ({
        patientDetails: getPatientDetails(workflow.patient),
        callDate: workflow.customFields?.callDate,
        type: (workflow.type as CoachWorkflowTypes | PtWorkflowTypes),
        workflowId: workflow.id,
        patientId: workflow.patient?.id,
        followups: workflow.customFields?.followups ?? 0,
        applicationPathway: workflow.patient?.applicationPathway
      }));
      setUpcomingEncountersAtom(atomData);
    }
  }, [setUpcomingEncountersAtom, workflows]);
  useEffect(() => {
    if (subTotalCounts) {
      const categoryTotal = workflowAtom.queueCategory.reduce((acc, currentType) => {
        const currentTypeCount = subTotalCounts.find(subCount => {
          if (subCount.workflowType === currentType) return subCount.count;
        })?.count || 0;
        return acc + currentTypeCount;
      }, 0);
      setWorkflowCategoryTotal((prev: FetchMoreWorkflowsAtom) => ({
        ...prev,
        categoryTotal
      }));
      setWorkflowCount(categoryTotal.toString());
      setWorkflowCountsByCategory(prev_0 => {
        const updatedState = prev_0.filter(item => item.categoryIndex !== queueCategoryIndex);
        updatedState.push({
          count: categoryTotal,
          categoryIndex: queueCategoryIndex
        });
        return updatedState;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotalCounts]);
  const unreadCategory = QUEUE_CATEGORY_NAMES[workflowAtom.queueCategory[0]] === WORKFLOW_CATEGORY.UNREAD_MESSAGES;
  const hlnCategory = QUEUE_CATEGORY_NAMES[workflowAtom.queueCategory[0]] === WORKFLOW_CATEGORY.HEALTH_LOG_NOTES;
  const disableCategory = (category_1: string): void => {
    setExpanded(false);
    if (category_1 === WORKFLOW_CATEGORY.UNREAD_MESSAGES) {
      setUnreadSpinner(true);
    } else {
      setHLNSpinner(true);
    }
  };
  const enableCategory = (): void => {
    if (unreadCategory) {
      setUnreadSpinner(false);
      setExpanded(true);
    } else {
      setHLNSpinner(false);
    }
  };
  useEventListener(UNREAD_PROGRESS, () => disableCategory(WORKFLOW_CATEGORY.UNREAD_MESSAGES));
  useEventListener(HLN_PROGRESS, () => disableCategory(WORKFLOW_CATEGORY.HEALTH_LOG_NOTES));
  useEventListener(HLN_COMPLETED, enableCategory);
  useEventListener(UNREAD_COMPLETED, enableCategory);
  useSpinnerTimeout({
    spinner: unreadSpinner,
    setTimoutAlert: setShowTimeout
  });
  useSpinnerTimeout({
    spinner: hlnSpinner,
    setTimoutAlert: setShowTimeout
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (fetchMoreCurrentWorkflowId && isEqual(sortBy(workflowCategories[currentQueueCategoryIndex]), sortBy(workflowAtom.queueCategory))) {
      showMoreClickHandler();
    }
    // eslint-disable-next-line
  }, [fetchMoreCurrentWorkflowId, currentQueueCategoryIndex]);
  const getWorkflowsIdMap = (workflows_0: WorkflowPayload[], showMore_0: boolean): Map<number, QueueWorkflowIdentifiers> => {
    const workflowIdMap = new Map<number, QueueWorkflowIdentifiers>();
    workflows_0.forEach((workflow_0, index) => {
      let nextId;
      if (index + 1 < workflows_0.length) {
        nextId = workflows_0[index + 1].id;
      } else if (showMore_0) {
        nextId = Sentinels.ShowMore;
      } else {
        nextId = Sentinels.End;
      }
      workflowIdMap.set(workflow_0.id, {
        id: workflow_0.id,
        patientId: workflow_0.patient?.id || '',
        type: workflow_0.type,
        nextId
      });
    });
    return workflowIdMap;
  };
  const reduceDueCounts = (acc_0: DueCounts, currentWorkflow: WorkflowPayload): DueCounts => {
    const dueState = getDueState(currentWorkflow.isPastDue, currentWorkflow.latestTaskDate);
    return {
      newOverdueCount: acc_0.newOverdueCount + (dueState === PriorityStates.Overdue ? 1 : 0),
      newDueSoonCount: acc_0.newDueSoonCount + (dueState === PriorityStates.DueSoon ? 1 : 0)
    };
  };
  const navigateToWorkflow = (workflowIdMap_0: Map<number, QueueWorkflowIdentifiers>): void => {
    const currentWorkflowDetails = workflowIdMap_0.get(fetchMoreCurrentWorkflowId);
    const firstWorkflowDetails = workflowIdMap_0.entries().next().value?.[1];
    const nextId_0 = currentWorkflowDetails?.nextId || 0;
    const nextWorkflowDetails = navigateToFirstWorkflow ? firstWorkflowDetails : workflowIdMap_0.get(nextId_0);
    const nextWorkflowUrl = buildWorkflowUrl(nextWorkflowDetails, isSimplifiedWorkflowsSplitOn);
    if (nextWorkflowUrl) {
      setCurrentQueueWorkflowId(nextId_0);
      navigate(nextWorkflowUrl);
    }
    navigateToFirstWorkflow && setNavigateToFirstWorkflow(false);
  };
  useEffect(() => {
    if (workflows && !loading) {
      const showMore_1 = Number(workflowCount) > workflows.length && Number(workflowCount) > MINIMUM_WORKFLOWS_FOR_SHOW_MORE;
      const completed_0 = Number(workflowCount) === 0;
      const workflowIdMap_1 = getWorkflowsIdMap(workflows, showMore_1);
      const {
        newOverdueCount,
        newDueSoonCount
      } = workflows.reduce(reduceDueCounts, {
        newOverdueCount: 0,
        newDueSoonCount: 0
      });
      const lastWorkflowDueState = !completed_0 && workflows.length > 0 && getDueState(workflows[workflows.length - 1].isPastDue, workflows[workflows.length - 1].latestTaskDate) || PriorityStates.Default;
      const overdueCountStr = newOverdueCount.toString() + (showMore_1 && lastWorkflowDueState == PriorityStates.Overdue ? POSSIBLY_MORE_INDICATOR : '');
      const dueSoonCountStr = newDueSoonCount.toString() + (showMore_1 && lastWorkflowDueState == PriorityStates.DueSoon ? POSSIBLY_MORE_INDICATOR : '');
      setCompleted(completed_0);
      setShowMore(showMore_1);
      setWorkflowIdMap(workflowIdMap_1);
      if (fetchMoreCurrentWorkflowId || navigateToFirstWorkflow && workflows.length) {
        navigateToWorkflow(workflowIdMap_1);
      }
      setOverdueCounts(overdueCountStr);
      setDueSoonCounts(dueSoonCountStr);
    }
    // eslint-disable-next-line
  }, [workflows, workflowCount, setWorkflowIdMap, fetchMoreCurrentWorkflowId, navigateToFirstWorkflow]);
  useEffect(() => {
    if (loadedWorkflows && loadedWorkflows.length > 0) {
      const showMore_2 = Number(workflowCount) > loadedWorkflows.length && Number(workflowCount) > MINIMUM_WORKFLOWS_FOR_SHOW_MORE;
      setShowMore(showMore_2);
    }
  }, [loadedWorkflows, workflowCount]);
  useEffect(() => {
    if (Number(workflowCount) === 0) {
      setLoadedWorkflows([]);
      setCompleted(true);
      setExpanded(false);
      setDueSoonCounts('');
      setOverdueCounts('');
    } else if (!loading && workflows && workflows.length > 0) {
      // Remove duplicate workflows based on workflow id
      const uniqueWorkflows = workflows.filter((workflow_1, index_0, self) => index_0 === self.findIndex(value => value.id === workflow_1.id));
      setLoadedWorkflows(uniqueWorkflows);
    }
  }, [loading, workflowCount, workflows]);
  const summaryValue = (category_2: QueueIconTypes): string => {
    switch (category_2) {
      case QueueIconTypes.Overdue:
        return overdueCounts;
      case QueueIconTypes.DueSoon:
        return dueSoonCounts;
      case QueueIconTypes.Event:
      case QueueIconTypes.Total:
      default:
        return workflowCount;
    }
  };
  const CategoryCounts = (): JSX.Element => {
    switch (type) {
      case QueueCategoryType.Encounter:
        return <QueueAccordionHeaderCount iconType={QueueIconTypes.Event} loading={loading && !completed} value={summaryValue(QueueIconTypes.Event)} key={QueueIconTypes.Event} />;
      case QueueCategoryType.Workflow:
      default:
        return <>
            {queueSummaryCategories.map(category_3 => summaryValue(category_3) !== '0' && <QueueAccordionHeaderCount iconType={category_3} loading={category_3 === QueueIconTypes.Total ? countLoading : loading && !completed} value={summaryValue(category_3)} key={category_3} />)}
          </>;
    }
  };
  useEffect(() => {
    if (expanded && !completed && !workflows?.length) {
      // Fetch workflows if accordion is expanded and there are no workflows
      fetchQueueWorkflows();
    }
    // eslint-disable-next-line
  }, [expanded, fetchQueueWorkflows]);
  return <HHAccordion square disableGutters TransitionProps={{
    unmountOnExit: true,
    timeout: collapseMs,
    addEndListener: (collapsibleElement: HTMLElement): void => {
      const accordionElemStyle = collapsibleElement.parentElement?.style;
      if (!accordionElemStyle) return;
      const shrinkAmt = `${1 + (workflows?.length || 1) / 10}`;
      if (expanded) {
        accordionElemStyle.flexShrink = shrinkAmt;
      } else {
        accordionElemStyle.minHeight = `${summaryHeight}px`;
      }
      setTimeout(() => {
        if (expanded) {
          accordionElemStyle.flexShrink = shrinkAmt;
          accordionElemStyle.minHeight = `${summaryHeight + 100}px`;
        } else {
          accordionElemStyle.flexShrink = '0';
          accordionElemStyle.minHeight = `${summaryHeight}px`;
        }
      }, collapseMs);
    }
  }} expanded={expanded} sx={{
    ...styles.accordion,
    pointerEvents: hlnSpinner && hlnCategory || unreadSpinner && unreadCategory ? 'none' : 'auto',
    opacity: hlnSpinner && hlnCategory || unreadSpinner && unreadCategory ? 0.5 : 1
  }}>
      <HHAccordionSummary expandIcon={!completed && <ExpandMore sx={styles.expand} />} aria-controls="panel1a-header" sx={styles.accordionSummary} disabled={completed} onClick={(): void => {
      if (!expanded) {
        trackEvent(new QueueWorkflowCategorySelected({
          selectedWorkflowCategory: QUEUE_CATEGORY_NAMES[workflowAtom.queueCategory[0]],
          adminCtx: adminProfile,
          sessionId: currentMemberSessionId || ''
        }));
      }
      setExpanded(!expanded);
    }}>
        <HHGrid container spacing={0} sx={styles.gridContainer}>
          <HHGrid item xs={6} sx={styles.categoryNameGrid}>
            <HHBox sx={styles.categoryNameContainer}>
              <HHTypography hhVariant="h3">
                {QUEUE_CATEGORY_NAMES[workflowAtom.queueCategory[0]]}
              </HHTypography>
            </HHBox>
          </HHGrid>
          {completed && !loading ? <QueueComplete /> : <CategoryCounts />}
        </HHGrid>
      </HHAccordionSummary>
      <HHAccordionDetails>
        {loadedWorkflows && loadedWorkflows.map(workflow_2 => <QueueWorkflowRow workflow={workflow_2} type={type} key={workflow_2.id} />)}
        {loading || countLoading ? <HHBox sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
            <HHCircularProgress size={20} sx={styles.circularProgress} />
          </HHBox> : showMore && <HHBox sx={styles.buttonContainer} onClick={showMoreClickHandler}>
              <HHButton hhVariant="text">{SHOW_MORE}</HHButton>
            </HHBox>}
      </HHAccordionDetails>
    </HHAccordion>;
};