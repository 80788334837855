import { format, parseISO } from 'date-fns';
import { CoachWorkflowTypes, OsWorkflowTypes, PtWorkflowTypes } from '../../../../../../../custom-types';
import { OktaRole } from '../../../../../../../types/okta-roles';
import { WorkflowPayload } from '../../../../member-alerts/types';
import { CLINICAL_ESCALATION_FORM, COACH_ESCALATION_FORM, ET_MODIFICATION_FORM, MEDICAL_EMERGENCY_FORM } from '../../constants/create-workflow-forms';
import { WORKFLOW_HEADER_TEXT } from '../../constants/workflow-panels';
const getWorkflowTitle = (workflowType: CoachWorkflowTypes | PtWorkflowTypes): string => {
  switch (workflowType) {
    case PtWorkflowTypes.ClinicalEscalation:
      return CLINICAL_ESCALATION_FORM.TITLE;
    case CoachWorkflowTypes.CoachEscalation:
      return COACH_ESCALATION_FORM.TITLE;
    case PtWorkflowTypes.MedicalEmergencyFollowUp:
      return MEDICAL_EMERGENCY_FORM.TITLE;
    case PtWorkflowTypes.EtModification:
      return ET_MODIFICATION_FORM.TITLE;
    case CoachWorkflowTypes.OrthoCoachEscalation:
      return 'Coach Escalation (Ortho)';
    case PtWorkflowTypes.OrthoClinicalEscalation:
      return 'Clinical Escalation (Ortho)';
    default:
      return '';
  }
};
export const getCreateWorkflowSnackbarMessage = (workflowType: CoachWorkflowTypes | PtWorkflowTypes): string => {
  const workflowTitle = getWorkflowTitle(workflowType);
  return workflowTitle ? `${getWorkflowTitle(workflowType)} workflow created` : workflowTitle;
};
export const getSubtitleForMemberAlertsDashboard = (currentWorkflow: WorkflowPayload | null): string => {
  if (!currentWorkflow?.customFields?.memberAlert) {
    return '';
  }
  const {
    startAt,
    endAt,
    category
  } = currentWorkflow.customFields.memberAlert;
  if (!startAt || !endAt || !category) {
    return '';
  }
  const startDate = parseISO(startAt);
  const endDate = parseISO(endAt);
  const formattedStartDate = format(startDate, 'M/d/yy');
  const formattedEndDate = format(endDate, 'M/d/yy');
  return `${formattedStartDate} - ${formattedEndDate}`;
};
export const getHeadingText = (workflowType: CoachWorkflowTypes | PtWorkflowTypes): string => {
  switch (workflowType) {
    case PtWorkflowTypes.Closing:
    case PtWorkflowTypes.Expiring:
      return WORKFLOW_HEADER_TEXT.PLAN_EXPIRATION;
    default:
      return WORKFLOW_HEADER_TEXT.DUE_DATE;
  }
};
const workflowRoleMap: Record<string, OktaRole> = {
  ...Object.fromEntries(Object.values(CoachWorkflowTypes).map(type => [type, OktaRole.Coach])),
  ...Object.fromEntries(Object.values(OsWorkflowTypes).map(type => [type, OktaRole.OnboardingSpecialist])),
  ...Object.fromEntries(Object.values(PtWorkflowTypes).map(type => [type, OktaRole.PhysicalTherapist]))
};
export const getWorkflowAdminRole = (workflowType?: string): OktaRole | undefined => {
  if (workflowType) {
    return workflowRoleMap[workflowType];
  }
};