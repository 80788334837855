import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { SseClient } from '../utils/sse-client';

// A singleton to prevent running into connection limits
const sseClient = new SseClient();
export const useSse = (url: string, eventsTypes: string[], options?: {
  replayLastEvent?: boolean;
}): unknown => {
  const [data, setData] = useState<unknown>();
  const currentDataRef = useRef<unknown>(null);
  const {
    hingeHealthAuthState,
    hingeHealthAuthClient
  } = useHingeHealthSecurityContext();
  const subscriberId = useRef(v4());

  /* eslint-disable react-hooks/exhaustive-deps */
  const memoizedEvents = useMemo(() => eventsTypes, [JSON.stringify(eventsTypes)]);
  /* eslint-disable react-hooks/exhaustive-deps */

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    (async () => {
      // Initialize the SSE client for the first time and on subsequent token changes
      if (!sseClient.initialized && hingeHealthAuthState?.accessToken) {
        sseClient.initialized = true;
        await sseClient.init({
          url,
          accessToken: hingeHealthAuthState?.accessToken,
          getOrRenewAccessToken: hingeHealthAuthClient?.getOrRenewAccessTokenViaTokenManager
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hingeHealthAuthState, hingeHealthAuthClient]);
  useEffect(() => {
    if (!sseClient.initialized || !hingeHealthAuthState?.accessToken) {
      console.warn('SSE client not initialized');
      return;
    }
    const currentSubscriberId = subscriberId.current;
    memoizedEvents.forEach(event => {
      sseClient.subscribe(event, currentSubscriberId, messageEvent => {
        if (!isEqual(currentDataRef.current, messageEvent)) {
          currentDataRef.current = messageEvent;
          setData(messageEvent);
        }
      });
      if (options?.replayLastEvent) {
        sseClient.replay(event);
      }
    });
    return () => {
      memoizedEvents.forEach(event_0 => {
        sseClient.unsubscribe(event_0, currentSubscriberId);
      });
    };
  }, [hingeHealthAuthState, memoizedEvents, options?.replayLastEvent]);
  return data;
};