export const ON = 'on';
export const OFF = 'off';
export const FRESHDESK_FEEDBACK_TREATMENT = 'freshdesk';
export const NAV_SPLIT_TREATMENTS = {
  ENCOUNTERS_TAB: 'encounters-tab',
  SETUP_ACTION_PLAN_WORKFLOW: 'setup-action-plan-workflow',
  MIXPANEL_CLICK_TRACKING: 'mixpanel-click-tracking',
  USER_FEEDBACK: 'user-feedback',
  COACH_VIDEO_VISIT: 'coach-video-visit',
  MPE_QUEUE: 'mpe-queue',
  FEEDBACK_VENDOR: 'carehub-feedback-vendor',
  REDIRECT_CTT_TO_CTP: 'redirect-ctt-to-ctp',
  NEXT_BEST_ACTION: 'next-best-action',
  ENABLE_WORKFLOW_STATE_FILTERING: 'enable-workflow-state-filtering',
  ORTHO_SPECIALIST: 'ortho-specialist-onboarding',
  QA_REVIEW_RECORDING: 'qa-review-recording',
  NOTES_LEXICAL_EDITOR_MIGRATION: 'notes-lexical-editor-migration',
  MPE_DASHBOARD: 'mpe-dashboard-ctp'
};
export const UPLOAD_ATTACHMENTS_USING_S3 = 'upload-feedback';