import { c as _c } from "react-compiler-runtime";
import { HHStack } from '@hinge-health/react-component-library';
import { Appointments } from '../appointments/appointments';
import { AsyncMembersQueue } from '../components/async-members-queue';
export const MembersQueue = () => {
  const $ = _c(3);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = {
      width: "26rem",
      gap: "1rem"
    };
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  let t1;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = <Appointments />;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = <HHStack sx={t0}>{t1}<AsyncMembersQueue /></HHStack>;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  return t2;
};